import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import filterAction from '../global-functions/filterAction';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  DeckSwiper,
  DeckSwiperCard,
  Icon,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const IntakeLegalScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showDoneCard, setShowDoneCard] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      undefined;
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
    >
      <XanoApi.FetchActionGetAllGET user_id={Constants['user_details']?.id}>
        {({ loading, error, data, refetchActionGetAll }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    backgroundColor: theme.colors['Divider'],
                    padding: 32,
                    paddingTop: 64,
                  },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'action plan'}
                </Text>
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'legal'}
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.header_onboard}
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { justifyContent: 'center', padding: 32 },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.swipe}
                </Text>
                <>
                  {!!showDoneCard ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignSelf: 'stretch',
                          padding: 32,
                        },
                        dimensions.width
                      )}
                    >
                      <DeckSwiper
                        data={filterAction(
                          fetchData?.action_list,
                          props.route?.params?.category ?? 'housing'
                        )}
                        horizontalEnabled={true}
                        infiniteSwiping={false}
                        keyExtractor={(deckSwiperData, index) =>
                          deckSwiperData?.id ??
                          deckSwiperData?.uuid ??
                          index.toString()
                        }
                        listKey={'KeryHfV7'}
                        onEndReached={() => {
                          try {
                            setShowDoneCard(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        renderItem={({ item, index }) => {
                          const deckSwiperData = item;
                          return (
                            <DeckSwiperCard
                              {...GlobalStyles.DeckSwiperCardStyles(theme)[
                                'Deck Swiper Card'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.DeckSwiperCardStyles(theme)[
                                    'Deck Swiper Card'
                                  ].style,
                                  { justifyContent: 'flex-start' }
                                ),
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'stretch',
                                    alignItems: 'stretch',
                                    alignSelf: 'stretch',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'Tag - Grey - View'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'Tag - Grey - View'
                                      ].style,
                                      {
                                        alignContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        alignSelf: 'flex-end',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Tag - Grey - Text'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {props.route?.params?.category ?? 'housing'}
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    minHeight: 227,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={theme.colors['Primary']}
                                  name={
                                    'MaterialCommunityIcons/shield-home-outline'
                                  }
                                  size={32}
                                  style={StyleSheet.applyWidth(
                                    { opacity: 0.5 },
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Header - Page'
                                  ].props}
                                  adjustsFontSizeToFit={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Header - Page'
                                      ].style,
                                      { textAlign: 'center' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {deckSwiperData?.action}
                                </Text>
                                {/* Text 2 */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Tag - Grey - Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].style,
                                      { textAlign: 'center' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {deckSwiperData?.why}
                                </Text>
                                <Button
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        (
                                          await XanoApi.checkinAddPOST(
                                            Constants,
                                            {
                                              action_id: deckSwiperData?.id,
                                              user_id:
                                                Constants['user_details']?.id,
                                            }
                                          )
                                        )?.json;
                                        navigation.navigate(
                                          'IntakeConnectScreen',
                                          { category: 'connect' }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)['Button']
                                    .props}
                                  icon={'Feather/plus'}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)['Button']
                                        .style,
                                      {
                                        marginBottom: 16,
                                        marginLeft: 16,
                                        marginRight: 16,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'add to action plan'}
                                />
                              </View>
                            </DeckSwiperCard>
                          );
                        }}
                        startCardIndex={0}
                        verticalEnabled={true}
                        {...GlobalStyles.DeckSwiperStyles(theme)['Deck Swiper']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.DeckSwiperStyles(theme)['Deck Swiper']
                            .style,
                          dimensions.width
                        )}
                        visibleCardCount={5}
                      />
                    </View>
                  )}
                </>
                {/* last card */}
                <>
                  {!showDoneCard ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignSelf: 'center',
                          justifyContent: 'center',
                          padding: 32,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            backgroundColor: theme.colors['Background Input'],
                            borderColor: theme.colors['Light'],
                            borderRadius: 4,
                            borderWidth: 1,
                            justifyContent: 'center',
                            maxHeight: 275,
                            maxWidth: 275,
                            minHeight: 275,
                            minWidth: 275,
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors['Strong']}
                          name={'MaterialCommunityIcons/party-popper'}
                          size={75}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          adjustsFontSizeToFit={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Assistant_500Medium',
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            "Sweet... it looks like you're all set with the major interventions."
                          }
                        </Text>
                        <Button
                          onPress={() => {
                            try {
                              navigation.navigate('IntakeConnectScreen', {
                                category: 'connect',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                fontFamily: 'Assistant_800ExtraBold',
                                marginBottom: 16,
                                marginLeft: 16,
                                marginRight: 16,
                              }
                            ),
                            dimensions.width
                          )}
                          title={"what's next?"}
                        />
                      </View>
                    </View>
                  )}
                </>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchActionGetAllGET>
    </ScreenContainer>
  );
};

export default withTheme(IntakeLegalScreen);
