import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  Markdown,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const TermsOfServiceScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [verified, setVerified] = React.useState('verified');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchAppGET>
        {({ loading, error, data, refetchApp }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <Divider
                  color={theme.colors.divider}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'terms of service'}
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Each time you use this app, you're agreeing to these terms of service, including these terms are updated."
                  }
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { padding: 32 },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                {/* overview */}
                <View>
                  {/* header */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'summary of terms'}
                  </Text>

                  <Markdown
                    {...GlobalStyles.MarkdownStyles(theme)['Text 3'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.MarkdownStyles(theme)['Text 3'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.app?.tos_summary}
                  </Markdown>
                </View>
                {/* Divider 2 */}
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                {/* Divider 3 */}
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                {/* overview 2 */}
                <View>
                  {/* header */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'full terms of service'}
                  </Text>

                  <Markdown
                    {...GlobalStyles.MarkdownStyles(theme)['Text 3'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.MarkdownStyles(theme)['Text 3'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.app?.tos}
                  </Markdown>
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchAppGET>
      <View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={'rgba(0, 0, 0, 0)'}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TermsOfServiceScreen);
