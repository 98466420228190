export default {
  Assistant_400Regular: require('../assets/fonts/Assistant_400Regular.ttf'),
  Assistant_700Bold: require('../assets/fonts/Assistant_700Bold.ttf'),
  Assistant_800ExtraBold: require('../assets/fonts/Assistant_800ExtraBold.ttf'),
  Assistant_600SemiBold: require('../assets/fonts/Assistant_600SemiBold.ttf'),
  Assistant_300Light: require('../assets/fonts/Assistant_300Light.ttf'),
  Assistant_500Medium: require('../assets/fonts/Assistant_500Medium.ttf'),
  Caveat_600SemiBold: require('../assets/fonts/Caveat_600SemiBold.ttf'),
  Roboto_700Bold: require('../assets/fonts/Roboto_700Bold.ttf'),
};
