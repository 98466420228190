import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  TextInput,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const IntakeVisionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [inputAdmin, setInputAdmin] = React.useState(false);
  const [inputAdults, setInputAdults] = React.useState('');
  const [inputAlt, setInputAlt] = React.useState('');
  const [inputBar, setInputBar] = React.useState('');
  const [inputBenefits, setInputBenefits] = React.useState('');
  const [inputCause, setInputCause] = React.useState('');
  const [inputChildren, setInputChildren] = React.useState('');
  const [inputCity, setInputCity] = React.useState('');
  const [inputDOB, setInputDOB] = React.useState('');
  const [inputDay, setInputDay] = React.useState('');
  const [inputDisability, setInputDisability] = React.useState('');
  const [inputEDU, setInputEDU] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [inputEntryDate, setInputEntryDate] = React.useState('');
  const [inputEntryDay, setInputEntryDay] = React.useState('');
  const [inputEntryMonth, setInputEntryMonth] = React.useState('');
  const [inputEntryYear, setInputEntryYear] = React.useState('');
  const [inputFirst, setInputFirst] = React.useState('');
  const [inputGender, setInputGender] = React.useState('');
  const [inputHousing, setInputHousing] = React.useState('');
  const [inputIncome, setInputIncome] = React.useState('');
  const [inputLast, setInputLast] = React.useState('');
  const [inputLead, setInputLead] = React.useState(false);
  const [inputMiddle, setInputMiddle] = React.useState('');
  const [inputMonth, setInputMonth] = React.useState('');
  const [inputRace, setInputRace] = React.useState('');
  const [inputRole, setInputRole] = React.useState('');
  const [inputSaved, setInputSaved] = React.useState('');
  const [inputSavings, setInputSavings] = React.useState('');
  const [inputSource, setInputSource] = React.useState('');
  const [inputStateID, setInputStateID] = React.useState('');
  const [inputStatus, setInputStatus] = React.useState('');
  const [inputStreet, setInputStreet] = React.useState('');
  const [inputTEL, setInputTEL] = React.useState('');
  const [inputVet, setInputVet] = React.useState('');
  const [inputVision, setInputVision] = React.useState('');
  const [inputYear, setInputYear] = React.useState('');
  const [inputZIP, setInputZIP] = React.useState('');
  const [nextStage, setNextStage] = React.useState('');
  const [pickerState, setPickerState] = React.useState('');
  const [pickerWorksFor, setPickerWorksFor] = React.useState('');
  const [showHelp, setShowHelp] = React.useState(false);
  const [switchPrivacy, setSwitchPrivacy] = React.useState(false);

  return (
    <ScreenContainer
      scrollable={false}
      {...GlobalStyles.ScreenContainerStyles(theme)['Text 2'].props}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        GlobalStyles.ScreenContainerStyles(theme)['Text 2'].style,
        dimensions.width
      )}
    >
      <XanoApi.FetchUserGetGET user_id={637}>
        {({ loading, error, data, refetchUserGet }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Divider'], padding: 32 },
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                ></View>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'\n\naction plan - your first action'}
                </Text>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'vision statement'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.vision_text}
                </Text>
                <Link
                  accessible={true}
                  onPress={() => {
                    try {
                      setShowHelp(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.LinkStyles(theme)['Link'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinkStyles(theme)['Link'].style,
                      { opacity: 1, textAlign: 'right' }
                    ),
                    dimensions.width
                  )}
                  title={'tell me more'}
                />
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    borderRadius: 4,
                    flexBasis: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingTop: 32,
                  },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* vision */}
                <View>
                  {/* vision */}
                  <View>
                    <TextInput
                      changeTextDelay={500}
                      multiline={true}
                      onChangeText={newTextAreaValue => {
                        try {
                          setInputVision(newTextAreaValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      numberOfLines={10}
                      placeholder={
                        'In 3 years, I will be running my mobile hair salon. The main thing I need to get me there is for me to get my hair license. The biggest thing in way is not having a drivers license, but if I run into any hiccups I know I can ask for help from my auntie. I commit to spending 1hr a week working towards this vision every Wednesday at 8am.'
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Input'].style,
                          { fontFamily: 'Assistant_400Regular' }
                        ),
                        dimensions.width
                      )}
                      value={inputVision}
                    />
                  </View>
                </View>

                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Primary'],
                      fontFamily: 'Assistant_700Bold',
                      fontSize: 16,
                      marginTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
                <>
                  {!(inputVision?.length >= 50) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32 },
                        dimensions.width
                      )}
                    >
                      <>
                        {!(inputVision !== fetchData?.vision_sample) ? null : (
                          <View>
                            {/* Button Note */}
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const visionResponse = (
                                      await XanoApi.userVisionPOST(Constants, {
                                        inputVision: inputVision,
                                      })
                                    )?.json;
                                    undefined;
                                    if (visionResponse?.message === 'success') {
                                      navigation.navigate(
                                        'IntakeHousingScreen'
                                      );
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['Button']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button']
                                    .style,
                                  { marginBottom: 32, marginTop: 32 }
                                ),
                                dimensions.width
                              )}
                              title={
                                'done...\n creating the rest of my action plan'
                              }
                            />
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>

              <Modal
                animationType={'slide'}
                presentationStyle={'overFullScreen'}
                transparent={true}
                visible={showHelp}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Darken Super'],
                      flexGrow: 1,
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <VideoPlayer
                    isLooping={false}
                    isMuted={false}
                    onPlaybackFinish={() => {
                      try {
                        setShowHelp(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    posterResizeMode={'cover'}
                    rate={1}
                    resizeMode={'cover'}
                    usePoster={false}
                    {...GlobalStyles.VideoPlayerStyles(theme)['Video'].props}
                    posterSource={{ uri: '' }}
                    shouldPlay={true}
                    source={{
                      uri: 'https://xqoe-1bwl-8nfx.n7.xano.io/vault/Tv_YGkBM/hsyml66Iaz-jEgmRbK-pLVBU8AA/xgaUWA../vision_statement.mov',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.VideoPlayerStyles(theme)['Video'].style,
                        { height: 144, width: 256 }
                      ),
                      dimensions.width
                    )}
                    useNativeControls={false}
                    volume={1}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['List Bold'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['List Bold'].style,
                        { color: theme.colors['Divider'], textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Jayesh Patel\none of the attorneys at Street Democracy'}
                  </Text>
                  <IconButton
                    onPress={() => {
                      try {
                        setShowHelp(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                    color={theme.colors['Background']}
                    icon={'AntDesign/close'}
                    style={StyleSheet.applyWidth(
                      { marginTop: 64 },
                      dimensions.width
                    )}
                  />
                </View>
              </Modal>
            </>
          );
        }}
      </XanoApi.FetchUserGetGET>
    </ScreenContainer>
  );
};

export default withTheme(IntakeVisionScreen);
