import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const actionGetOneLocationPOST = (
  Constants,
  { action_id, location },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/action/location`, {
    body: JSON.stringify({ action_id: action_id, location: location }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useActionGetOneLocationPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['actionDetail', args],
    () => actionGetOneLocationPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['actionDetails']),
    }
  );
};

export const FetchActionGetOneLocationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action_id,
  location,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActionGetOneLocationPOST(
    { action_id, location },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchActionGetOneLocation: refetch,
  });
};

export const actionGetAllGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/action?user_id=${encodeQueryParam(
      `${typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useActionGetAllGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['actionLists', args],
    () => actionGetAllGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchActionGetAllGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActionGetAllGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActionGetAll: refetch });
};

export const actionGetOneGET = (Constants, { action_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/action/${
      typeof action_id === 'string'
        ? action_id
        : JSON.stringify(action_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useActionGetOneGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['actionDetail', args],
    () => actionGetOneGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['actionDetails']),
    }
  );
};

export const FetchActionGetOneGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActionGetOneGET(
    { action_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActionGetOne: refetch });
};

export const addUserPOST = (
  Constants,
  { date, email, name, password },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/auth/signup`, {
    body: JSON.stringify({
      email: email,
      name: name,
      password: password,
      date: date,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddUserPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAddUserPOST', args],
    () => addUserPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAddUserPOSTS']),
    }
  );
};

export const FetchAddUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  date,
  email,
  name,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAddUserPOST(
    { date, email, name, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddUser: refetch });
};

export const appGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/app/`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useAppGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAppGET', args],
    () => appGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAppGETS']),
    }
  );
};

export const FetchAppGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAppGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchApp: refetch });
};

export const authMeGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useAuthMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['userDetail', args],
    () => authMeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['userDetails']),
    }
  );
};

export const FetchAuthMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAuthMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuthMe: refetch });
};

export const caseBackgroundPATCH = (
  Constants,
  { background, case_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/background`, {
    body: JSON.stringify({ case_id: case_id, background: background }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCaseBackgroundPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseBackgroundPATCH', args],
    () => caseBackgroundPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCaseBackgroundPATCHES']),
    }
  );
};

export const caseFilePOST = (
  Constants,
  { case_id, file, filename, note },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/file`, {
    body: JSON.stringify({
      content: file,
      filename: filename,
      case_id: case_id,
      note: note,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCaseFilePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseFilePOST', args],
    () => caseFilePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCaseFilePOSTS']),
    }
  );
};

export const FetchCaseFilePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  case_id,
  file,
  filename,
  note,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseFilePOST(
    { case_id, file, filename, note },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseFile: refetch });
};

export const caseFlagDeletePATCH = (
  Constants,
  { case_flag_id, case_id },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/case_flag/delete`,
    {
      body: JSON.stringify({ case_id: case_id, case_flag_id: case_flag_id }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    }
  ).then(res => handleResponse(res, handlers));

export const useCaseFlagDeletePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseFlagDeletePATCH', args],
    () => caseFlagDeletePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCaseFlagDeletePATCHES']),
    }
  );
};

export const caseGetOneGET = (Constants, { case_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/${
      typeof case_id === 'string' ? case_id : JSON.stringify(case_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCaseGetOneGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['caseDetail', args],
    () => caseGetOneGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['caseDetails']),
    }
  );
};

export const FetchCaseGetOneGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  case_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseGetOneGET(
    { case_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseGetOne: refetch });
};

export const caseListGET = (
  Constants,
  { keyword, program_id },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case?program_id=${encodeQueryParam(
      `${
        typeof program_id === 'string'
          ? program_id
          : JSON.stringify(program_id ?? '')
      }`
    )}&keyword=${encodeQueryParam(
      `${typeof keyword === 'string' ? keyword : JSON.stringify(keyword ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCaseListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['caseLists', args],
    () => caseListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCaseListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keyword,
  program_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseListGET(
    { keyword, program_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseList: refetch });
};

export const caseListArchiveGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/archive/`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCaseListArchiveGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseListArchiveGET', args],
    () => caseListArchiveGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCaseListArchiveGETS']),
    }
  );
};

export const FetchCaseListArchiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseListArchiveGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseListArchive: refetch });
};

export const caseListCleanupGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/cleanup/`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCaseListCleanupGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['caseListCleanups', args],
    () => caseListCleanupGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCaseListCleanupGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseListCleanupGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseListCleanup: refetch });
};

export const caseListNewGET = (Constants, { program_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/program/${
      typeof program_id === 'string'
        ? program_id
        : JSON.stringify(program_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCaseListNewGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['caseListNews', args],
    () => caseListNewGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCaseListNewGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseListNewGET(
    { program_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseListNew: refetch });
};

export const caseNotePOST = (Constants, { case_id, note }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/note`, {
    body: JSON.stringify({ case_id: case_id, note: note }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCaseNotePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseNotePOST', args],
    () => caseNotePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCaseNotePOSTS']),
    }
  );
};

export const FetchCaseNotePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  case_id,
  note,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseNotePOST(
    { case_id, note },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseNote: refetch });
};

export const caseNotesGetGET = (Constants, { case_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/note/${
      typeof case_id === 'string' ? case_id : JSON.stringify(case_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCaseNotesGetGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['caseNotes', args],
    () => caseNotesGetGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCaseNotesGetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  case_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCaseNotesGetGET(
    { case_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCaseNotesGet: refetch });
};

export const casePostPOST = (
  Constants,
  { program_id, provider_note, user_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case`, {
    body: JSON.stringify({
      participant_id: user_id,
      program_id: program_id,
      provider_note: provider_note,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCasePostPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => casePostPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('casePost', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('casePost');
        queryClient.invalidateQueries('casePosts');
      },
    }
  );
};

export const FetchCasePostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_id,
  provider_note,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCasePostPOST(
    { program_id, provider_note, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCasePost: refetch });
};

export const caseProviderEditPATCH = (
  Constants,
  { case_id, note },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/provider`, {
    body: JSON.stringify({ case_id: case_id, referral_note: note }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCaseProviderEditPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseProviderEditPATCH', args],
    () => caseProviderEditPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCaseProviderEditPATCHES']),
    }
  );
};

export const caseSignPATCH = (Constants, { case_id }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/sign`, {
    body: JSON.stringify({ case_id: case_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCaseSignPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseSignPATCH', args],
    () => caseSignPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCaseSignPATCHES']),
    }
  );
};

export const caseUpdatePATCH = (
  Constants,
  {
    case_id,
    case_list,
    case_type,
    defender_user_id,
    due_at_end,
    due_at_state,
    flag_court,
    flag_defender,
    flag_participant,
    flag_prosecutor,
    flag_provider,
    flag_special,
    judge_user_id,
    jurisdiction_id,
    next_date,
    number_of,
    program_id,
    provider_note,
    provider_org_id,
    provider_user_org,
    referring_judge_user_id,
    status,
    update,
    win,
  },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/case/update`, {
    body: JSON.stringify({
      case_type: case_type,
      case_list: case_list,
      number_of: number_of,
      due_at_start: due_at_state,
      due_at_end: due_at_end,
      case_id: case_id,
      status: status,
      update: update,
      flag_participant: flag_participant,
      flag_provider: flag_provider,
      flag_defender: flag_defender,
      flag_prosecutor: flag_prosecutor,
      flag_court: flag_court,
      flag_special: flag_special,
      provider_org_id: provider_org_id,
      provider_user_id: provider_user_org,
      next_date: next_date,
      provider_note: provider_note,
      jurisdiction_id: jurisdiction_id,
      program_id: program_id,
      judge_user_id: judge_user_id,
      referring_judge_user_id: referring_judge_user_id,
      flag_win: win,
      defender_user_id: defender_user_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCaseUpdatePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCaseUpdatePATCH', args],
    () => caseUpdatePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCaseUpdatePATCHES']),
    }
  );
};

export const casesCSVPOST = (
  Constants,
  { program_id, status },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/cases/csv/`, {
    body: JSON.stringify({ program_id: program_id, status: status }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCasesCSVPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCasesCSVPOST', args],
    () => casesCSVPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCasesCSVPOSTS']),
    }
  );
};

export const FetchCasesCSVPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_id,
  status,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCasesCSVPOST(
    { program_id, status },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCasesCSV: refetch });
};

export const checkinAddPOST = (
  Constants,
  { action_id, user_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin`, {
    body: JSON.stringify({ user_id: user_id, action_id: action_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCheckinAddPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCheckinAddPOST', args],
    () => checkinAddPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoCheckinAddPOSTS']),
    }
  );
};

export const FetchCheckinAddPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckinAddPOST(
    { action_id, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckinAdd: refetch });
};

export const checkinDeleteDELETE = (Constants, { checkin }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin/${
      typeof checkin === 'string' ? checkin : JSON.stringify(checkin ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useCheckinDeleteDELETE = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCheckinDeleteDELETE', args],
    () => checkinDeleteDELETE(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCheckinDeleteDELETES']),
    }
  );
};

export const checkinDenyPATCH = (Constants, { checkin_id }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin/deny`, {
    body: JSON.stringify({ checkin_id: checkin_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCheckinDenyPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCheckinDenyPATCH', args],
    () => checkinDenyPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCheckinDenyPATCHES']),
    }
  );
};

export const checkinGetOneGET = (Constants, { checkin_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin/${
      typeof checkin_id === 'string'
        ? checkin_id
        : JSON.stringify(checkin_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useCheckinGetOneGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['checkinDetail', args],
    () => checkinGetOneGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['checkinDetails']),
    }
  );
};

export const FetchCheckinGetOneGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  checkin_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckinGetOneGET(
    { checkin_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckinGetOne: refetch });
};

export const checkinListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCheckinListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['checkinLists', args],
    () => checkinListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchCheckinListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckinListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckinList: refetch });
};

export const checkinRequestPATCH = (
  Constants,
  { checkin_id, organization_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin/request`, {
    body: JSON.stringify({
      checkin_id: checkin_id,
      organization_id: organization_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCheckinRequestPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCheckinRequestPATCH', args],
    () => checkinRequestPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCheckinRequestPATCHES']),
    }
  );
};

export const checkinVerifyPATCH = (
  Constants,
  { checkin_id, note, verified },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/checkin/verify`, {
    body: JSON.stringify({
      checkin_id: checkin_id,
      provider_note: note,
      verified: verified,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useCheckinVerifyPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoCheckinVerifyPATCH', args],
    () => checkinVerifyPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoCheckinVerifyPATCHES']),
    }
  );
};

export const fAQDetailGET = (Constants, { faq_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/faq/${
      typeof faq_id === 'string' ? faq_id : JSON.stringify(faq_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useFAQDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['faqDetail', args],
    () => fAQDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['faqDetails']),
    }
  );
};

export const FetchFAQDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  faq_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFAQDetailGET(
    { faq_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFAQDetail: refetch });
};

export const fAQListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/faq`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useFAQListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['faqLists', args],
    () => fAQListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFAQListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFAQListGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFAQList: refetch });
};

export const loginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const loginPublicPOST = (
  Constants,
  { email, password },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/auth/login_public`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPublicPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoLoginPublicPOST', args],
    () => loginPublicPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoLoginPublicPOSTS']),
    }
  );
};

export const FetchLoginPublicPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPublicPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginPublic: refetch });
};

export const notePATCH = (Constants, { filename, value }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/note`, {
    body: JSON.stringify({ filename: filename, file: value }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useNotePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoNotePATCH', args],
    () => notePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoNotePATCHES']),
    }
  );
};

export const onboardingContactPATCH = (
  Constants,
  { city, state, street, tel_alt, tel_best, user_id, zip },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/onboarding/contact`, {
    body: JSON.stringify({
      user_id: user_id,
      address_street: street,
      address_city: city,
      address_st: state,
      address_zip: zip,
      tel_best: tel_best,
      tel_alt: tel_alt,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useOnboardingContactPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoOnboardingContactPATCH', args],
    () => onboardingContactPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoOnboardingContactPATCHES']),
    }
  );
};

export const onboardingNamePATCH = (
  Constants,
  { date_of_birth, first_name, last_name, middle_name, state_id, user_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/onboarding/name`, {
    body: JSON.stringify({
      user_id: user_id,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      date_of_birth: date_of_birth,
      state_id: state_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useOnboardingNamePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoOnboardingNamePATCH', args],
    () => onboardingNamePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoOnboardingNamePATCHES']),
    }
  );
};

export const onboardingVisionPATCH = (Constants, { vision }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/onboarding/vision`, {
    body: JSON.stringify({ vision_statement: vision }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useOnboardingVisionPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoOnboardingVisionPATCH', args],
    () => onboardingVisionPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoOnboardingVisionPATCHES']),
    }
  );
};

export const orgListJusticePOST = (Constants, { location }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/organization/justice`, {
    body: JSON.stringify({ location: location }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useOrgListJusticePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['orgListJustices', args],
    () => orgListJusticePOST(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchOrgListJusticePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  location,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOrgListJusticePOST(
    { location },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOrgListJustice: refetch });
};

export const organizationDetailGET = (
  Constants,
  { organization_id },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/organization/${
      typeof organization_id === 'string'
        ? organization_id
        : JSON.stringify(organization_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useOrganizationDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['orgDetail', args],
    () => organizationDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['orgDetails']),
    }
  );
};

export const FetchOrganizationDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  organization_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOrganizationDetailGET(
    { organization_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOrganizationDetail: refetch });
};

export const organizationListGET = (
  Constants,
  { location, type },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/organization?location=${encodeQueryParam(
      `${
        typeof location === 'string' ? location : JSON.stringify(location ?? '')
      }`
    )}&type=${encodeQueryParam(
      `${typeof type === 'string' ? type : JSON.stringify(type ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useOrganizationListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['orgLists', args],
    () => organizationListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchOrganizationListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  location,
  type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOrganizationListGET(
    { location, type },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOrganizationList: refetch });
};

export const organizationListLocationPOST = (
  Constants,
  { location },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/organization`, {
    body: JSON.stringify({ location: location }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useOrganizationListLocationPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['orgLists', args],
    () => organizationListLocationPOST(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchOrganizationListLocationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  location,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOrganizationListLocationPOST(
    { location },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchOrganizationListLocation: refetch,
  });
};

export const pINPATCH = (Constants, { email }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/auth/get-pin`, {
    body: JSON.stringify({ email: email }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const usePINPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoPINPATCH', args],
    () => pINPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoPINPATCHES']),
    }
  );
};

export const participantActionPlanEditPOST = (
  Constants,
  { action_plan_id, note },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/action_plan/${
      typeof action_plan_id === 'string'
        ? action_plan_id
        : JSON.stringify(action_plan_id ?? '')
    }`,
    {
      body: JSON.stringify({ private_note: note }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useParticipantActionPlanEditPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoParticipantActionPlanEditPOST', args],
    () => participantActionPlanEditPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoParticipantActionPlanEditPOSTS']),
    }
  );
};

export const FetchParticipantActionPlanEditPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action_plan_id,
  note,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useParticipantActionPlanEditPOST(
    { action_plan_id, note },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchParticipantActionPlanEdit: refetch,
  });
};

export const participantActionPlanGetGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/action_plan`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useParticipantActionPlanGetGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoParticipantActionPlanGetGET', args],
    () => participantActionPlanGetGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoParticipantActionPlanGetGETS']),
    }
  );
};

export const FetchParticipantActionPlanGetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useParticipantActionPlanGetGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchParticipantActionPlanGet: refetch,
  });
};

export const participantEditFirstNamePATCH = (
  Constants,
  { firstName },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/edit_first_name`, {
    body: JSON.stringify({ first_name: firstName }),
    headers: {
      Accept: 'application/json',
      Authorization:
        'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.X_OGmwn33pkPEXa8MHa0WrgbrNdhE7KNBmabsBpQOo3rlt5my316Fm6A4q_xjQSTcVzpOqOCZXLirPz6vrr446hNVwVPPCEg.ovPTIdM7Bqk1ydG6h4S0Sw.XIXfXn-Ek4N9zfw4EskInVIp7BSW0cYjApZ15h_NALT_DyMVepT9I4wasla-bH8ypl9V3KIr6sBde8EGnL7i_MUp2oxNWeAtFZ9pfaHqnx9C_-wu4y8IFIM1756fYwGLbFomILf35MfYKCjm8Y3YaRgrgs5lw0svPnjGaVg2RGU.-TyLCCYVjiyjcDCdRqusnAsal0tj-mgVU6m7BL5Uebo',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useParticipantEditFirstNamePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoParticipantEditFirstNamePATCH', args],
    () => participantEditFirstNamePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoParticipantEditFirstNamePATCHES']),
    }
  );
};

export const participantEditUserPATCH = (
  Constants,
  {
    alt,
    city,
    dob,
    email,
    first,
    last,
    middle,
    org,
    role,
    state,
    state_id,
    street,
    tel,
    vision,
    zip,
  },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/participant/edit`, {
    body: JSON.stringify({
      first_name: first,
      middle_name: middle,
      last_name: last,
      email: email,
      date_of_birth: dob,
      role: role,
      organization_id: org,
      state_id: state_id,
      address_street: street,
      address_city: city,
      address_st: state,
      address_zip: zip,
      vision_statement: vision,
      tel_best: tel,
      tel_alt: alt,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useParticipantEditUserPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoParticipantEditUserPATCH', args],
    () => participantEditUserPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoParticipantEditUserPATCHES']),
    }
  );
};

export const participantOrganizationsGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/participant/organizations`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useParticipantOrganizationsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoParticipantOrganizationsGET', args],
    () => participantOrganizationsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoParticipantOrganizationsGETS']),
    }
  );
};

export const FetchParticipantOrganizationsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useParticipantOrganizationsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchParticipantOrganizations: refetch,
  });
};

export const programGetOneGET = (Constants, { program_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/program/${
      typeof program_id === 'string'
        ? program_id
        : JSON.stringify(program_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useProgramGetOneGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['programDetail', args],
    () => programGetOneGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['programDetails']),
    }
  );
};

export const FetchProgramGetOneGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useProgramGetOneGET(
    { program_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProgramGetOne: refetch });
};

export const programListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/program`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useProgramListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['programLists', args],
    () => programListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchProgramListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useProgramListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProgramList: refetch });
};

export const publicActionListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/public/action`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const usePublicActionListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoPublicActionListGET', args],
    () => publicActionListGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoPublicActionListGETS']),
    }
  );
};

export const FetchPublicActionListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePublicActionListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPublicActionList: refetch });
};

export const publicActionSinglePOST = (
  Constants,
  { action_id, location },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/public/action_location`,
    {
      body: JSON.stringify({ action_id: action_id, location: location }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const usePublicActionSinglePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoPublicActionSinglePOST', args],
    () => publicActionSinglePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoPublicActionSinglePOSTS']),
    }
  );
};

export const FetchPublicActionSinglePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action_id,
  location,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePublicActionSinglePOST(
    { action_id, location },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPublicActionSingle: refetch });
};

export const quoteGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/quote`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useQuoteGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoQuoteGET', args],
    () => quoteGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoQuoteGETS']),
    }
  );
};

export const FetchQuoteGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useQuoteGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchQuote: refetch });
};

export const reportingProgramGET = (
  Constants,
  { programID, year },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/reporting/program?program_id=${encodeQueryParam(
      `${
        typeof programID === 'string'
          ? programID
          : JSON.stringify(programID ?? '')
      }`
    )}&year=${encodeQueryParam(
      `${typeof year === 'string' ? year : JSON.stringify(year ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useReportingProgramGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoReportingProgramGET', args],
    () => reportingProgramGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoReportingProgramGETS']),
    }
  );
};

export const FetchReportingProgramGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  programID,
  year,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useReportingProgramGET(
    { programID, year },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReportingProgram: refetch });
};

export const reportingProviderGET = (
  Constants,
  { programID, year },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/reporting/provider?program_id=${encodeQueryParam(
      `${
        typeof programID === 'string'
          ? programID
          : JSON.stringify(programID ?? '')
      }`
    )}&year=${encodeQueryParam(
      `${typeof year === 'string' ? year : JSON.stringify(year ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useReportingProviderGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoReportingProviderGET', args],
    () => reportingProviderGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoReportingProviderGETS']),
    }
  );
};

export const FetchReportingProviderGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  programID,
  year,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useReportingProviderGET(
    { programID, year },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReportingProvider: refetch });
};

export const resourceGetOneGET = (Constants, { resource_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/resource/${
      typeof resource_id === 'string'
        ? resource_id
        : JSON.stringify(resource_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useResourceGetOneGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['resourceGetOne', args],
    () => resourceGetOneGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['resourceGetOnes']),
    }
  );
};

export const FetchResourceGetOneGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  resource_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResourceGetOneGET(
    { resource_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResourceGetOne: refetch });
};

export const resourceListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/resource/faq/`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useResourceListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['resourceList', args],
    () => resourceListGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['resourceLists']),
    }
  );
};

export const FetchResourceListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResourceListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResourceList: refetch });
};

export const uploadPOST = (Constants, { value }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/upload/attachment`, {
    body: JSON.stringify({ content: value }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useUploadPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUploadPOST', args],
    () => uploadPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUploadPOSTS']),
    }
  );
};

export const FetchUploadPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  value,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUploadPOST(
    { value },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpload: refetch });
};

export const userCaseAddPATCH = (
  Constants,
  { participant_id, program_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/add_case`, {
    body: JSON.stringify({
      participant_id: participant_id,
      program_id: program_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserCaseAddPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserCaseAddPATCH', args],
    () => userCaseAddPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoUserCaseAddPATCHES']),
    }
  );
};

export const userCheckinAddPATCH = (
  Constants,
  { action_id, check_in, user_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/add_checkin`, {
    body: JSON.stringify({
      user_id: user_id,
      action_id: action_id,
      checkin_id: check_in,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserCheckinAddPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserCheckinAddPATCH', args],
    () => userCheckinAddPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoUserCheckinAddPATCHES']),
    }
  );
};

export const userCheckinDenyPATCH = (
  Constants,
  { checkin_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/checkin_deny`, {
    body: JSON.stringify({ checkin_id: checkin_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserCheckinDenyPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserCheckinDenyPATCH', args],
    () => userCheckinDenyPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoUserCheckinDenyPATCHES']),
    }
  );
};

export const userCheckinVerifyPATCH = (
  Constants,
  { action_id, checkin_id },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/checkin_verify`, {
    body: JSON.stringify({ checkin_id: checkin_id, action_id: action_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserCheckinVerifyPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserCheckinVerifyPATCH', args],
    () => userCheckinVerifyPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoUserCheckinVerifyPATCHES']),
    }
  );
};

export const userGetGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useUserGetGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['userDetails2', args],
    () => userGetGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['userDetails2s']),
    }
  );
};

export const FetchUserGetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserGetGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserGet: refetch });
};

export const userListGET = (
  Constants,
  { dob, keyword, last_name },
  handlers = {}
) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user?last_name=${encodeQueryParam(
      `${
        typeof last_name === 'string'
          ? last_name
          : JSON.stringify(last_name ?? '')
      }`
    )}&dob=${encodeQueryParam(
      `${typeof dob === 'string' ? dob : JSON.stringify(dob ?? '')}`
    )}&keyword=${encodeQueryParam(
      `${typeof keyword === 'string' ? keyword : JSON.stringify(keyword ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useUserListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['userLists', args],
    () => userListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchUserListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dob,
  keyword,
  last_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserListGET(
    { dob, keyword, last_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserList: refetch });
};

export const userPatchPATCH = (
  Constants,
  {
    admin,
    alt,
    bar,
    city,
    disability,
    dob,
    edu,
    email,
    entry_adult,
    entry_benefits,
    entry_cause,
    entry_child,
    entry_disposable,
    entry_housing,
    entry_income,
    entry_saved,
    entry_source,
    first,
    last,
    lead,
    middle,
    privacy,
    race,
    role,
    sex,
    state,
    state_id,
    status,
    street,
    tel,
    user_id,
    vet,
    vision,
    works_for,
    zip,
  },
  handlers = {}
) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user`, {
    body: JSON.stringify({
      user_id: user_id,
      name_first: first,
      name_middle: middle,
      name_last: last,
      address_street: street,
      address_city: city,
      address_state: state,
      address_zip: zip,
      tel: tel,
      tel_alt: alt,
      email: email,
      state_id: state_id,
      date_of_birth: dob,
      sex: sex,
      vet: vet,
      race: race,
      disability: disability,
      edu: edu,
      entry_housing: entry_housing,
      entry_cause: entry_cause,
      entry_hh_adult: entry_adult,
      entry_hh_child: entry_child,
      entry_benefits: entry_benefits,
      entry_source: entry_source,
      entry_income: entry_income,
      entry_saved: entry_saved,
      entry_disposable: entry_disposable,
      vision_statement: vision,
      role: role,
      works_for: works_for,
      status: status,
      bar: bar,
      lead: lead,
      admin: admin,
      privacy: privacy,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserPatchPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => userPatchPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('updateUser', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('updateUser');
        queryClient.invalidateQueries('updateUsers');
      },
    }
  );
};

export const userReportGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/report/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useUserReportGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['userReport', args],
    () => userReportGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['userReports']),
    }
  );
};

export const FetchUserReportGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserReportGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserReport: refetch });
};

export const userSearchPATCH = (Constants, { search_term }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/search`, {
    body: JSON.stringify({ search_term: search_term }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).then(res => handleResponse(res, handlers));

export const useUserSearchPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserSearchPATCH', args],
    () => userSearchPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUserSearchPATCHES']),
    }
  );
};

export const userVisionPOST = (Constants, { inputVision }, handlers = {}) =>
  fetch(`https://xqoe-1bwl-8nfx.n7.xano.io/api:MrtRcPr8/user/vision/`, {
    body: JSON.stringify({ vision_statement: inputVision }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useUserVisionPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoUserVisionPOST', args],
    () => userVisionPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoUserVisionPOSTS']),
    }
  );
};

export const FetchUserVisionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  inputVision,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useUserVisionPOST(
    { inputVision },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUserVision: refetch });
};
