import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CaseNotesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [allNotes, setAllNotes] = React.useState(false);
  const [caseNotes, setCaseNotes] = React.useState('');
  const [fileUpload, setFileUpload] = React.useState('');
  const [filename, setFilename] = React.useState('');
  const [showAddForm, setShowAddForm] = React.useState(false);
  const [textAreaValue2, setTextAreaValue2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(props.route?.params?.selected_case ?? '');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'flex-start' },
            dimensions.width
          )}
        >
          {/* title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Big Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Big Header'].style,
              dimensions.width
            )}
          >
            {'\nnotes'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
              dimensions.width
            )}
          >
            {
              (props.route?.params?.selected_case ?? '')?.participant
                ?.name_first
            }{' '}
            {(props.route?.params?.selected_case ?? '')?.participant?.name_last}
            {'\n#'}
            {(props.route?.params?.selected_case ?? '')?.case?.id}
            {' - '}
            {(props.route?.params?.selected_case ?? '')?.case?.case_type}
            {'\n'}
            {props.route?.params?.jurisdiction ?? ''}
          </Text>
          {/* switch */}
          <>
            {!(Constants['user_details']?.role === 'defender') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'stretch',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <>
                  {allNotes ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors['Light'],
                            fontFamily: 'Assistant_600SemiBold',
                            fontSize: 14,
                            padding: 4,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'all'}
                    </Text>
                  )}
                </>
                {/* Text 3 */}
                <>
                  {!allNotes ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'Assistant_700Bold',
                            fontSize: 14,
                            padding: 4,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'all'}
                    </Text>
                  )}
                </>
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setAllNotes(newSwitchValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={allNotes}
                />
              </View>
            )}
          </>
        </View>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 32,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={false}
      >
        <View>
          <XanoApi.FetchCaseNotesGetGET
            case_id={Constants['selected_case']?.case?.id}
          >
            {({ loading, error, data, refetchCaseNotesGet }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* list */}
                  <>
                    {showAddForm ? null : (
                      <View>
                        <FlatList
                          data={fetchData?.notes}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ?? listData?.uuid ?? index.toString()
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'8YZ3dVa0'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* case only */}
                                <>
                                  {allNotes ? null : (
                                    <View>
                                      {/* equal to */}
                                      <>
                                        {!(
                                          Constants['selected_case']?.case
                                            ?.id === listData?.fields?.case_id
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'stretch',
                                                flexDirection: 'column',
                                                marginBottom: 16,
                                                marginTop: 16,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Grey - Text'].props}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  {
                                                    color:
                                                      theme.colors['Light'],
                                                    textAlign: 'left',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.fields?.date}
                                              {' - '}
                                              {listData?.fields?.user_id}
                                            </Text>

                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Background Input'
                                                    ],
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  borderRadius: 4,
                                                  borderWidth: 1,
                                                  flex: 1,
                                                  justifyContent: 'center',
                                                  padding: 4,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors['Strong'],
                                                      fontFamily:
                                                        'Assistant_400Regular',
                                                      fontSize: 16,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?.fields?.note}
                                                </Text>
                                                <>
                                                  {!listData?.fields?.file?.[0]
                                                    .filename ? null : (
                                                    <IconButton
                                                      onPress={() => {
                                                        try {
                                                          navigation.navigate(
                                                            'FileViewScreen',
                                                            {
                                                              file_url:
                                                                listData?.fields
                                                                  ?.file?.[0]
                                                                  .url,
                                                            }
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      }}
                                                      size={32}
                                                      icon={
                                                        'Ionicons/ios-document-attach-outline'
                                                      }
                                                    />
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* all */}
                                <>
                                  {!allNotes ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'stretch',
                                          flexDirection: 'column',
                                          marginBottom: 16,
                                          marginTop: 16,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].props}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            {
                                              color: theme.colors['Light'],
                                              textAlign: 'left',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.fields?.date}
                                        {' by '}
                                        {listData?.fields?.user_id}
                                        {' in #'}
                                        {listData?.fields?.case_id}
                                      </Text>

                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Background Input'],
                                            borderColor:
                                              theme.colors['Divider'],
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            flex: 1,
                                            justifyContent: 'center',
                                            padding: 4,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors['Strong'],
                                                fontFamily:
                                                  'Assistant_400Regular',
                                                fontSize: 16,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.fields?.note}
                                          </Text>
                                          <>
                                            {!listData?.fields?.file?.[0]
                                              .filename ? null : (
                                              <IconButton
                                                onPress={() => {
                                                  try {
                                                    navigation.navigate(
                                                      'FileViewScreen',
                                                      {
                                                        file_url:
                                                          listData?.fields
                                                            ?.file?.[0].url,
                                                      }
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                size={32}
                                                icon={
                                                  'Ionicons/ios-document-attach-outline'
                                                }
                                              />
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          contentContainerStyle={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        />
                        {/* add file */}
                        <Touchable
                          onPress={() => {
                            try {
                              /* hidden 'Set Variable' action */
                              navigation.navigate('CaseNoteAddScreen', {
                                selected_case:
                                  props.route?.params?.selected_case ?? '',
                                case_id: fetchData?.case?.id,
                                name_first:
                                  Constants['selected_case']?.participant
                                    ?.name_first,
                                name_last:
                                  Constants['selected_case']?.participant
                                    ?.name_last,
                                jurisdiction:
                                  Constants['selected_case']?.jurisdiction
                                    ?.entity,
                                case_type: fetchData?.case?.case_type,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'add a note or file'}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={
                                'MaterialCommunityIcons/file-document-edit-outline'
                              }
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      </View>
                    )}
                  </>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={'rgba(0, 0, 0, 0)'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      dimensions.width
                    )}
                  />
                </>
              );
            }}
          </XanoApi.FetchCaseNotesGetGET>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(CaseNotesScreen);
