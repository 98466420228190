import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CheckinDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [edit, setEdit] = React.useState(false);
  const [org_1, setOrg_1] = React.useState(
    props.route?.params?.user_org_id ?? ''
  );
  const [org_2, setOrg_2] = React.useState(
    props.route?.params?.checkin_provider_id ?? ''
  );
  const [showSwitch, setShowSwitch] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(props.route?.params?.participant ?? '');
      if (org_1 !== org_2) {
        return;
      }
      setTextAreaValue((props.route?.params?.checkin ?? '')?.provider_note);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column', paddingBottom: 32 },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
            dimensions.width
          )}
        >
          {'\n\naction'}
        </Text>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {(props.route?.params?.checkin ?? '')?.action}
        </Text>
        {/* switch */}
        <>
          {!(
            Constants['user_details']?.works_for ===
            (props.route?.params?.checkin ?? '')
          )?.provider_org_id ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'stretch',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <>
                {edit ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors['Light'],
                          fontFamily: 'Assistant_600SemiBold',
                          fontSize: 14,
                          padding: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'edit'}
                  </Text>
                )}
              </>
              {/* Text 3 */}
              <>
                {!edit ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Assistant_700Bold',
                          fontSize: 14,
                          padding: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'edit'}
                  </Text>
                )}
              </>
              <Switch
                onValueChange={newSwitchValue => {
                  try {
                    setEdit(newSwitchValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={edit}
              />
            </View>
          )}
        </>
      </View>
      {/* Keyboard Aware Scroll View 2 */}
      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Background'],
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
          },
          dimensions.width
        )}
        showsVerticalScrollIndicator={false}
      >
        {/* detail */}
        <>
          {edit ? null : (
            <View>
              {/* participant */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 32 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'participant'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { textAlign: 'auto' }
                    ),
                    dimensions.width
                  )}
                >
                  {(props.route?.params?.participant ?? '')?.name_first}{' '}
                  {(props.route?.params?.participant ?? '')?.name_middle}{' '}
                  {(props.route?.params?.participant ?? '')?.name_last}
                  {'\n'}
                  {(props.route?.params?.participant ?? '')?.date_of_birth}
                </Text>
              </View>
              {/* if verified */}
              <>
                {!(props.route?.params?.checkin ?? '')?.verified ? null : (
                  <View>
                    {/* provider */}
                    <>
                      {!(props.route?.params?.checkin ?? '')
                        ?.provider_org_id ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 32 },
                            dimensions.width
                          )}
                        >
                          {/* if verified */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'verified by'}
                          </Text>
                          {/* Text 3 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { textAlign: 'auto' }
                              ),
                              dimensions.width
                            )}
                          >
                            {(props.route?.params?.checkin ?? '')?.entity}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { textAlign: 'auto' }
                              ),
                              dimensions.width
                            )}
                          >
                            {'/s/ '}
                            {
                              (props.route?.params?.checkin ?? '')?.name_first
                            }{' '}
                            {(props.route?.params?.checkin ?? '')?.name_last}
                            {' - '}
                            {(props.route?.params?.checkin ?? '')?.date}
                          </Text>
                          <>
                            {!(props.route?.params?.checkin ?? '')
                              ?.provider_note ? null : (
                              <View>
                                <>
                                  {!(
                                    (props.route?.params?.checkin_provider_id ??
                                      '') ===
                                    (props.route?.params?.user_org_id ?? '')
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Background Input'],
                                          borderColor: theme.colors['Divider'],
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          padding: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { fontSize: 14 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          (props.route?.params?.checkin ?? '')
                                            ?.provider_note
                                        }
                                      </Text>
                                    </View>
                                  )}
                                </>
                                {/* View 2 */}
                                <>
                                  {!(
                                    Constants['user_details']?.role ===
                                    'defender'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            theme.colors['Background Input'],
                                          borderColor: theme.colors['Divider'],
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          padding: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { fontSize: 14 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          (props.route?.params?.checkin ?? '')
                                            ?.provider_note
                                        }
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* options */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 32 },
                  dimensions.width
                )}
              >
                {/* verify checkin */}
                <>
                  {!(
                    Constants['user_details']?.role !== 'participant'
                  ) ? null : (
                    <View>
                      {/* right button */}
                      <>
                        {(props.route?.params?.checkin ?? '')
                          ?.verified ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                setEdit(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TouchableStyles(theme)[
                                'touchable icon right'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'flex-start',
                                    flex: 1,
                                    flexWrap: 'nowrap',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Right Link Text'
                                      ].style,
                                      { alignSelf: 'flex-end' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'verify checkin'}
                                </Text>
                              </View>
                              <Icon
                                size={24}
                                color={theme.colors['Primary']}
                                name={'MaterialCommunityIcons/check-decagram'}
                                style={StyleSheet.applyWidth(
                                  { marginLeft: 8 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                  )}
                </>
                {/* request a verification */}
                <>
                  {(props.route?.params?.checkin ?? '')?.verified ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('OrgList2Screen', {
                            checkin: props.route?.params?.checkin ?? '',
                            participant: props.route?.params?.participant ?? '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.TouchableStyles(theme)[
                        'touchable icon right'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TouchableStyles(theme)[
                          'touchable icon right'
                        ].style,
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'flex-start',
                              flex: 1,
                              flexWrap: 'nowrap',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Right Link Text'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].style,
                                { alignSelf: 'flex-end' }
                              ),
                              dimensions.width
                            )}
                          >
                            {'request verification'}
                          </Text>
                        </View>
                        <Icon
                          size={24}
                          color={theme.colors['Primary']}
                          name={'MaterialCommunityIcons/alert-decagram-outline'}
                          style={StyleSheet.applyWidth(
                            { marginLeft: 8 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </Touchable>
                  )}
                </>
                {/* connect */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('ActionDetailsScreen', {
                        action_id: (props.route?.params?.checkin ?? '')
                          ?.action_id,
                        checkin_id: (props.route?.params?.checkin ?? '')?.id,
                        participant: props.route?.params?.participant ?? '',
                        checkin: props.route?.params?.checkin ?? '',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.TouchableStyles(theme)[
                    'touchable icon right'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TouchableStyles(theme)['touchable icon right']
                      .style,
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'flex-start',
                          flex: 1,
                          flexWrap: 'nowrap',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { alignSelf: 'flex-end' }
                          ),
                          dimensions.width
                        )}
                      >
                        {'connect to resources'}
                      </Text>
                    </View>
                    <Icon
                      size={24}
                      color={theme.colors['Primary']}
                      name={'MaterialCommunityIcons/account-search'}
                      style={StyleSheet.applyWidth(
                        { marginLeft: 8 },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
                <>
                  {!(Constants['works_for']?.type !== 'justice') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-end', marginTop: 64 },
                        dimensions.width
                      )}
                    >
                      <>
                        {(props.route?.params?.checkin ?? '')
                          ?.verified ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'flex-end' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {'other options'}
                            </Text>
                            {/* delete */}
                            <>
                              {(props.route?.params?.checkin ?? '')
                                ?.verified ? null : (
                                <Touchable
                                  onLongPress={() => {
                                    const handler = async () => {
                                      try {
                                        (
                                          await XanoApi.checkinDeleteDELETE(
                                            Constants,
                                            {
                                              checkin: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.id,
                                            }
                                          )
                                        )?.json;
                                        navigation.goBack();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  {...GlobalStyles.TouchableStyles(theme)[
                                    'touchable icon right'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TouchableStyles(theme)[
                                        'touchable icon right'
                                      ].style,
                                      { marginTop: 0 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignContent: 'flex-start',
                                          flex: 1,
                                          flexWrap: 'nowrap',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Right Link Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Right Link Text'
                                            ].style,
                                            {
                                              alignSelf: 'flex-end',
                                              opacity: 0.5,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'delete checkin'}
                                      </Text>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          )}
        </>
        {/* edit */}
        <>
          {!edit ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['edit'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['edit'].style,
                dimensions.width
              )}
            >
              {/* participant 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 32 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'participant'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { textAlign: 'auto' }
                    ),
                    dimensions.width
                  )}
                >
                  {(props.route?.params?.participant ?? '')?.name_first}{' '}
                  {(props.route?.params?.participant ?? '')?.name_middle}{' '}
                  {(props.route?.params?.participant ?? '')?.name_last}
                  {'\n'}
                  {(props.route?.params?.participant ?? '')?.date_of_birth}
                </Text>
              </View>
              {/* action 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 32 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'action'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { textAlign: 'auto' }
                    ),
                    dimensions.width
                  )}
                >
                  {(props.route?.params?.checkin ?? '')?.action}
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 32 },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'verification details'}
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Caption'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Caption'].style,
                      { textAlign: 'auto' }
                    ),
                    dimensions.width
                  )}
                >
                  {'be short & sweet: (what) (dated) from (where)'}
                </Text>
                {/* input */}
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newInputValue => {
                    try {
                      setTextAreaValue(newInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={true}
                  numberOfLines={10}
                  placeholder={
                    'application confirmation email dated 11/5 from Detroit Housing'
                  }
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      { marginTop: 8 }
                    ),
                    dimensions.width
                  )}
                  value={textAreaValue}
                />
              </View>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Caption'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Caption'].style,
                    { marginTop: 32 }
                  ),
                  dimensions.width
                )}
              >
                {
                  'By clicking here, you are confirming that either (a) the participant made meaningful contact with your organization for services; or (b) you have seen something (eg confirmation email, receipt, call log, screenshot) that genuinely gives you the notion that the participant completed (or attempted) this action.'
                }
              </Text>
              {/* Button Note */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      (
                        await XanoApi.checkinVerifyPATCH(Constants, {
                          checkin_id: (props.route?.params?.checkin ?? '')?.id,
                          note: textAreaValue,
                          verified: true,
                        })
                      )?.json;
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  dimensions.width
                )}
                title={'verify'}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Caption'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Caption'].style,
                    { marginTop: 64 }
                  ),
                  dimensions.width
                )}
              >
                {
                  "Hold off on declining if they're still working on it but just not done yet."
                }
              </Text>
              {/* Button Note 2 */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      (
                        await XanoApi.checkinDenyPATCH(Constants, {
                          checkin_id: (props.route?.params?.checkin ?? '')?.id,
                        })
                      )?.json;
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button Outline'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button Outline'].style,
                    { marginTop: 16 }
                  ),
                  dimensions.width
                )}
                title={'decline'}
              />
            </View>
          )}
        </>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(CheckinDetailsScreen);
