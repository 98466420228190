import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  action_category: ['housing', 'money', 'health', 'legal', 'connect'],
  action_list: [{}],
  action_options: [
    { label: 'do first', value: 1 },
    { label: 'do next', value: 2 },
    { label: 'do while waiting', value: 3 },
  ],
  app: null,
  auth_header: null,
  case_status_list:
    '{"label":"new","value":"new"},{"label":"review","value":"review"},{"label":"meeting","value":"meeting"},{"label":"deadline","value":"deadline"},{"label":"pro bono","value":"pro bono"},{"label":"readiness","value":"readiness"},{"label":"hearing","value":"hearing"},{"label":"hiccup","value":"hiccup"},{"label":"x-no contact","value":"x-no contact"},{"label":"x-declined","value":"x-declined"},{"label":"x-fell off","value":"x-fell off"},{"label":"x-completed","value":"x-completed"},{"label":"x-win","value":"x-win"}]',
  case_type_list: [
    { label: 'checkup', value: 'checkup' },
    { label: 'child welfare', value: 'child welfare' },
    { label: 'civil', value: 'civil' },
    { label: 'debt', value: 'debt' },
    { label: 'dl restoration', value: 'dl restoration' },
    { label: 'family', value: 'family' },
    { label: 'housing', value: 'housing' },
    { label: 'property tax assessment', value: 'property tax assessment' },
    { label: 'property tax exemption', value: 'property tax exemption' },
    { label: 'security deposit', value: 'security deposit' },
    { label: 'street outreach', value: 'street outreach' },
    { label: 'traf-misd-ord', value: 'traf-misd-ord' },
    { label: 'aid application', value: 'aid application' },
  ],
  day: [
    { label: 1, value: '01' },
    { label: 2, value: '02' },
    { label: 3, value: '03' },
    { label: 4, value: '04' },
    { label: 5, value: '05' },
    { label: 6, value: '06' },
    { label: 7, value: '07' },
    { label: 8, value: '08' },
    { label: 9, value: '09' },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
  ],
  disability: [
    { label: 'Able-Bodied', value: 'able-bodied' },
    { label: 'Disabled', value: 'disabled' },
  ],
  edu: [
    {
      label: 'some high school experience',
      value: 'some high school experience',
    },
    {
      label: 'high degree or equivalent',
      value: 'a high school degree of GED',
    },
    {
      label: 'a vocational certification',
      value: 'a vocational certification',
    },
    { label: 'some collage', value: 'some college' },
    { label: 'a college degree', value: 'a college degree' },
    { label: 'a graduate degree', value: 'a graduate degree' },
  ],
  entry_adults: [
    { label: 'single', value: 1 },
    { label: 'with significant other', value: 2 },
  ],
  entry_benefits: [
    { label: 'Yes, I get public benefits', value: 'true' },
    { label: 'No, I do not get public benefits', value: 'false' },
  ],
  entry_causes: [
    { label: 'aged out of foster care', value: 'aged out of foster care' },
    { label: 'loss of benefits', value: 'loss of benefits' },
    { label: 'other causes', value: 'other causes' },
    { label: 'eviction', value: 'eviction' },
    { label: 'breakup or divorce', value: 'breakup or divorce' },
    { label: 'incarceration', value: 'incarceration' },
    { label: 'loss of income', value: 'loss of income' },
    { label: 'death of cohabitant', value: 'death of cohabitant' },
    { label: 'dispute with cohabitant', value: 'dispute with cohabitant' },
    { label: 'substance abuse', value: 'substance abuse' },
    { label: 'domestic violence', value: 'domestic violence' },
    { label: 'loss of health', value: 'loss of health' },
    { label: 'inhabitable housing', value: 'inhabitable housing' },
    { label: 'fire', value: 'fire' },
    { label: 'legal trouble', value: 'legal trouble' },
    { label: 'tax foreclosure', value: 'tax foreclosure' },
    { label: 'mortgage foreclosure', value: 'mortgage foreclosure' },
    { label: 'sale of property', value: 'sale of property' },
  ],
  entry_children: [
    { label: 0, value: '0' },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
  ],
  entry_housing: [
    { label: 'secure', value: 'secure' },
    { label: 'outside or car or vacant', value: 'outside or car or vacant' },
    { label: 'shelter', value: 'shelter' },
    { label: 'supportive housing', value: 'supportive housing' },
    { label: 'coach-surfing', value: 'coach-surfing' },
    { label: 'behind on rent', value: 'behind on rent' },
    {
      label: 'behind on mortgage or taxes',
      value: 'behind on mortgage or taxes',
    },
    { label: 'other insecure', value: 'other insecure' },
  ],
  entry_income: [
    { label: 0, value: '0' },
    { label: 50, value: '50' },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 },
    { label: 400, value: 400 },
    { label: 500, value: 500 },
    { label: 600, value: 600 },
    { label: 700, value: 700 },
    { label: 800, value: 800 },
    { label: 900, value: 900 },
    { label: 1000, value: 1000 },
    { label: 1100, value: 1100 },
    { label: 1200, value: 1200 },
    { label: 1300, value: 1300 },
    { label: 1400, value: 1400 },
    { label: 1500, value: 1500 },
    { label: 1600, value: 1600 },
    { label: 1700, value: 1700 },
    { label: 1800, value: 1800 },
    { label: 1900, value: 1900 },
    { label: 2000, value: 2000 },
    { label: 2250, value: 2250 },
    { label: 2500, value: 2500 },
    { label: 2750, value: 2750 },
    { label: 3000, value: 3000 },
    { label: 3250, value: 3250 },
    { label: 3500, value: 3500 },
    { label: 3750, value: 3750 },
    { label: 4000, value: 4000 },
    { label: 4500, value: 4500 },
    { label: 5000, value: 5000 },
    { label: 5500, value: 5500 },
    { label: 6000, value: 6000 },
    { label: 6500, value: 6500 },
    { label: 7000, value: 7000 },
    { label: 7500, value: 7500 },
    { label: 8000, value: 8000 },
    { label: 8500, value: 8500 },
    { label: 9000, value: 9000 },
    { label: 9500, value: 9500 },
    { label: 10000, value: 10000 },
  ],
  entry_saved: [
    { label: 0, value: '0' },
    { label: 50, value: '50' },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 },
    { label: 400, value: 400 },
    { label: 500, value: 500 },
    { label: 600, value: 600 },
    { label: 700, value: 700 },
    { label: 800, value: 800 },
    { label: 900, value: 900 },
    { label: 1000, value: 1000 },
    { label: 1100, value: 1100 },
    { label: 1200, value: 1200 },
    { label: 1300, value: 1300 },
    { label: 1400, value: 1400 },
    { label: 1500, value: 1500 },
    { label: 1600, value: 1600 },
    { label: 1700, value: 1700 },
    { label: 1800, value: 1800 },
    { label: 1900, value: 1900 },
    { label: 2000, value: 2000 },
    { label: 2250, value: 2250 },
    { label: 2500, value: 2500 },
    { label: 2750, value: 2750 },
    { label: 3000, value: 3000 },
    { label: 3250, value: 3250 },
    { label: 3500, value: 3500 },
    { label: 3750, value: 3750 },
    { label: 4000, value: 4000 },
    { label: 4500, value: 4500 },
    { label: 5000, value: 5000 },
    { label: 5500, value: 5500 },
    { label: 6000, value: 6000 },
    { label: 6500, value: 6500 },
    { label: 7000, value: 7000 },
    { label: 7500, value: 7500 },
    { label: 8000, value: 8000 },
    { label: 8500, value: 8500 },
    { label: 9000, value: 9000 },
    { label: 9500, value: 9500 },
    { label: 10000, value: 10000 },
  ],
  entry_source: [
    { label: 'none', value: 'none' },
    { label: 'disability', value: 'disability' },
    { label: 'cash assistance', value: 'cash assistance' },
    { label: 'part time job', value: 'part time job' },
    { label: 'full time job', value: 'full time job' },
    { label: "worker's comp", value: "worker's comp" },
    { label: 'unemployment', value: 'unemployment' },
    { label: 'self-employed', value: 'self-employed' },
    { label: 'other', value: 'other' },
  ],
  flag: [
    'participant',
    'community',
    'defender',
    'prosecutor',
    'court',
    'special',
  ],
  gender: [
    { label: 'Man', value: 'man' },
    { label: 'Woman', value: 'woman' },
    { label: 'Person', value: 'person' },
  ],
  jurisdiction_list: [
    { label: '10th Circuit Court - Saginaw', value: 135 },
    { label: '10th District Court - Calhoun County', value: 31 },
    {
      label: '11th Circuit Court - Luce, Mackinac, Schoolcraft, and Alger',
      value: 136,
    },
    { label: '12th Circuit Court - Houghton, Baraga and Keweenaw', value: 137 },
    { label: '12th District Court - Jackson County', value: 32 },
    {
      label: '13th Circuit Court - Leelanau, Antrim and Grand Traverse',
      value: 138,
    },
    { label: '14A District Court - Washtenaw County', value: 33 },
    { label: '14B District Court - Ypsilanti Twp', value: 34 },
    { label: '14th Circuit Court - Muskegon', value: 139 },
    { label: '15th Circuit Court - Branch', value: 140 },
    { label: '15th District Court - Ann Arbor', value: 35 },
    { label: '16th Circuit Court - Macomb', value: 141 },
    { label: '16th District Court - Livonia', value: 36 },
    { label: '17th Circuit Court - Kent', value: 142 },
    { label: '17th District Court - Redford Township', value: 37 },
    { label: '18th Circuit Court - Bay', value: 143 },
    { label: '18th District Court - Westland', value: 38 },
    { label: '19th Circuit Court - Benzie, Manistee', value: 144 },
    { label: '19th District Court - Dearborn', value: 39 },
    { label: '1st Circuit Court - Hillsdale', value: 128 },
    { label: '1st District Court - Monroe County', value: 22 },
    { label: '20th Circuit Court - Ottawa', value: 145 },
    { label: '20th District Court - Dearborn Heights', value: 40 },
    { label: '21st Circuit Court - Isabella', value: 146 },
    { label: '21st District Court - Garden City', value: 41 },
    { label: '22nd Circuit Court - Washtenaw', value: 147 },
    { label: '22nd District Court - Inkster', value: 42 },
    { label: '23rd Circuit Court - Iosco, Arenac, Alcona, Oscoda', value: 148 },
    { label: '23rd District Court - Taylor', value: 43 },
    { label: '24th Circuit Court - Sanilac', value: 149 },
    { label: '24th District Court - Allen Park and Melvindale', value: 44 },
    { label: '25th Circuit Court - Marquette', value: 150 },
    { label: '25th District Court - Ecorse and Lincoln Park', value: 45 },
    { label: '26th Circuit Court - Alpena, Montmorency', value: 151 },
    { label: '27th Circuit Court - Oceana, Newaygo', value: 152 },
    { label: '27th District Court - Wyandotte', value: 46 },
    { label: '28th Circuit Court - Wexford, Missaukee', value: 153 },
    { label: '28th District Court - Southgate', value: 47 },
    { label: '29th Circuit Court - Gratiot, Clinton', value: 154 },
    { label: '29th District Court - Wayne City', value: 48 },
    { label: '2A District Court - Lenawee County', value: 23 },
    { label: '2B District Court - Hillsdale County', value: 24 },
    { label: '30th Circuit Court - Ingham', value: 155 },
    { label: '30th District Court - Highland Park', value: 49 },
    { label: '31st Circuit Court - St. Clair', value: 156 },
    { label: '31st District Court - Hamtramck', value: 50 },
    { label: '32A District Court - Harper Woods', value: 51 },
    { label: '32nd Circuit Court - Ontonagon, Gogebic', value: 157 },
    { label: '33rd Circuit Court - Charlevoix', value: 158 },
    { label: '33rd District Court - Woodhaven', value: 52 },
    { label: '34th Circuit Court - Ogemaw, Roscommon', value: 159 },
    { label: '34th District Court - Romulus', value: 53 },
    { label: '35th Circuit Court - Shiawassee', value: 160 },
    { label: '35th District Court - Plymouth', value: 54 },
    { label: '36th Circuit Court - Van Buren', value: 161 },
    { label: '36th District Court - Detroit', value: 18 },
    { label: '37th Circuit Court - Calhoun', value: 162 },
    { label: '37th District Court - Warren and Centerline', value: 55 },
    { label: '38th Circuit Court - Monroe', value: 163 },
    { label: '38th District Court - Eastpointe', value: 56 },
    { label: '39th Circuit Court - Lenawee', value: 164 },
    { label: '39th District Court - Roseville and Fraser', value: 21 },
    { label: '3A District Court - Branch County', value: 25 },
    { label: '3B District Court - St. Joseph County', value: 26 },
    { label: '3rd Circuit Court - Wayne County', value: 127 },
    { label: '40th Circuit Court - Lapeer', value: 165 },
    { label: '40th District Court - St. Clair Shores', value: 57 },
    {
      label: '41 A District Court - Sterling Heights and Shelby Twp',
      value: 58,
    },
    { label: '41 B District Court - Clinton Twp and Mt. Clemens', value: 59 },
    { label: '41st Circuit Court - Iron, Dickinson, Menominee', value: 166 },
    { label: '42nd Circuit Court - Midland', value: 167 },
    { label: '42nd District Court - Romeo and New Baltimore', value: 60 },
    { label: '43rd Circuit Court - Cass', value: 168 },
    {
      label: '43rd District Court - Ferndale, Hazel Park, and Madison Heights',
      value: 61,
    },
    { label: '44th Circuit Court - Livingston', value: 169 },
    { label: '44th District Court - Royal Oak', value: 62 },
    { label: '45A District Court - Berkley', value: 63 },
    { label: '45B District Court - Oak Park', value: 64 },
    { label: '45th Circuit Court - St. Joseph', value: 170 },
    { label: '46th Circuit Court - Otsego, Crawford, Kalkaska', value: 171 },
    { label: '46th District Court - Southfield', value: 65 },
    { label: '47th Circuit Court - Delta', value: 172 },
    {
      label: '47th District Court - Farmington and Farmington Hills',
      value: 66,
    },
    { label: '48th Circuit Court - Allegan', value: 173 },
    { label: '48th District Court - Bloomfield Hills', value: 67 },
    { label: '49th Circuit Court - Osceola, Mecosta', value: 174 },
    { label: '4th Circuit Court - Jackson', value: 129 },
    { label: '4th District Court - Cass County', value: 27 },
    { label: '50th Circuit Court - Chippewa', value: 175 },
    { label: '50th District Court - Pontiac', value: 68 },
    { label: '51st Circuit Court - Mason, Lake', value: 176 },
    { label: '51st District Court - Waterford Township', value: 69 },
    { label: '52-1 District Court - Novi', value: 70 },
    { label: '52-2 District Court - Clarkston', value: 71 },
    { label: '52-3 District Court - Rochester Hills', value: 72 },
    { label: '52-4 District Court - Troy', value: 73 },
    { label: '52nd Circuit Court - Huron', value: 177 },
    { label: '53rd Circuit Court - Cheboygan, Presque Isle', value: 178 },
    { label: '53rd District Court - Howell and Brighton', value: 74 },
    { label: '54A District Court - Lansing', value: 75 },
    { label: '54B District Court - East Lansing', value: 76 },
    { label: '54th Circuit Court - Tuscola', value: 179 },
    { label: '55th Circuit Court - Clare, Gladwin', value: 180 },
    { label: '55th District Court - Mason', value: 77 },
    { label: '56A District Court - Eaton County', value: 78 },
    { label: '56B District Court - Barry County', value: 79 },
    { label: '56th Circuit Court - Eaton', value: 181 },
    { label: '57th Circuit Court - Emmet', value: 182 },
    { label: '57th District Court - Allegan County', value: 80 },
    { label: '58th District Court - Ottawa County', value: 81 },
    { label: '59th District Court - Grandville and Walker', value: 82 },
    { label: '5th Circuit Court - Barry', value: 130 },
    { label: '60th District Court - Muskegon', value: 83 },
    { label: '61st District Court - Grand Rapids', value: 84 },
    { label: '62A District Court - Wyoming', value: 85 },
    { label: '62B District Court - Kentwood', value: 86 },
    { label: '63rd District Court - Rockford and Grand Rapids', value: 87 },
    { label: '64A District Court - Ionia County', value: 88 },
    { label: '64B District Court - Montcalm County', value: 89 },
    { label: '65A District Court - Clinton County', value: 90 },
    { label: '65B District Court - Gratiot County', value: 91 },
    { label: '66th District Court - Shiawassee County', value: 92 },
    { label: '67th District Court - Genesee County', value: 93 },
    { label: '6th Circuit Court - Oakland', value: 131 },
    { label: '70th District Court - Saginaw County', value: 94 },
    { label: '71A District Court - Lapeer County', value: 95 },
    { label: '71B District Court - Tuscola County', value: 96 },
    { label: '72nd District Court - St. Clair County', value: 97 },
    { label: '73A District Court - Sanilac County', value: 98 },
    { label: '73B District Court - Huron County', value: 99 },
    { label: '74th District Court - Bay County', value: 100 },
    { label: '75th District Court - Midland County', value: 101 },
    { label: '76th District Court - Isabella County', value: 102 },
    { label: '77th District Court - Osceola and Mecosta Counties', value: 103 },
    { label: '78th District Court - Oceana and Newaygo Counties', value: 104 },
    { label: '79th District Court - Lake and Mason Counties', value: 105 },
    { label: '7th Circuit Court - Genesee', value: 132 },
    { label: '7th District Court - Van Buren County', value: 29 },
    { label: '80th District Court - Gladwin and Clare Counties', value: 106 },
    {
      label: '81st District Court - Alcona, Arenac, Iosco, Oscoda Counties',
      value: 107,
    },
    { label: '82nd District Court - Ogemaw, Roscommon Counties', value: 108 },
    {
      label: '84th District Court - Missaukee and Wexford Counties',
      value: 109,
    },
    { label: '85th District Court - Benzie and Manistee Counties', value: 110 },
    {
      label:
        '86th District Court - Antrim, Grand Traverse, and Leelanau Counties',
      value: 111,
    },
    { label: '87A District Court - Otsego County Counties', value: 112 },
    { label: '87B District Court - Kalkaska County Counties', value: 113 },
    { label: '87C District Court - Crawford County Counties', value: 114 },
    {
      label: '88th District Court - Alpena and Montmorency Counties',
      value: 115,
    },
    {
      label: '89th District Court - Cheboygan and Presque Isle Counties',
      value: 116,
    },
    { label: '8th Circuit Court - Montcalm and Ionia', value: 133 },
    { label: '8th District Court - Kalamazoo County', value: 30 },
    {
      label: '90th District Court - Emmet and Charlevoix Counties',
      value: 117,
    },
    { label: '91st District Court - Chippewa County Counties', value: 118 },
    { label: '92nd District Court - Luce and Mackinac Counties', value: 119 },
    {
      label: '93rd District Court - Alger and Schoolcraft Counties',
      value: 120,
    },
    { label: '94th District Court - Delta County Counties', value: 121 },
    { label: '95A District Court - Menominee County Counties', value: 122 },
    { label: '95B District Court - Iron and Dickinson Counties', value: 123 },
    { label: '96th District Court - Marquette County Counties', value: 124 },
    {
      label: '97th District Court - Baraga, Houghton and Keweenaw Counties',
      value: 125,
    },
    {
      label: '98th District Court - Ontonagon and Gogebic Counties',
      value: 126,
    },
    { label: '9th Circuit Court - Kalamazoo', value: 134 },
    { label: 'Berrien County Trial Court - Berrien County', value: 28 },
    { label: 'Court of Appeals - ', value: 186 },
    { label: 'Mental Health Court - Wayne - ', value: 9 },
    { label: 'Michigan Tax Tribunal - statewide', value: 183 },
  ],
  month: [
    { label: '1', value: '01' },
    { label: '2', value: '02' },
    { label: '3', value: '03' },
    { label: '4', value: '04' },
    { label: '5', value: '05' },
    { label: '6', value: '06' },
    { label: '7', value: '07' },
    { label: '8', value: '08' },
    { label: '9', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ],
  race: [
    { label: 'Asian', value: 'Asian' },
    { label: 'Black', value: 'Black' },
    { label: 'American Indian and Native Alaskan (AI/AN)', value: 'AIAN' },
    { label: 'Hawaiian or Pacific Islander', value: 'NHPI' },
    { label: 'Latin or Hispanic', value: 'Latin' },
    { label: 'other', value: 'other' },
    { label: 'prefer not to say', value: 'unknown' },
  ],
  role: [
    { label: 'participant', value: 'participant' },
    { label: 'provider', value: 'provider' },
    { label: 'defender', value: 'defender' },
    { label: 'prosecutor', value: 'prosecutor' },
    { label: 'judge', value: 'judge' },
    { label: 'court', value: 'court' },
  ],
  sampleFile: null,
  savings: [
    { label: 0, value: '0' },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 30, value: 30 },
    { label: 40, value: 40 },
    { label: 50, value: 50 },
    { label: 60, value: 60 },
    { label: 70, value: 70 },
    { label: 80, value: 80 },
    { label: 90, value: 90 },
    { label: 100, value: 100 },
    { label: 110, value: 110 },
    { label: 120, value: 120 },
    { label: 130, value: 130 },
    { label: 140, value: 140 },
    { label: 150, value: 150 },
    { label: 160, value: 160 },
    { label: 170, value: 170 },
    { label: 180, value: 180 },
    { label: 190, value: 190 },
    { label: 200, value: 200 },
    { label: 225, value: 225 },
    { label: 250, value: 250 },
    { label: 275, value: 275 },
    { label: 300, value: 300 },
    { label: 325, value: 325 },
    { label: 350, value: 350 },
    { label: 375, value: 375 },
    { label: 400, value: 400 },
    { label: 450, value: 450 },
    { label: 500, value: 500 },
    { label: 550, value: 550 },
    { label: 600, value: 600 },
    { label: 650, value: 650 },
    { label: 700, value: 700 },
    { label: 750, value: 750 },
    { label: 800, value: 800 },
    { label: 850, value: 850 },
    { label: 900, value: 900 },
    { label: 950, value: 950 },
    { label: 1000, value: 1000 },
  ],
  state: [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District Of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'Newfoundland', value: 'NF' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
  ],
  user_status_list: [
    { label: 'active', value: 'active' },
    { label: 'inactive', value: 'inactive' },
    { label: 'locked', value: 'locked' },
  ],
  vet: [
    { label: 'Non-Veteran', value: 'nonveteran' },
    { label: 'Veteran', value: 'veteran' },
    { label: 'Active Military', value: 'active' },
    { label: 'Reservist', value: 'reservist' },
  ],
  year: [
    { label: 2024, value: 2024 },
    { label: 2023, value: 2023 },
    { label: 2022, value: 2022 },
    { label: 2021, value: 2021 },
    { label: 2020, value: 2020 },
    { label: 2019, value: 2019 },
    { label: 2018, value: 2018 },
    { label: 2017, value: 2017 },
    { label: 2016, value: 2016 },
    { label: 2015, value: 2015 },
    { label: 2014, value: 2014 },
    { label: 2013, value: 2013 },
    { label: 2012, value: 2012 },
    { label: 2011, value: 2011 },
    { label: 2010, value: 2010 },
    { label: 2009, value: 2009 },
    { label: 2008, value: 2008 },
    { label: 2007, value: 2007 },
    { label: 2006, value: 2006 },
    { label: 2005, value: 2005 },
    { label: 2004, value: 2004 },
    { label: 2003, value: 2003 },
    { label: 2002, value: 2002 },
    { label: 2001, value: 2001 },
    { label: 2000, value: 2000 },
    { label: 1999, value: 1999 },
    { label: 1998, value: 1998 },
    { label: 1997, value: 1997 },
    { label: 1996, value: 1996 },
    { label: 1995, value: 1995 },
    { label: 1994, value: 1994 },
    { label: 1993, value: 1993 },
    { label: 1992, value: 1992 },
    { label: 1991, value: 1991 },
    { label: 1990, value: 1990 },
    { label: 1989, value: 1989 },
    { label: 1988, value: 1988 },
    { label: 1987, value: 1987 },
    { label: 1986, value: 1986 },
    { label: 1985, value: 1985 },
    { label: 1984, value: 1984 },
    { label: 1983, value: 1983 },
    { label: 1982, value: 1982 },
    { label: 1981, value: 1981 },
    { label: 1980, value: 1980 },
    { label: 1979, value: 1979 },
    { label: 1978, value: 1978 },
    { label: 1977, value: 1977 },
    { label: 1976, value: 1976 },
    { label: 1975, value: 1975 },
    { label: 1974, value: 1974 },
    { label: 1973, value: 1973 },
    { label: 1972, value: 1972 },
    { label: 1971, value: 1971 },
    { label: 1970, value: 1970 },
    { label: 1969, value: 1969 },
    { label: 1968, value: 1968 },
    { label: 1967, value: 1967 },
    { label: 1966, value: 1966 },
    { label: 1965, value: 1965 },
    { label: 1964, value: 1964 },
    { label: 1963, value: 1963 },
    { label: 1962, value: 1962 },
    { label: 1961, value: 1961 },
    { label: 1960, value: 1960 },
    { label: 1959, value: 1959 },
    { label: 1958, value: 1958 },
    { label: 1957, value: 1957 },
    { label: 1956, value: 1956 },
    { label: 1955, value: 1955 },
    { label: 1954, value: 1954 },
    { label: 1953, value: 1953 },
    { label: 1952, value: 1952 },
    { label: 1951, value: 1951 },
    { label: 1950, value: 1950 },
    { label: 1949, value: 1949 },
    { label: 1948, value: 1948 },
    { label: 1947, value: 1947 },
    { label: 1946, value: 1946 },
    { label: 1945, value: 1945 },
    { label: 1944, value: 1944 },
    { label: 1943, value: 1943 },
    { label: 1942, value: 1942 },
    { label: 1941, value: 1941 },
    { label: 1940, value: 1940 },
    { label: 1939, value: 1939 },
    { label: 1938, value: 1938 },
    { label: 1937, value: 1937 },
    { label: 1936, value: 1936 },
    { label: 1935, value: 1935 },
    { label: 1934, value: 1934 },
    { label: 1933, value: 1933 },
    { label: 1932, value: 1932 },
    { label: 1931, value: 1931 },
    { label: 1930, value: 1930 },
    { label: 1929, value: 1929 },
    { label: 1928, value: 1928 },
    { label: 1927, value: 1927 },
    { label: 1926, value: 1926 },
    { label: 1925, value: 1925 },
    { label: 1924, value: 1924 },
    { label: 1923, value: 1923 },
    { label: 1922, value: 1922 },
    { label: 1921, value: 1921 },
    { label: 1920, value: 1920 },
    { label: 1919, value: 1919 },
    { label: 1918, value: 1918 },
    { label: 1917, value: 1917 },
    { label: 1916, value: 1916 },
    { label: 1915, value: 1915 },
    { label: 1914, value: 1914 },
    { label: 1913, value: 1913 },
    { label: 1912, value: 1912 },
    { label: 1911, value: 1911 },
    { label: 1910, value: 1910 },
    { label: 1909, value: 1909 },
    { label: 1908, value: 1908 },
    { label: 1907, value: 1907 },
    { label: 1906, value: 1906 },
    { label: 1905, value: 1905 },
    { label: 1904, value: 1904 },
    { label: 1903, value: 1903 },
    { label: 1902, value: 1902 },
    { label: 1901, value: 1901 },
  ],
  __env__: 'Production',
};
export const AppVariables = {
  error_code: '',
  error_message: '',
  initial_load: true,
  is_loading: true,
  org_action_list: '',
  organization_list: '',
  participant_item: '',
  participant_report: '',
  person_list: '',
  quote: '',
  selected_case: '',
  selected_user: '',
  selected_user_details: '',
  user_action_plan: '',
  user_details: '',
  user_email: '',
  user_location: '',
  works_for: '',
  works_for_programs: '',
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
