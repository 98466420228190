import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CaseNoteAddScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [allNotes, setAllNotes] = React.useState(false);
  const [caseNotes, setCaseNotes] = React.useState('');
  const [fileUpload, setFileUpload] = React.useState('');
  const [filename, setFilename] = React.useState('');
  const [showAddForm, setShowAddForm] = React.useState(false);
  const [textAreaValue2, setTextAreaValue2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Log to Console' action */
      undefined;
      undefined;
      undefined;
      undefined;
      undefined;
      undefined;
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'flex-start' },
            dimensions.width
          )}
        >
          {/* title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Big Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Big Header'].style,
              dimensions.width
            )}
          >
            {'\nnotes'}
          </Text>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
              dimensions.width
            )}
          >
            {props.route?.params?.name_first ?? ''}{' '}
            {props.route?.params?.name_last ?? ''}
            {'\n#'}
            {props.route?.params?.case_id ?? ''}
            {' - '}
            {props.route?.params?.case_type ?? ''}
            {'\n'}
            {props.route?.params?.jurisdiction ?? ''}
          </Text>
        </View>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 32,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={false}
      >
        <View>
          {/* add */}
          <View
            {...GlobalStyles.ViewStyles(theme)['List View'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['List View'].style,
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Header - Page'].style,
                dimensions.width
              )}
            >
              {'add a new note'}
            </Text>
            <TextInput
              changeTextDelay={500}
              multiline={true}
              onChangeText={newTextAreaValue => {
                const textInputValue = newTextAreaValue;
                try {
                  setTextAreaValue2(newTextAreaValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoComplete={'off'}
              autoFocus={false}
              numberOfLines={10}
              placeholder={'add your new note here...'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={textAreaValue2}
            />
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    const fileUpload = await selectFileUtil({
                      returnNameAndValue: true,
                    });
                    setFileUpload(fileUpload?.value);
                    setFilename(fileUpload?.name);
                    console.log(props.route?.params?.selected_case ?? '');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ marginTop: 16 }, dimensions.width)}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  name={'Feather/upload'}
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: 4,
                      marginLeft: 4,
                      marginRight: 4,
                      marginTop: 4,
                    },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'upload'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors['Primary'],
                        fontSize: 12,
                        marginLeft: 16,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {filename}
                </Text>
              </View>
            </Touchable>
            <TextInput
              autoCapitalize={'none'}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                const textInputValue = newTextInputValue;
                try {
                  setTextInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              placeholder={'document title, eg 123 Apt Lease'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={textInputValue}
            />
            {/* Button File */}
            <>
              {!filename ? null : (
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await XanoApi.caseFilePOST(Constants, {
                          case_id: props.route?.params?.case_id ?? '',
                          file: fileUpload,
                          filename: textInputValue,
                          note: textAreaValue2,
                        });
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Refetch Data' action */
                        /* hidden 'Set Variable' action */
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { marginTop: 32 }
                    ),
                    dimensions.width
                  )}
                  title={'submit'}
                />
              )}
            </>
            {/* Button Note */}
            <>
              {filename ? null : (
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const resultNote = (
                          await XanoApi.caseNotePOST(Constants, {
                            case_id: props.route?.params?.case_id ?? '',
                            note: textAreaValue2,
                          })
                        )?.json;
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Set Variable' action */
                        /* hidden 'Refetch Data' action */
                        /* hidden 'Set Variable' action */
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { marginTop: 32 }
                    ),
                    dimensions.width
                  )}
                  title={'submit'}
                />
              )}
            </>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(CaseNoteAddScreen);
