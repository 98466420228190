import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import { parseBoolean } from './utils';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import ActionDetailsPublicScreen from './screens/ActionDetailsPublicScreen';
import ActionDetailsScreen from './screens/ActionDetailsScreen';
import ActionListPublicScreen from './screens/ActionListPublicScreen';
import ActionListScreen from './screens/ActionListScreen';
import BlankScreen from './screens/BlankScreen';
import CaseDetailsScreen from './screens/CaseDetailsScreen';
import CaseEditScreen from './screens/CaseEditScreen';
import CaseList2Screen from './screens/CaseList2Screen';
import CaseListArchiveScreen from './screens/CaseListArchiveScreen';
import CaseListCleanupScreen from './screens/CaseListCleanupScreen';
import CaseListOriginalScreen from './screens/CaseListOriginalScreen';
import CaseListProgramScreen from './screens/CaseListProgramScreen';
import CaseListScreen from './screens/CaseListScreen';
import CaseNewScreen from './screens/CaseNewScreen';
import CaseNoteAddScreen from './screens/CaseNoteAddScreen';
import CaseNotesScreen from './screens/CaseNotesScreen';
import CaseOrderScreen from './screens/CaseOrderScreen';
import CheckinDetailsScreen from './screens/CheckinDetailsScreen';
import CheckinEditScreen from './screens/CheckinEditScreen';
import CheckinListScreen from './screens/CheckinListScreen';
import FAQDetailScreen from './screens/FAQDetailScreen';
import FAQListScreen from './screens/FAQListScreen';
import FSLoginScreen from './screens/FSLoginScreen';
import FSScreen from './screens/FSScreen';
import FileViewScreen from './screens/FileViewScreen';
import IntakeConnectScreen from './screens/IntakeConnectScreen';
import IntakeHealthScreen from './screens/IntakeHealthScreen';
import IntakeHousingScreen from './screens/IntakeHousingScreen';
import IntakeInfoScreen from './screens/IntakeInfoScreen';
import IntakeLegalScreen from './screens/IntakeLegalScreen';
import IntakeMoneyScreen from './screens/IntakeMoneyScreen';
import IntakeVisionScreen from './screens/IntakeVisionScreen';
import OpportunityReportScreen from './screens/OpportunityReportScreen';
import OrgDetailsScreen from './screens/OrgDetailsScreen';
import OrgList2Screen from './screens/OrgList2Screen';
import ProgramDetailScreen from './screens/ProgramDetailScreen';
import ProgramListScreen from './screens/ProgramListScreen';
import ResourceDetailsScreen from './screens/ResourceDetailsScreen';
import StatsProgramScreen from './screens/StatsProgramScreen';
import StatsReportProviderScreen from './screens/StatsReportProviderScreen';
import StatsReportScreen from './screens/StatsReportScreen';
import StatsYearScreen from './screens/StatsYearScreen';
import TermsOfServiceScreen from './screens/TermsOfServiceScreen';
import UserActionPlanScreen from './screens/UserActionPlanScreen';
import UserCaseScreen from './screens/UserCaseScreen';
import UserDetailScreen from './screens/UserDetailScreen';
import UserEdit2Screen from './screens/UserEdit2Screen';
import UserEditScreen from './screens/UserEditScreen';
import UserNewScreen from './screens/UserNewScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import UserSearchScreen from './screens/UserSearchScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="FSScreen"
        presentation="modal"
        screenOptions={({ navigation }) => ({
          animationEnabled: true,
          cardOverlayEnabled: false,
          gestureDirection: 'horizontal',
          gestureEnabled: true,
          headerLeft: ({ tintColor, canGoBack }) =>
            canGoBack ? (
              <View
                style={[styles.headerContainer, styles.headerContainerLeft]}
              >
                <Icon
                  name=""
                  size={Platform.OS === 'ios' ? 21 : 24}
                  color={theme.colors['Light']}
                  style={[styles.headerIcon, styles.headerIconLeft]}
                />
              </View>
            ) : null,
          headerRight: ({ tintColor }) => (
            <View style={[styles.headerContainer, styles.headerContainerRight]}>
              <Icon
                name=""
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['Light']}
                style={[styles.headerIcon, styles.headerIconRight]}
              />
            </View>
          ),
          headerShown: false,
          headerTitleStyle: { fontFamily: 'Assistant_400Regular' },
        })}
      >
        <Stack.Screen
          name="CaseEditScreen"
          component={CaseEditScreen}
          options={({ navigation }) => ({
            title: 'Case - Edit',
          })}
        />
        <Stack.Screen
          name="FSLoginScreen"
          component={FSLoginScreen}
          options={({ navigation }) => ({
            title: 'FS - Login',
          })}
        />
        <Stack.Screen
          name="CaseNotesScreen"
          component={CaseNotesScreen}
          options={({ navigation }) => ({
            title: 'Case - Notes',
          })}
        />
        <Stack.Screen
          name="UserEditScreen"
          component={UserEditScreen}
          options={({ navigation }) => ({
            title: 'User - Edit',
          })}
        />
        <Stack.Screen
          name="UserSearchScreen"
          component={UserSearchScreen}
          options={({ navigation }) => ({
            title: 'User - Search',
          })}
        />
        <Stack.Screen
          name="ActionListScreen"
          component={ActionListScreen}
          options={({ navigation }) => ({
            title: 'Action - List',
          })}
        />
        <Stack.Screen
          name="FileViewScreen"
          component={FileViewScreen}
          options={({ navigation }) => ({
            title: 'File - View',
          })}
        />
        <Stack.Screen
          name="IntakeInfoScreen"
          component={IntakeInfoScreen}
          options={({ navigation }) => ({
            title: 'Intake - Info',
          })}
        />
        <Stack.Screen
          name="ProgramListScreen"
          component={ProgramListScreen}
          options={({ navigation }) => ({
            title: 'Program - List',
          })}
        />
        <Stack.Screen
          name="IntakeHousingScreen"
          component={IntakeHousingScreen}
          options={({ navigation }) => ({
            title: 'Intake - Housing',
          })}
        />
        <Stack.Screen
          name="CaseDetailsScreen"
          component={CaseDetailsScreen}
          options={({ navigation }) => ({
            title: 'Case - Details',
          })}
        />
        <Stack.Screen
          name="UserProfileScreen"
          component={UserProfileScreen}
          options={({ navigation }) => ({
            title: 'User - Profile',
          })}
        />
        <Stack.Screen
          name="OrgDetailsScreen"
          component={OrgDetailsScreen}
          options={({ navigation }) => ({
            title: 'Org - Details',
          })}
        />
        <Stack.Screen
          name="OpportunityReportScreen"
          component={OpportunityReportScreen}
          options={({ navigation }) => ({
            title: 'Opportunity Report ',
          })}
        />
        <Stack.Screen
          name="FSScreen"
          component={FSScreen}
          options={({ navigation }) => ({
            title: 'FS',
          })}
        />
        <Stack.Screen
          name="ActionDetailsScreen"
          component={ActionDetailsScreen}
          options={({ navigation }) => ({
            title: 'Action - Details',
          })}
        />
        <Stack.Screen
          name="CheckinListScreen"
          component={CheckinListScreen}
          options={({ navigation }) => ({
            title: 'Checkin - List',
          })}
        />
        <Stack.Screen
          name="ProgramDetailScreen"
          component={ProgramDetailScreen}
          options={({ navigation }) => ({
            title: 'Program - Detail',
          })}
        />
        <Stack.Screen
          name="CheckinDetailsScreen"
          component={CheckinDetailsScreen}
          options={({ navigation }) => ({
            title: 'Checkin - Details',
          })}
        />
        <Stack.Screen
          name="CaseNewScreen"
          component={CaseNewScreen}
          options={({ navigation }) => ({
            title: 'Case - New',
          })}
        />
        <Stack.Screen
          name="UserNewScreen"
          component={UserNewScreen}
          options={({ navigation }) => ({
            title: 'User - New',
          })}
        />
        <Stack.Screen
          name="CaseListScreen"
          component={CaseListScreen}
          options={({ navigation }) => ({
            title: 'Case - List',
          })}
        />
        <Stack.Screen
          name="ResourceDetailsScreen"
          component={ResourceDetailsScreen}
          options={({ navigation }) => ({
            title: 'Resource - Details',
          })}
        />
        <Stack.Screen
          name="OrgList2Screen"
          component={OrgList2Screen}
          options={({ navigation }) => ({
            title: 'Org - List - 2',
          })}
        />
        <Stack.Screen
          name="FAQListScreen"
          component={FAQListScreen}
          options={({ navigation }) => ({
            title: 'FAQ - List',
          })}
        />
        <Stack.Screen
          name="IntakeMoneyScreen"
          component={IntakeMoneyScreen}
          options={({ navigation }) => ({
            title: 'Intake - Money',
          })}
        />
        <Stack.Screen
          name="IntakeHealthScreen"
          component={IntakeHealthScreen}
          options={({ navigation }) => ({
            title: 'Intake - Health',
          })}
        />
        <Stack.Screen
          name="IntakeLegalScreen"
          component={IntakeLegalScreen}
          options={({ navigation }) => ({
            title: 'Intake - Legal',
          })}
        />
        <Stack.Screen
          name="IntakeConnectScreen"
          component={IntakeConnectScreen}
          options={({ navigation }) => ({
            title: 'Intake - Connect',
          })}
        />
        <Stack.Screen
          name="TermsOfServiceScreen"
          component={TermsOfServiceScreen}
          options={({ navigation }) => ({
            title: 'Terms of Service',
          })}
        />
        <Stack.Screen
          name="CheckinEditScreen"
          component={CheckinEditScreen}
          options={({ navigation }) => ({
            title: 'Checkin - Edit',
          })}
        />
        <Stack.Screen
          name="CaseListCleanupScreen"
          component={CaseListCleanupScreen}
          options={({ navigation }) => ({
            title: 'Case - List - Cleanup',
          })}
        />
        <Stack.Screen
          name="FAQDetailScreen"
          component={FAQDetailScreen}
          options={({ navigation }) => ({
            title: 'FAQ - Detail',
          })}
        />
        <Stack.Screen
          name="CaseNoteAddScreen"
          component={CaseNoteAddScreen}
          options={({ navigation }) => ({
            title: 'Case - Note - Add',
          })}
        />
        <Stack.Screen
          name="UserDetailScreen"
          component={UserDetailScreen}
          options={({ navigation }) => ({
            title: 'User - Detail',
          })}
        />
        <Stack.Screen
          name="UserEdit2Screen"
          component={UserEdit2Screen}
          options={({ navigation }) => ({
            title: 'User - Edit 2',
          })}
        />
        <Stack.Screen
          name="UserActionPlanScreen"
          component={UserActionPlanScreen}
          options={({ navigation }) => ({
            title: 'User - Action Plan',
          })}
        />
        <Stack.Screen
          name="UserCaseScreen"
          component={UserCaseScreen}
          options={({ navigation }) => ({
            title: 'User - Case',
          })}
        />
        <Stack.Screen
          name="IntakeVisionScreen"
          component={IntakeVisionScreen}
          options={({ navigation }) => ({
            title: 'Intake - Vision',
          })}
        />
        <Stack.Screen
          name="CaseListOriginalScreen"
          component={CaseListOriginalScreen}
          options={({ navigation }) => ({
            title: 'Case - List - Original',
          })}
        />
        <Stack.Screen
          name="CaseList2Screen"
          component={CaseList2Screen}
          options={({ navigation }) => ({
            title: 'Case - List 2',
          })}
        />
        <Stack.Screen
          name="CaseListProgramScreen"
          component={CaseListProgramScreen}
          options={({ navigation }) => ({
            title: 'Case - List - Program',
          })}
        />
        <Stack.Screen
          name="BlankScreen"
          component={BlankScreen}
          options={({ navigation }) => ({
            title: 'Blank',
          })}
        />
        <Stack.Screen
          name="CaseOrderScreen"
          component={CaseOrderScreen}
          options={({ navigation }) => ({
            title: 'Case - Order',
          })}
        />
        <Stack.Screen
          name="StatsProgramScreen"
          component={StatsProgramScreen}
          options={({ navigation }) => ({
            title: 'Stats - Program',
          })}
        />
        <Stack.Screen
          name="StatsYearScreen"
          component={StatsYearScreen}
          options={({ navigation }) => ({
            title: 'Stats - Year',
          })}
        />
        <Stack.Screen
          name="StatsReportScreen"
          component={StatsReportScreen}
          options={({ navigation }) => ({
            title: 'Stats - Report',
          })}
        />
        <Stack.Screen
          name="StatsReportProviderScreen"
          component={StatsReportProviderScreen}
          options={({ navigation }) => ({
            title: 'Stats - Report - Provider',
          })}
        />
        <Stack.Screen
          name="ActionListPublicScreen"
          component={ActionListPublicScreen}
          options={({ navigation }) => ({
            title: 'Action - List - Public',
          })}
        />
        <Stack.Screen
          name="ActionDetailsPublicScreen"
          component={ActionDetailsPublicScreen}
          options={({ navigation }) => ({
            title: 'Action - Details - Public',
          })}
        />
        <Stack.Screen
          name="CaseListArchiveScreen"
          component={CaseListArchiveScreen}
          options={({ navigation }) => ({
            title: 'Case - List - Archive',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
});
