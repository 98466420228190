import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Link,
  PinInput,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const FSLoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailInput, setEmailInput] = React.useState('');
  const [errorScreen, setErrorScreen] = React.useState('');
  const [legal, setLegal] = React.useState(false);
  const [pin, setPin] = React.useState(false);
  const [pinInput, setPinInput] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      undefined;
      setGlobalVariableValue({
        key: 'error_message',
        value: '',
      });
      undefined;
      undefined;
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const pinInput7Sjuo40LRef = React.useRef();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <XanoApi.FetchAppGET>
        {({ loading, error, data, refetchApp }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingTop: 32 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={Images.OWColor1}
                  style={StyleSheet.applyWidth(
                    { height: 100, width: 100 },
                    dimensions.width
                  )}
                />
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Big Header'].style,
                      { color: theme.colors['Primary'], textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {fetchData?.app?.name}
                </Text>
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
                      { paddingLeft: 32, paddingRight: 32, textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {fetchData?.app?.tag}
                </Text>

                <KeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableOnAndroid={false}
                  enableResetScrollToCoords={false}
                  viewIsInsideTabBar={false}
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      marginLeft: 32,
                      marginRight: 32,
                      marginTop: 32,
                    },
                    dimensions.width
                  )}
                  keyboardShouldPersistTaps={'always'}
                  showsVerticalScrollIndicator={false}
                >
                  {/* email */}
                  <>
                    {pin ? null : (
                      <View>
                        <View>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                marginBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Header - Page'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'Header - Page'
                                  ].style,
                                  { textAlign: 'center' }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.signup_title}
                            </Text>
                          </View>
                          {/* list */}
                          <FlatList
                            data={fetchData?.app?.sign_up}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            keyboardShouldPersistTaps={'never'}
                            listKey={'KQlKy1Bb'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <>
                                  {/* touchable */}
                                  <>
                                    {!(listData?.legal === legal) ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'CaseDetailsScreen',
                                              { case_id: listData?.id }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.TouchableStyles(theme)[
                                          'case list view'
                                        ].props}
                                        disabled={true}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TouchableStyles(theme)[
                                            'case list view'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* item */}
                                        <View>
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginBottom: 24,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* left */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { padding: 8 },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Right Link Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Right Link Text'].style,
                                                    { fontSize: 24 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.order}
                                              </Text>
                                            </View>
                                            {/* center */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { flex: 1 },
                                                dimensions.width
                                              )}
                                            >
                                              {/* name */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.text}
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        </View>
                        {/* Login Form */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 32 },
                            dimensions.width
                          )}
                        >
                          {/* Email Input */}
                          <TextInput
                            autoCapitalize={'none'}
                            changeTextDelay={500}
                            onChangeText={newEmailInputValue => {
                              try {
                                setEmailInput(newEmailInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Enter a value...'}
                            webShowOutline={true}
                            {...GlobalStyles.TextInputStyles(theme)['Input']
                              .props}
                            autoComplete={'email'}
                            autoFocus={true}
                            keyboardType={'email-address'}
                            placeholder={'enter your email address'}
                            placeholderTextColor={theme.colors['Medium']}
                            spellcheck={true}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)['Input']
                                  .style,
                                { textAlign: 'center' }
                              ),
                              dimensions.width
                            )}
                            textContentType={'emailAddress'}
                          />
                          {/* Sign In Button */}
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                console.log('Sign In Button ON_PRESS Start');
                                let error = null;
                                try {
                                  console.log('Start ON_PRESS:0 SET_VARIABLE');
                                  setGlobalVariableValue({
                                    key: 'user_email',
                                    value: emailInput,
                                  });
                                  console.log(
                                    'Complete ON_PRESS:0 SET_VARIABLE'
                                  );
                                  console.log('Start ON_PRESS:1 FETCH_REQUEST');
                                  const response = (
                                    await XanoApi.pINPATCH(Constants, {
                                      email: emailInput,
                                    })
                                  )?.json;
                                  console.log(
                                    'Complete ON_PRESS:1 FETCH_REQUEST',
                                    { response }
                                  );
                                  console.log('Start ON_PRESS:2 EXTRACT_KEY');
                                  const error = response?.message;
                                  console.log(
                                    'Complete ON_PRESS:2 EXTRACT_KEY',
                                    { error }
                                  );
                                  console.log('Start ON_PRESS:3 SET_VARIABLE');
                                  setGlobalVariableValue({
                                    key: 'error_message',
                                    value: error,
                                  });
                                  console.log(
                                    'Complete ON_PRESS:3 SET_VARIABLE'
                                  );
                                  console.log('Start ON_PRESS:4 SET_VARIABLE');
                                  if (response?.get_pin?.success) {
                                    setPin(true);
                                    console.log(
                                      'Complete ON_PRESS:4 SET_VARIABLE'
                                    );
                                  } else {
                                    console.log(
                                      'Skipped ON_PRESS:4 SET_VARIABLE: condition not met'
                                    );
                                  }
                                  console.log(
                                    'Start ON_PRESS:5 TEXT_INPUT_FOCUS'
                                  );
                                  pinInput7Sjuo40LRef.current.focus();
                                  console.log(
                                    'Complete ON_PRESS:5 TEXT_INPUT_FOCUS'
                                  );
                                } catch (err) {
                                  console.error(err);
                                  error = err.message ?? err;
                                }
                                console.log(
                                  'Sign In Button ON_PRESS Complete',
                                  error ? { error } : 'no error'
                                );
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={"Let's do this!"}
                          >
                            {'Sign Up'}
                          </Button>
                          {/* Error Message */}
                          <>
                            {!Constants['error_message'] ? null : (
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      theme.colors['Community_Bright_Yellow'],
                                    fontFamily: 'Roboto_700Bold',
                                    fontSize: 20,
                                    marginBottom: 16,
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Yikes! '}
                                {Constants['error_message']}
                                {' Please try again.'}
                              </Text>
                            )}
                          </>
                        </View>
                        {/* other links */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              marginTop: 32,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Tag - Grey - Text'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'Tag - Grey - Text'
                                ].style,
                                {
                                  fontFamily: 'Assistant_500Medium',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.link_header}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                              },
                              dimensions.width
                            )}
                          >
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  navigation.navigate('ActionListPublicScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)[
                                'Link - Big - Red'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)[
                                    'Link - Big - Red'
                                  ].style,
                                  {
                                    fontFamily: 'Assistant_700Bold',
                                    fontSize: 14,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'resources'}
                            />
                            {/* Link 3 */}
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  navigation.navigate('OrgList2Screen', {
                                    type: 'provider',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    fontSize: 14,
                                    opacity: 1,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'partners'}
                            />
                            {/* Link 2 */}
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  navigation.navigate('ProgramListScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    fontSize: 14,
                                    opacity: 1,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'programs'}
                            />
                            {/* Link 4 */}
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  navigation.navigate('TermsOfServiceScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    fontSize: 14,
                                    opacity: 1,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'terms of service'}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </>
                  {/* pin */}
                  <>
                    {!pin ? null : (
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              { textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Check your email for a 4-digit PIN.'}
                        </Text>
                        {/* Error Message */}
                        <>
                          {!errorScreen ? null : (
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color:
                                    theme.colors['Community_Bright_Yellow'],
                                  fontFamily: 'Assistant_700Bold',
                                  fontSize: 20,
                                  marginBottom: 16,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              {errorScreen}
                            </Text>
                          )}
                        </>
                        <Spacer left={8} right={8} bottom={24} top={24} />
                        <PinInput
                          autoComplete={'one-time-code'}
                          blurOnFull={true}
                          cellCount={4}
                          changeTextDelay={500}
                          clearOnCellFocus={true}
                          focusedBorderColor={theme.colors.primary}
                          keyboardType={'number-pad'}
                          onChangeText={newPinInputValue => {
                            try {
                              setPinInput(newPinInputValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          onInputFull={finalValue => {
                            const handler = async () => {
                              console.log('Pin Input ON_INPUT_FULL Start');
                              let error = null;
                              try {
                                console.log(
                                  'Start ON_INPUT_FULL:0 FETCH_REQUEST'
                                );
                                const response = (
                                  await XanoApi.loginPOST(Constants, {
                                    email: emailInput,
                                    password: pinInput,
                                  })
                                )?.json;
                                console.log(
                                  'Complete ON_INPUT_FULL:0 FETCH_REQUEST',
                                  { response }
                                );
                                console.log(
                                  'Start ON_INPUT_FULL:1 SET_VARIABLE'
                                );
                                setErrorScreen(response?.message);
                                console.log(
                                  'Complete ON_INPUT_FULL:1 SET_VARIABLE'
                                );
                                console.log(
                                  'Start ON_INPUT_FULL:2 SET_VARIABLE'
                                );
                                setGlobalVariableValue({
                                  key: 'auth_header',
                                  value: response?.authToken,
                                });
                                console.log(
                                  'Complete ON_INPUT_FULL:2 SET_VARIABLE'
                                );
                                console.log('Start ON_INPUT_FULL:3 NAVIGATE');
                                if (response?.authToken) {
                                  navigation.navigate('FSScreen');
                                  console.log(
                                    'Complete ON_INPUT_FULL:3 NAVIGATE'
                                  );
                                } else {
                                  console.log(
                                    'Skipped ON_INPUT_FULL:3 NAVIGATE: condition not met'
                                  );
                                }
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Pin Input ON_INPUT_FULL Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                          renderItem={({ cellValue, isFocused }) => {
                            return null;
                          }}
                          secureTextEntry={false}
                          {...GlobalStyles.PinInputStyles(theme)['Pin Input']
                            .props}
                          ref={pinInput7Sjuo40LRef}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.PinInputStyles(theme)['Pin Input']
                                .style,
                              { fontSize: 32 }
                            ),
                            dimensions.width
                          )}
                          value={pinInput}
                        />
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (
                                  await XanoApi.pINPATCH(Constants, {
                                    email: emailInput,
                                  })
                                )?.json;
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: '"rgba(0, 0, 0, 0)"',
                              borderRadius: 8,
                              color: theme.colors['Strong'],
                              fontFamily: 'Assistant_500Medium',
                              marginTop: 16,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'click here to request a new PIN'}
                        />
                      </View>
                    )}
                  </>
                </KeyboardAwareScrollView>
              </View>
            </>
          );
        }}
      </XanoApi.FetchAppGET>
    </ScreenContainer>
  );
};

export default withTheme(FSLoginScreen);
