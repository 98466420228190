import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const OrgList2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [category, setCategory] = React.useState('housing');
  const [checkin_exists, setCheckin_exists] = React.useState(
    props.route?.params?.checkin ?? ''
  );
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const loc = await getLocationUtil();
        setGlobalVariableValue({
          key: 'user_location',
          value: loc,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column', flexShrink: 0 },
        dimensions.width
      )}
    >
      <View style={StyleSheet.applyWidth({ flexGrow: 1 }, dimensions.width)}>
        <XanoApi.FetchOrganizationListGET
          location={Constants['user_location']}
          type={'provider'}
        >
          {({ loading, error, data, refetchOrganizationList }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* header */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['header'].style,
                    dimensions.width
                  )}
                >
                  {/* nav */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['nav 5'].style,
                      dimensions.width
                    )}
                  >
                    {/* back */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* view */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'auto',
                            flexDirection: 'column',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Light']}
                          name={'Ionicons/chevron-back'}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 4,
                              marginLeft: 4,
                              marginRight: 4,
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: theme.colors['Light'], fontSize: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'back'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* home */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('FSScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* view */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Light']}
                          name={'Feather/home'}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 4,
                              marginLeft: 4,
                              marginRight: 4,
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: theme.colors['Light'], fontSize: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'home'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                  {/* title */}
                  <>
                    {props.route?.params?.checkin ?? '' ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Big Header'].style,
                          dimensions.width
                        )}
                      >
                        {'\ncommunity partners'}
                      </Text>
                    )}
                  </>
                  {/* title 2 */}
                  <>
                    {!(props.route?.params?.checkin ?? '') ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Big Header'].style,
                          dimensions.width
                        )}
                      >
                        {'\nrequest checkin'}
                      </Text>
                    )}
                  </>
                  <>
                    {!(props.route?.params?.checkin ?? '') ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Big Header - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Big Header - Text']
                            .style,
                          dimensions.width
                        )}
                      >
                        {(props.route?.params?.participant ?? '')?.name_first}{' '}
                        {(props.route?.params?.participant ?? '')?.name_last}
                        {'\n#'}
                        {(props.route?.params?.checkin ?? '')?.id}
                        {' - '}
                        {(props.route?.params?.checkin ?? '')?.action}
                        {'\n'}
                      </Text>
                    )}
                  </>
                </View>

                <KeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableOnAndroid={false}
                  enableResetScrollToCoords={false}
                  keyboardShouldPersistTaps={'never'}
                  viewIsInsideTabBar={false}
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flexBasis: 1, padding: 32 },
                    dimensions.width
                  )}
                  showsVerticalScrollIndicator={false}
                >
                  {/* housing */}
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'housing'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                    </View>
                    <FlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'kODRny2X'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* horizontal touch */}
                            <>
                              {!(listData?.category === 'housing') ? null : (
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          props.route?.params?.checkin ??
                                          ''
                                        ) {
                                          (
                                            await XanoApi.checkinRequestPATCH(
                                              Constants,
                                              {
                                                checkin_id: (
                                                  props.route?.params
                                                    ?.checkin ?? ''
                                                )?.id,
                                                organization_id: listData?.id,
                                              }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            {
                                              user_id: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.participant_user_id,
                                            }
                                          );
                                        } else {
                                          navigation.navigate(
                                            'OrgDetailsScreen',
                                            { OrganizationID: listData?.id }
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  {/* new standard list view */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'horizontal'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'horizontal'
                                        ].style,
                                        {
                                          marginRight: 8,
                                          marginTop: 8,
                                          maxWidth: 275,
                                          minWidth: 275,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.category === 'legal'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/scale-balance'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'housing'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-home-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'money'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={'Ionicons/ios-wallet-outline'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'health'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/plus-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'connect'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-link-variant-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st}{' '}
                                          {listData?.address_zip}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  {/* money */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'money'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                    </View>
                    <FlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'htT1kju4'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* horizontal touch */}
                            <>
                              {!(listData?.category === 'money') ? null : (
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          props.route?.params?.checkin ??
                                          ''
                                        ) {
                                          (
                                            await XanoApi.checkinRequestPATCH(
                                              Constants,
                                              {
                                                checkin_id: (
                                                  props.route?.params
                                                    ?.checkin ?? ''
                                                )?.id,
                                                organization_id: listData?.id,
                                              }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            {
                                              user_id: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.participant_user_id,
                                            }
                                          );
                                        } else {
                                          navigation.navigate(
                                            'OrgDetailsScreen',
                                            { OrganizationID: listData?.id }
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  {/* new standard list view */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'horizontal'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'horizontal'
                                        ].style,
                                        {
                                          marginRight: 8,
                                          marginTop: 8,
                                          maxWidth: 275,
                                          minWidth: 275,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.category === 'legal'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/scale-balance'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'housing'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-home-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'money'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={'Ionicons/ios-wallet-outline'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'health'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/plus-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'connect'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-link-variant-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st}{' '}
                                          {listData?.address_zip}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  {/* health */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'health'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                    </View>
                    <FlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'dczp6SIJ'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* horizontal touch */}
                            <>
                              {!(listData?.category === 'health') ? null : (
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          props.route?.params?.checkin ??
                                          ''
                                        ) {
                                          (
                                            await XanoApi.checkinRequestPATCH(
                                              Constants,
                                              {
                                                checkin_id: (
                                                  props.route?.params
                                                    ?.checkin ?? ''
                                                )?.id,
                                                organization_id: listData?.id,
                                              }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            {
                                              user_id: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.participant_user_id,
                                            }
                                          );
                                        } else {
                                          navigation.navigate(
                                            'OrgDetailsScreen',
                                            { OrganizationID: listData?.id }
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  {/* new standard list view */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'horizontal'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'horizontal'
                                        ].style,
                                        {
                                          marginRight: 8,
                                          marginTop: 8,
                                          maxWidth: 275,
                                          minWidth: 275,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.category === 'legal'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/scale-balance'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'housing'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-home-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'money'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={'Ionicons/ios-wallet-outline'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'health'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/plus-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'connect'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-link-variant-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st}{' '}
                                          {listData?.address_zip}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  {/* legal */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'legal'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                    </View>
                    <FlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'sqHXrK5E'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* horizontal touch */}
                            <>
                              {!(listData?.category === 'legal') ? null : (
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          props.route?.params?.checkin ??
                                          ''
                                        ) {
                                          (
                                            await XanoApi.checkinRequestPATCH(
                                              Constants,
                                              {
                                                checkin_id: (
                                                  props.route?.params
                                                    ?.checkin ?? ''
                                                )?.id,
                                                organization_id: listData?.id,
                                              }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            {
                                              user_id: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.participant_user_id,
                                            }
                                          );
                                        } else {
                                          navigation.navigate(
                                            'OrgDetailsScreen',
                                            { OrganizationID: listData?.id }
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  {/* new standard list view */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'horizontal'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'horizontal'
                                        ].style,
                                        {
                                          marginRight: 8,
                                          marginTop: 8,
                                          maxWidth: 275,
                                          minWidth: 275,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.category === 'legal'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/scale-balance'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'housing'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-home-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'money'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={'Ionicons/ios-wallet-outline'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'health'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/plus-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'connect'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-link-variant-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st}{' '}
                                          {listData?.address_zip}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  {/* connect */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'connect'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                    </View>
                    <FlatList
                      data={fetchData}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'htItaJ20'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* horizontal touch */}
                            <>
                              {!(listData?.category === 'connect') ? null : (
                                <Touchable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        if (
                                          props.route?.params?.checkin ??
                                          ''
                                        ) {
                                          (
                                            await XanoApi.checkinRequestPATCH(
                                              Constants,
                                              {
                                                checkin_id: (
                                                  props.route?.params
                                                    ?.checkin ?? ''
                                                )?.id,
                                                organization_id: listData?.id,
                                              }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'UserProfileScreen',
                                            {
                                              user_id: (
                                                props.route?.params?.checkin ??
                                                ''
                                              )?.participant_user_id,
                                            }
                                          );
                                        } else {
                                          navigation.navigate(
                                            'OrgDetailsScreen',
                                            { OrganizationID: listData?.id }
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  {/* new standard list view */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'horizontal'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'horizontal'
                                        ].style,
                                        {
                                          marginRight: 8,
                                          marginTop: 8,
                                          maxWidth: 275,
                                          minWidth: 275,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          listData?.category === 'legal'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/scale-balance'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'housing'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-home-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'money'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={'Ionicons/ios-wallet-outline'}
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'health'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/plus-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          listData?.category === 'connect'
                                        ) ? null : (
                                          <Icon
                                            color={theme.colors['Primary']}
                                            name={
                                              'MaterialCommunityIcons/shield-link-variant-outline'
                                            }
                                            size={18}
                                            style={StyleSheet.applyWidth(
                                              { opacity: 0.5 },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View>
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st}{' '}
                                          {listData?.address_zip}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  <Divider
                    {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                    color={'rgba(0, 0, 0, 0)'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      dimensions.width
                    )}
                  />
                </KeyboardAwareScrollView>
              </>
            );
          }}
        </XanoApi.FetchOrganizationListGET>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(OrgList2Screen);
