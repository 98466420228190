import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import makeDate from '../global-functions/makeDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Picker,
  ScreenContainer,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const UserNewScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [inputAdmin, setInputAdmin] = React.useState(false);
  const [inputAdults, setInputAdults] = React.useState('');
  const [inputAlt, setInputAlt] = React.useState('');
  const [inputBar, setInputBar] = React.useState('');
  const [inputBenefits, setInputBenefits] = React.useState('');
  const [inputCause, setInputCause] = React.useState('');
  const [inputChildren, setInputChildren] = React.useState('');
  const [inputCity, setInputCity] = React.useState('');
  const [inputDOB, setInputDOB] = React.useState('');
  const [inputDay, setInputDay] = React.useState('');
  const [inputDisability, setInputDisability] = React.useState('');
  const [inputEDU, setInputEDU] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [inputEntryDate, setInputEntryDate] = React.useState('');
  const [inputEntryDay, setInputEntryDay] = React.useState('');
  const [inputEntryMonth, setInputEntryMonth] = React.useState('');
  const [inputEntryYear, setInputEntryYear] = React.useState('');
  const [inputFirst, setInputFirst] = React.useState('');
  const [inputGender, setInputGender] = React.useState('');
  const [inputHousing, setInputHousing] = React.useState('');
  const [inputIncome, setInputIncome] = React.useState('');
  const [inputLast, setInputLast] = React.useState('');
  const [inputLead, setInputLead] = React.useState(false);
  const [inputMiddle, setInputMiddle] = React.useState('');
  const [inputMonth, setInputMonth] = React.useState('');
  const [inputRace, setInputRace] = React.useState('');
  const [inputRole, setInputRole] = React.useState('');
  const [inputSaved, setInputSaved] = React.useState('');
  const [inputSavings, setInputSavings] = React.useState('');
  const [inputSource, setInputSource] = React.useState('');
  const [inputStateID, setInputStateID] = React.useState('');
  const [inputStatus, setInputStatus] = React.useState('');
  const [inputStreet, setInputStreet] = React.useState('');
  const [inputTEL, setInputTEL] = React.useState('');
  const [inputVet, setInputVet] = React.useState('');
  const [inputVision, setInputVision] = React.useState('');
  const [inputYear, setInputYear] = React.useState('');
  const [inputZIP, setInputZIP] = React.useState('');
  const [pickerState, setPickerState] = React.useState('');
  const [pickerWorksFor, setPickerWorksFor] = React.useState('');
  const [switchPrivacy, setSwitchPrivacy] = React.useState(false);
  const xanoUserPatchPATCH = XanoApi.useUserPatchPATCH();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'\nnew participant'}
        </Text>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 32,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={false}
      >
        {/* user edit admin */}
        <>
          {!Constants['user_details']?.admin ? null : (
            <View>
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                    { fontSize: 24 }
                  ),
                  dimensions.width
                )}
              >
                {'community lead'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { marginBottom: 16 }
                  ),
                  dimensions.width
                )}
              >
                {'Only leads and admins have access to these options.'}
              </Text>
              {/* role */}
              <View>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setInputRole(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'role'}
                  options={Constants['role']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={inputRole}
                />
              </View>
              {/* account status */}
              <View>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setInputStatus(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'account status'}
                  options={Constants['user_status_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={inputStatus}
                />
              </View>
              {/* works_for */}
              <View>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setPickerWorksFor(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'works for'}
                  options={Constants['organization_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerWorksFor}
                />
              </View>
              {/* bar */}
              <View>
                {/* input */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newInputValue => {
                    try {
                      setInputBar(newInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  multiline={false}
                  numberOfLines={10}
                  placeholder={'bar number'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    dimensions.width
                  )}
                  value={inputBar}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { alignSelf: 'flex-end' },
                  dimensions.width
                )}
              >
                <SwitchRow
                  label={'First Option'}
                  onValueChange={newSwitchRowValue => {
                    try {
                      setInputLead(newSwitchRowValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                  direction={'row'}
                  inactiveTrackColor={theme.colors['Light']}
                  label={'community lead   '}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      {
                        fontFamily: 'Assistant_700Bold',
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                  value={inputLead}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { alignSelf: 'flex-end' },
                  dimensions.width
                )}
              >
                <SwitchRow
                  label={'First Option'}
                  onValueChange={newSwitchRowValue => {
                    try {
                      setInputAdmin(newSwitchRowValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                  direction={'row'}
                  inactiveTrackColor={theme.colors['Light']}
                  label={'admin   '}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      {
                        fontFamily: 'Assistant_700Bold',
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                  value={inputAdmin}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { alignSelf: 'flex-end' },
                  dimensions.width
                )}
              >
                <SwitchRow
                  label={'First Option'}
                  onValueChange={newSwitchRowValue => {
                    try {
                      setInputStatus(newSwitchRowValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                  direction={'row'}
                  inactiveTrackColor={theme.colors['Light']}
                  label={'locked user account  '}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      {
                        fontFamily: 'Assistant_700Bold',
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            </View>
          )}
        </>
        {/* id */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 32 }, dimensions.width)}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                { fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'id'}
          </Text>
          {/* first */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputFirst(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'first name'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputFirst}
            />
          </View>
          {/* middle */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputMiddle(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'middle name, full please _/\\_'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputMiddle}
            />
          </View>
          {/* last */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputLast(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'last name'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputLast}
            />
          </View>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Small Header'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Small Header'].style,
              dimensions.width
            )}
          >
            {'date of birth'}
          </Text>
          {/* datepicker */}
          <View
            {...GlobalStyles.ViewStyles(theme)['datepicker'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['datepicker'].style,
              dimensions.width
            )}
          >
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputMonth(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Medium']}
              label={'month'}
              options={Constants['month']}
              placeholder={'month'}
              placeholderTextColor={theme.colors['Strong']}
              rightIconName={'Entypo/chevron-down'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.PickerStyles(theme)['picker'].style,
                  { marginTop: 0, width: 95 }
                ),
                dimensions.width
              )}
              value={inputMonth}
            />
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputDay(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Medium']}
              label={'day'}
              options={Constants['day']}
              placeholder={'day'}
              placeholderTextColor={theme.colors['Strong']}
              rightIconName={'Entypo/chevron-down'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.PickerStyles(theme)['picker'].style,
                  { width: 95 }
                ),
                dimensions.width
              )}
              value={inputDay}
            />
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputYear(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Medium']}
              label={'year'}
              options={Constants['year']}
              placeholder={'year'}
              placeholderTextColor={theme.colors['Strong']}
              rightIconName={'Entypo/chevron-down'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.PickerStyles(theme)['picker'].style,
                  { width: 110 }
                ),
                dimensions.width
              )}
              value={inputYear}
            />
          </View>
          {/* state id */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputStateID(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'state id'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputStateID}
            />
          </View>
        </View>
        {/* contact */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 64 }, dimensions.width)}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                { fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'contact'}
          </Text>
          {/* street */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputStreet(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'street address, eg 1234 Main Street'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputStreet}
            />
          </View>
          {/* city */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputCity(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'city'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputCity}
            />
          </View>
          {/* state */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setPickerState(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'state'}
              leftIconName={'Feather/map-pin'}
              options={Constants['state']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={pickerState}
            />
          </View>
          {/* zip */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputZIP(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'5-digit zip code'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputZIP}
            />
          </View>
          {/* tel */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputTEL(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'main phone number, 10 digits, no "-"'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputTEL}
            />
          </View>
          {/* alt tel */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputAlt(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'back phone number... just in case'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputAlt}
            />
          </View>
          {/* email */}
          <View>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              onChangeText={newInputValue => {
                try {
                  setInputEmail(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Input'].props}
              autoFocus={false}
              multiline={false}
              numberOfLines={10}
              placeholder={'email'}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Input'].style,
                dimensions.width
              )}
              value={inputEmail}
            />
          </View>
        </View>
        {/* entry */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 64 }, dimensions.width)}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                { fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'current situation'}
          </Text>
          {/* housing */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputHousing(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'where are you sleeping most nights?'}
              leftIconName={'Feather/home'}
              options={Constants['entry_housing']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputHousing}
            />
          </View>
          {/* causes */}
          <>
            {!(inputHousing !== 'secure') ? null : (
              <View>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setInputCause(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'main cause of instability'}
                  leftIconName={'AntDesign/warning'}
                  options={Constants['entry_causes']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={inputCause}
                />
              </View>
            )}
          </>
          {/* hh_adults */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputAdults(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'live with a partner? change this to "2"'}
              leftIconName={'Feather/user-plus'}
              options={Constants['entry_adults']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputAdults}
            />
          </View>
          {/* hh_children */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputChildren(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'how many children are in the household?'}
              leftIconName={'Feather/users'}
              options={Constants['entry_children']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputChildren}
            />
          </View>
          {/* benefits */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputBenefits(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'you getting SNAP? Medicaid? Etc...?'}
              leftIconName={'Feather/shield'}
              options={Constants['entry_benefits']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputBenefits}
            />
          </View>
          {/* source */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputSource(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'main source of income'}
              leftIconName={'Feather/trending-up'}
              options={Constants['entry_source']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputSource}
            />
          </View>
          {/* income */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputIncome(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'monthly cash income'}
              leftIconName={'Feather/dollar-sign'}
              options={Constants['entry_income']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputIncome}
            />
          </View>
        </View>
        {/* demo */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 64 }, dimensions.width)}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                { fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'demographics'}
          </Text>
          {/* gender */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputGender(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'gender'}
              leftIconName={'Ionicons/ios-transgender-outline'}
              options={Constants['gender']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputGender}
            />
          </View>
          {/* race */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputRace(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'race'}
              leftIconName={'Ionicons/earth-outline'}
              options={Constants['race']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputRace}
            />
          </View>
          {/* disability */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputDisability(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'disability'}
              leftIconName={'MaterialCommunityIcons/wheelchair-accessibility'}
              options={Constants['disability']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputDisability}
            />
          </View>
          {/* vet */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputVet(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'veteran status'}
              leftIconName={'Feather/flag'}
              options={Constants['vet']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputVet}
            />
          </View>
          {/* edu */}
          <View>
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background}
              dropDownBorderColor={theme.colors.divider}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerValue => {
                try {
                  setInputEDU(newPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              {...GlobalStyles.PickerStyles(theme)['picker'].props}
              iconColor={theme.colors['Primary']}
              label={'highest level of education'}
              leftIconName={'SimpleLineIcons/graduation'}
              options={Constants['edu']}
              rightIconName={'Ionicons/chevron-down'}
              style={StyleSheet.applyWidth(
                GlobalStyles.PickerStyles(theme)['picker'].style,
                dimensions.width
              )}
              value={inputEDU}
            />
          </View>
        </View>
        {/* submit */}
        <>
          {!inputDay ? null : (
            <View>
              <>
                {!inputMonth ? null : (
                  <View>
                    {/* View 2 */}
                    <>
                      {!inputYear ? null : (
                        <View>
                          {/* View 2 */}
                          <>
                            {!inputFirst ? null : (
                              <View>
                                {/* View 3 */}
                                <>
                                  {!inputLast ? null : (
                                    <View>
                                      <>
                                        {!inputTEL ? null : (
                                          <View>
                                            {/* Button Note */}
                                            <Button
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    const functionDate =
                                                      makeDate(
                                                        Variables,
                                                        inputDay,
                                                        inputMonth,
                                                        inputYear
                                                      );
                                                    const functionDate2 =
                                                      makeDate(
                                                        Variables,
                                                        inputEntryDay,
                                                        inputEntryMonth,
                                                        inputEntryYear
                                                      );
                                                    const postResponse = (
                                                      await xanoUserPatchPATCH.mutateAsync(
                                                        {
                                                          admin: inputAdmin,
                                                          alt: inputAlt,
                                                          bar: inputBar,
                                                          city: inputCity,
                                                          disability:
                                                            inputDisability,
                                                          dob: functionDate,
                                                          edu: inputEDU,
                                                          email: inputEmail,
                                                          entry_adult:
                                                            inputAdults,
                                                          entry_benefits:
                                                            inputBenefits,
                                                          entry_cause:
                                                            inputCause,
                                                          entry_child:
                                                            inputChildren,
                                                          entry_disposable:
                                                            inputSavings,
                                                          entry_housing:
                                                            inputHousing,
                                                          entry_income:
                                                            inputIncome,
                                                          entry_saved:
                                                            inputSaved,
                                                          entry_source:
                                                            inputSource,
                                                          first: inputFirst,
                                                          last: inputLast,
                                                          lead: inputLead,
                                                          middle: inputMiddle,
                                                          privacy:
                                                            switchPrivacy,
                                                          race: inputRace,
                                                          role: inputRole,
                                                          sex: inputGender,
                                                          state: pickerState,
                                                          state_id:
                                                            inputStateID,
                                                          status: inputStatus,
                                                          street: inputStreet,
                                                          tel: inputTEL,
                                                          vet: inputVet,
                                                          vision: inputVision,
                                                          works_for:
                                                            pickerWorksFor,
                                                          zip: inputZIP,
                                                        }
                                                      )
                                                    )?.json;
                                                    setErrorMessage(
                                                      postResponse?.message
                                                    );
                                                    if (
                                                      postResponse?.message ===
                                                      'success'
                                                    ) {
                                                      navigation.navigate(
                                                        'CaseNewScreen',
                                                        {
                                                          participant:
                                                            postResponse?.user,
                                                        }
                                                      );
                                                    }
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              {...GlobalStyles.ButtonStyles(
                                                theme
                                              )['Button'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.ButtonStyles(
                                                    theme
                                                  )['Button'].style,
                                                  {
                                                    marginBottom: 32,
                                                    marginTop: 32,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              title={'refer participant to...'}
                                            />
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(UserNewScreen);
