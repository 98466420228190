import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import makeDate from '../global-functions/makeDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  Picker,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const UserSearchScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [admin, setAdmin] = React.useState(false);
  const [day, setDay] = React.useState('');
  const [keyword, setKeyword] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [name, setName] = React.useState('');
  const [searchList, setSearchList] = React.useState('');
  const [year, setYear] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Constants['user_details']?.admin) {
        setAdmin(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 3'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 3'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>
        <Divider
          color={theme.colors.divider}
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
        {/* switch */}
        <>
          {!Constants['user_details']?.admin ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'stretch',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <>
                {admin ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors['Light'],
                          fontFamily: 'Assistant_600SemiBold',
                          fontSize: 14,
                          padding: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'admin'}
                  </Text>
                )}
              </>
              {/* Text 3 */}
              <>
                {!admin ? null : (
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'Assistant_700Bold',
                          fontSize: 14,
                          padding: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'admin'}
                  </Text>
                )}
              </>
              <Switch
                onValueChange={newSwitchValue => {
                  try {
                    setAdmin(newSwitchValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={admin}
              />
            </View>
          )}
        </>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'search'}
        </Text>
        {/* non-admin */}
        <>
          {admin ? null : (
            <View>
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setName(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                autoFocus={false}
                editable={true}
                placeholder={'last name'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    { marginTop: 16 }
                  ),
                  dimensions.width
                )}
                value={name}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)[
                  'Print Header - Delete Later'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Print Header - Delete Later']
                    .style,
                  dimensions.width
                )}
              >
                {'date of birth'}
              </Text>
              {/* datepicker */}
              <View
                {...GlobalStyles.ViewStyles(theme)['datepicker'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['datepicker'].style,
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setMonth(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Medium']}
                  label={'month'}
                  options={Constants['month']}
                  placeholder={'month'}
                  placeholderTextColor={theme.colors['Strong']}
                  rightIconName={'Entypo/chevron-down'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.PickerStyles(theme)['picker'].style,
                      { marginTop: 0, width: 95 }
                    ),
                    dimensions.width
                  )}
                  value={month}
                />
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setDay(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Medium']}
                  label={'day'}
                  options={Constants['day']}
                  placeholder={'day'}
                  placeholderTextColor={theme.colors['Strong']}
                  rightIconName={'Entypo/chevron-down'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.PickerStyles(theme)['picker'].style,
                      { width: 95 }
                    ),
                    dimensions.width
                  )}
                  value={day}
                />
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setYear(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Medium']}
                  label={'year'}
                  options={Constants['year']}
                  placeholder={'year'}
                  placeholderTextColor={theme.colors['Strong']}
                  rightIconName={'Entypo/chevron-down'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.PickerStyles(theme)['picker'].style,
                      { width: 125 }
                    ),
                    dimensions.width
                  )}
                  value={year}
                />
              </View>
              <>
                {!name ? null : (
                  <View>
                    <>
                      {!day ? null : (
                        <View>
                          <>
                            {!month ? null : (
                              <View>
                                {/* View 2 */}
                                <>
                                  {!year ? null : (
                                    <View>
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            console.log(
                                              'Button ON_PRESS Start'
                                            );
                                            let error = null;
                                            try {
                                              console.log(
                                                'Start ON_PRESS:0 CUSTOM_FUNCTION'
                                              );
                                              const functionDate = makeDate(
                                                Variables,
                                                day,
                                                month,
                                                year
                                              );
                                              console.log(
                                                'Complete ON_PRESS:0 CUSTOM_FUNCTION',
                                                { functionDate }
                                              );
                                              console.log(
                                                'Start ON_PRESS:1 FETCH_REQUEST'
                                              );
                                              const response = (
                                                await XanoApi.userListGET(
                                                  Constants,
                                                  {
                                                    dob: functionDate,
                                                    keyword: keyword,
                                                    last_name: name,
                                                  }
                                                )
                                              )?.json;
                                              console.log(
                                                'Complete ON_PRESS:1 FETCH_REQUEST',
                                                { response }
                                              );
                                              console.log(
                                                'Start ON_PRESS:2 SET_VARIABLE'
                                              );
                                              setSearchList(response?.users);
                                              console.log(
                                                'Complete ON_PRESS:2 SET_VARIABLE'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                              error = err.message ?? err;
                                            }
                                            console.log(
                                              'Button ON_PRESS Complete',
                                              error ? { error } : 'no error'
                                            );
                                          };
                                          handler();
                                        }}
                                        {...GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ButtonStyles(theme)[
                                              'Button'
                                            ].style,
                                            {
                                              fontFamily:
                                                'Assistant_800ExtraBold',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={'search'}
                                      />
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        {/* admin */}
        <>
          {!admin ? null : (
            <View>
              {/* Text Input 2 */}
              <TextInput
                autoCapitalize={'none'}
                changeTextDelay={500}
                onChangeText={newTextInput2Value => {
                  try {
                    setKeyword(newTextInput2Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onSubmitEditing={() => {
                  const handler = async () => {
                    try {
                      const response = (
                        await XanoApi.userListGET(Constants, {
                          dob: new Date().toISOString(),
                          keyword: keyword,
                          last_name: keyword,
                        })
                      )?.json;
                      setSearchList(response?.users);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                autoFocus={false}
                editable={true}
                enablesReturnKeyAutomatically={true}
                placeholder={'keyword'}
                spellcheck={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    { marginTop: 16 }
                  ),
                  dimensions.width
                )}
                value={keyword}
              />
              {/* Button 2 */}
              <Button
                onPress={() => {
                  const handler = async () => {
                    console.log('Button 2 ON_PRESS Start');
                    let error = null;
                    try {
                      console.log('Start ON_PRESS:0 FETCH_REQUEST');
                      const response = (
                        await XanoApi.userListGET(Constants, {
                          dob: new Date().toISOString(),
                          keyword: keyword,
                          last_name: name,
                        })
                      )?.json;
                      console.log('Complete ON_PRESS:0 FETCH_REQUEST', {
                        response,
                      });
                      console.log('Start ON_PRESS:1 SET_VARIABLE');
                      setSearchList(response?.users);
                      console.log('Complete ON_PRESS:1 SET_VARIABLE');
                    } catch (err) {
                      console.error(err);
                      error = err.message ?? err;
                    }
                    console.log(
                      'Button 2 ON_PRESS Complete',
                      error ? { error } : 'no error'
                    );
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    { fontFamily: 'Assistant_800ExtraBold' }
                  ),
                  dimensions.width
                )}
                title={'admin search'}
              />
            </View>
          )}
        </>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          { flexBasis: 1, padding: 32 },
          dimensions.width
        )}
        showsVerticalScrollIndicator={false}
      >
        <>
          {!searchList ? null : (
            <View>
              <View
                {...GlobalStyles.ViewStyles(theme)['Tag - Red - View'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['Tag - Red - View'].style,
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                      { textAlign: 'center', textTransform: 'none' }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "If you can't find them but think they have an account, use a partial last name in case there was a typo."
                  }
                </Text>
              </View>
            </View>
          )}
        </>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 32 }, dimensions.width)}
        >
          {/* list */}
          <FlatList
            data={searchList}
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ?? listData?.uuid ?? index.toString()
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'uSlexE9t'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <>
                  {/* touchable */}
                  <Touchable
                    onPress={() => {
                      try {
                        if (Constants['user_details']?.role !== 'viewer') {
                          navigation.navigate('UserProfileScreen', {
                            user_id: listData?.id,
                          });
                        }
                        if (Constants['user_details']?.role === 'viewer') {
                          navigation.navigate('OpportunityReportScreen', {
                            user_id: listData?.id,
                          });
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.TouchableStyles(theme)['case list view']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['case list view']
                        .style,
                      dimensions.width
                    )}
                  >
                    {/* item */}
                    <View>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['Background Input'],
                            borderColor: theme.colors['Divider'],
                            borderRadius: 4,
                            borderWidth: 1,
                            flexDirection: 'row',
                            marginBottom: 24,
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* left */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: 8 },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={theme.colors['Primary']}
                            name={'Feather/user'}
                            style={StyleSheet.applyWidth(
                              { opacity: 0.5 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* center */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* name */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['List Bold']
                              .props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['List Bold'].style,
                              dimensions.width
                            )}
                          >
                            {listData?.name_first} {listData?.name_last}
                          </Text>
                          {/* address */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {listData?.address_city}
                            {', '}
                            {listData?.address_state}
                          </Text>
                          {/* dob */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {listData?.date_of_birth}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </Touchable>
                </>
              );
            }}
            contentContainerStyle={StyleSheet.applyWidth(
              { flexWrap: 'nowrap' },
              dimensions.width
            )}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          />
        </View>
        {/* View 3 */}
        <>
          {!searchList ? null : (
            <View>
              {/* direct referral */}
              <>
                {!Constants['works_for']?.direct ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('UserNewScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.TouchableStyles(theme)[
                      'touchable icon right'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)[
                        'touchable icon right'
                      ].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'flex-start',
                            flex: 1,
                            flexWrap: 'nowrap',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Right Link Text']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Right Link Text']
                                .style,
                              { alignSelf: 'flex-end' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'submit a direct referral'}
                        </Text>

                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Caption'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Caption'].style,
                              {
                                alignSelf: 'auto',
                                fontFamily: 'Assistant_300Light',
                                textAlign: 'right',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {null}
                        </Text>
                      </View>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'Feather/user-plus'}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 8 },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          )}
        </>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(UserSearchScreen);
