import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const UserActionPlanScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [response, setResponse] = React.useState('');
  const [showActionPlanNote, setShowActionPlanNote] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [viewSelect, setViewSelect] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchUserReportGET
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'selected_case',
                value: fetchData,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        user_id={props.route?.params?.user_id ?? 637}
      >
        {({ loading, error, data, refetchUserReport }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Divider'], padding: 32 },
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'\n\naction plan'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.participant?.name_first}{' '}
                  {fetchData?.participant?.name_last}
                </Text>
              </View>
              {/* Keyboard Aware Scroll View 2 */}
              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Background'],
                    flexBasis: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                  },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  <FlatList
                    data={fetchData?.unique}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'Z0Sx66gy'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* View 2 */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'Tag - Grey - View'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'Tag - Grey - View'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Tag - Grey - Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'Tag - Grey - Text'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {listData}
                            </Text>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* action plan list */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  {/* list */}
                  <FlatList
                    data={fetchData?.checkin}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'Q601Embe'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* touchable */}
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('CheckinDetailsScreen', {
                                  page: 'profile',
                                  participant: fetchData?.participant,
                                  checkin: listData,
                                  checkin_provider_id:
                                    listData?.provider_org_id,
                                  user_org_id: Constants['works_for']?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.TouchableStyles(theme)[
                              'case list view'
                            ].props}
                            disabled={
                              Constants['works_for']?.type === 'justice'
                            }
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TouchableStyles(theme)[
                                'case list view'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {/* item */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)['horizontal']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)['horizontal']
                                    .style,
                                  { marginBottom: 8, marginTop: 8 }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* left */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* verified icon */}
                                <>
                                  {!listData?.verified ? null : (
                                    <Icon
                                      size={24}
                                      color={theme.colors['Primary']}
                                      name={
                                        'MaterialCommunityIcons/check-decagram'
                                      }
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* open icon */}
                                <>
                                  {listData?.verified ? null : (
                                    <Icon
                                      size={24}
                                      color={theme.colors['Dark']}
                                      name={
                                        'MaterialCommunityIcons/decagram-outline'
                                      }
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                              {/* center */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* action */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'List Bold'
                                  ].props}
                                  numberOfLines={1}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['List Bold']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.action}
                                </Text>
                                {/* verified */}
                                <>
                                  {!listData?.verified ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].props}
                                      numberOfLines={1}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.date}
                                      {' - '}
                                      {listData?.entity}
                                      {' - '}
                                      {listData?.name_first}{' '}
                                      {listData?.name_last}
                                    </Text>
                                  )}
                                </>
                                {/* unverified */}
                                <>
                                  {listData?.verified ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].props}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.entity}
                                    </Text>
                                  )}
                                </>
                                <>
                                  {!listData?.provider_note ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginTop: 4 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* note */}
                                      <>
                                        {!(
                                          Constants['user_details']?.role ===
                                          'defender'
                                        ) ? null : (
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'Tag - Grey - View'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                {
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Background Input'
                                                    ],
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  margin: 0,
                                                  opacity: 0.75,
                                                  padding: 4,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Grey - Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  {
                                                    color:
                                                      theme.colors['Strong'],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.provider_note}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* note 2 */}
                                      <>
                                        {!(
                                          Constants['user_details']?.role ===
                                          'participant'
                                        ) ? null : (
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'Tag - Grey - View'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                {
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Background Input'
                                                    ],
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  margin: 0,
                                                  opacity: 0.75,
                                                  padding: 4,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Grey - Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  {
                                                    color:
                                                      theme.colors['Strong'],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.provider_note}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          </Touchable>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </View>
                {/* options */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  {/* action */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ActionListScreen', {
                          participant: fetchData?.participant,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { paddingRight: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'add action'}
                      </Text>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/alert-decagram-outline'}
                      />
                    </View>
                  </Touchable>
                  {/* print */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('OpportunityReportScreen', {
                          report: fetchData,
                          user_id: fetchData?.participant?.id,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { paddingRight: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'view print-safe version'}
                      </Text>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'AntDesign/printer'}
                      />
                    </View>
                  </Touchable>
                </View>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      { height: 64 }
                    ),
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchUserReportGET>
    </ScreenContainer>
  );
};

export default withTheme(UserActionPlanScreen);
