import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  Markdown,
  ScreenContainer,
  Touchable,
  VideoPlayer,
  YoutubePlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const FAQDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [verified, setVerified] = React.useState('verified');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchFAQDetailGET faq_id={props.route?.params?.faq_id ?? 1}>
        {({ loading, error, data, refetchFAQDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <Divider
                  color={theme.colors.divider}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'faq'}
                </Text>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.question}
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexBasis: 1, padding: 32 },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                {/* overview */}
                <View>
                  <Markdown
                    {...GlobalStyles.MarkdownStyles(theme)['Text 3'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.MarkdownStyles(theme)['Text 3'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.answer}
                  </Markdown>
                </View>
                {/* Divider 2 */}
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                {/* video */}
                <>
                  {!fetchData?.link_video ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start' },
                        dimensions.width
                      )}
                    >
                      {/* header */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            { marginBottom: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'video'}
                      </Text>
                      <>
                        {!fetchData?.link_video ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                flexDirection: 'column',
                              },
                              dimensions.width
                            )}
                          >
                            <YoutubePlayer
                              autoplay={false}
                              {...GlobalStyles.YoutubePlayerStyles(theme)[
                                'Youtube Player'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.YoutubePlayerStyles(theme)[
                                  'Youtube Player'
                                ].style,
                                dimensions.width
                              )}
                              videoId={fetchData?.link_video}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
                {/* connect */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-start' },
                    dimensions.width
                  )}
                >
                  {/* info */}
                  <>
                    {!fetchData?.link_web ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            /* hidden 'Open Browser' action */
                            navigation.navigate('FileViewScreen', {
                              file_url: fetchData?.link_web,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.TouchableStyles(theme)[
                          'icon touchable'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)['icon touchable']
                            .style,
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={theme.colors['Primary']}
                            name={'MaterialIcons/info-outline'}
                            style={StyleSheet.applyWidth(
                              { marginRight: 8 },
                              dimensions.width
                            )}
                          />
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {'more info'}
                          </Text>
                        </View>
                      </Touchable>
                    )}
                  </>
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchFAQDetailGET>
      <View>
        {/* Divider 3 */}
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={'rgba(0, 0, 0, 0)'}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(FAQDetailScreen);
