import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEqualTo from '../global-functions/isEqualTo';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CaseDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [inputBG, setInputBG] = React.useState('');
  const [response, setResponse] = React.useState('');
  const [toggle, setToggle] = React.useState(false);
  const [toggleAction, setToggleAction] = React.useState(false);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchCaseGetOneGET
        case_id={props.route?.params?.case_id ?? 1}
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'selected_case',
                value: fetchData,
              });
              if (isEqualTo(parseBoolean(inputBG), false)) {
                setInputBG(fetchData?.case?.bg_report);
              }
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchCaseGetOne }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <Divider
                  color={theme.colors.divider}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'#'}
                  {fetchData?.case?.id}
                  {' - '}
                  {fetchData?.program?.name}
                </Text>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.case?.case_type}
                </Text>
                {/* jurisdiction */}
                <>
                  {!fetchData?.jurisdiction?.entity ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header - Text']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header - Text']
                          .style,
                        dimensions.width
                      )}
                    >
                      {Constants['selected_case']?.jurisdiction?.entity}
                      {' ('}
                      {Constants['selected_case']?.jurisdiction?.address_st}
                      {')'}
                    </Text>
                  )}
                </>
                {/* status */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
                    dimensions.width
                  )}
                >
                  {Constants['selected_case']?.case?.next_date}
                  {' - '}
                  {Constants['selected_case']?.case?.status}
                </Text>
                {/* tags */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {!fetchData?.case?.flag_participant ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'participant'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!fetchData?.case?.flag_provider ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'provider'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!fetchData?.case?.flag_defender ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'defender'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!fetchData?.case?.flag_prosecutor ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'prosecutor'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!fetchData?.case?.flag_court ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'court'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {!fetchData?.case?.flag_special ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['Tag - Red - View']
                            .style,
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                            dimensions.width
                          )}
                        >
                          {'special'}
                        </Text>
                      </View>
                    )}
                  </>
                </View>
              </View>
              {/* Keyboard Aware Scroll View 2 */}
              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Background'],
                    flex: 1,
                    flexBasis: 1,
                    flexShrink: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                  },
                  dimensions.width
                )}
                extraHeight={0}
                extraScrollHeight={0}
                showsVerticalScrollIndicator={false}
              >
                {/* not court */}
                <>
                  {fetchData?.allow_court_view ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'stretch' },
                        dimensions.width
                      )}
                    >
                      {/* participant 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 32 },
                          dimensions.width
                        )}
                      >
                        {/* item */}
                        <View>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'participant'}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* center */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              {/* name */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.participant?.name_first}{' '}
                                {fetchData?.participant?.name_middle}{' '}
                                {fetchData?.participant?.name_last}
                              </Text>
                              {/* case type */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.participant?.date_of_birth}
                              </Text>
                            </View>
                            {/* right 2 */}
                            <>
                              {Constants['works_for']?.type ===
                              'justice' ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-end',
                                      alignSelf: 'auto',
                                      flexDirection: 'column',
                                      flexWrap: 'nowrap',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onLongPress={() => {
                                      try {
                                        Linking.openURL(
                                          `sms:${fetchData?.participant?.tel}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPress={() => {
                                      try {
                                        Linking.openURL(
                                          `tel:${fetchData?.participant?.tel}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Small Header - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Small Header - Red'
                                          ].style,
                                          {
                                            alignSelf: 'stretch',
                                            flexBasis: 0,
                                            flexGrow: 0,
                                            flexShrink: 1,
                                            opacity: 0.75,
                                            textAlign: 'right',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.participant?.tel}
                                    </Text>
                                  </Touchable>
                                  {/* Touchable 2 */}
                                  <Touchable
                                    onLongPress={() => {
                                      try {
                                        Linking.openURL(
                                          `sms:${fetchData?.participant?.tel_alt}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPress={() => {
                                      try {
                                        Linking.openURL(
                                          `tel:${fetchData?.participant?.tel_alt}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Small Header - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Small Header - Red'
                                          ].style,
                                          {
                                            alignSelf: 'stretch',
                                            flexBasis: 0,
                                            flexGrow: 0,
                                            flexShrink: 1,
                                            opacity: 0.75,
                                            textAlign: 'right',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.participant?.tel_alt}
                                    </Text>
                                  </Touchable>
                                  {/* Touchable 3 */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        Linking.openURL(
                                          `mailto:${fetchData?.participant?.email}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Small Header - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Small Header - Red'
                                          ].style,
                                          {
                                            alignSelf: 'stretch',
                                            flexBasis: 0,
                                            flexGrow: 0,
                                            flexShrink: 1,
                                            opacity: 0.75,
                                            textAlign: 'right',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {fetchData?.participant?.email}
                                    </Text>
                                  </Touchable>
                                  {/* Touchable 4 */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'UserDetailScreen',
                                          {
                                            user_id: fetchData?.participant?.id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Small Header - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Small Header - Red'
                                          ].style,
                                          {
                                            alignSelf: 'stretch',
                                            flexBasis: 0,
                                            flexGrow: 0,
                                            flexShrink: 1,
                                            opacity: 0.75,
                                            textAlign: 'right',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'bio'}
                                    </Text>
                                  </Touchable>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                      {/* vision */}
                      <>
                        {!fetchData?.participant?.vision_statement ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 32 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Header - Page'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Header - Page']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'vision statement'}
                            </Text>

                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {fetchData?.participant?.vision_statement}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* provider */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 32 },
                          dimensions.width
                        )}
                      >
                        {/* touchable */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('OrgDetailsScreen', {
                                OrganizationID:
                                  fetchData?.case?.provider_org_id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'case list view'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'case list view'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {/* item */}
                          <View>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Header - Page'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Header - Page']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'provider'}
                            </Text>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            />
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              {/* center */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* manager */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  numberOfLines={1}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.provider_user?.name_first}{' '}
                                  {fetchData?.provider_user?.name_last}
                                </Text>
                                {/* org */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  numberOfLines={1}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.provider?.entity}
                                </Text>
                              </View>
                              {/* right */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'flex-end',
                                    alignItems: 'stretch',
                                    flexDirection: 'column',
                                    flexShrink: 1,
                                    maxWidth: '30%',
                                    minWidth: '30%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Link 4 */}
                                <Link
                                  accessible={true}
                                  onPress={() => {
                                    try {
                                      Linking.openURL(
                                        `tel:${fetchData?.participant?.tel}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.LinkStyles(theme)['Link']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.LinkStyles(theme)['Link']
                                        .style,
                                      {
                                        alignSelf: 'flex-end',
                                        opacity: 0.75,
                                        textAlign: 'auto',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={`${fetchData?.provider_user?.tel}`}
                                />
                                {/* Link 4 */}
                                <Link
                                  accessible={true}
                                  onPress={() => {
                                    try {
                                      Linking.openURL(
                                        `tel:${fetchData?.participant?.tel_alt}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.LinkStyles(theme)['Link']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.LinkStyles(theme)['Link']
                                        .style,
                                      { alignSelf: 'flex-end', opacity: 0.75 }
                                    ),
                                    dimensions.width
                                  )}
                                  title={`${fetchData?.provider_user?.tel_alt}`}
                                />
                                {/* Link 4 */}
                                <Link
                                  accessible={true}
                                  onPress={() => {
                                    try {
                                      Linking.openURL(
                                        `mailto:${fetchData?.participant?.email}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.LinkStyles(theme)['Link']
                                    .props}
                                  ellipsizeMode={'tail'}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.LinkStyles(theme)['Link']
                                        .style,
                                      { alignSelf: 'flex-end', opacity: 0.75 }
                                    ),
                                    dimensions.width
                                  )}
                                  title={`${fetchData?.provider_user?.email}`}
                                />
                              </View>
                            </View>
                            {/* provider note */}
                            <>
                              {!fetchData?.case?.provider_note ? null : (
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'Tag - Grey - View'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'Tag - Grey - View'
                                      ].style,
                                      {
                                        backgroundColor:
                                          theme.colors['Background Input'],
                                        borderColor: theme.colors['Divider'],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'Tag - Grey - Text'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.case?.provider_note}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        </Touchable>
                      </View>
                      {/* action plan list 2 */}
                      <>
                        {!(fetchData?.checkin?.length >= 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 32 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Header - Page'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Header - Page']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'action plan'}
                            </Text>
                            {/* tags */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'flex-start',
                                    },
                                  ],
                                  flexDirection: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'row',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'row',
                                    },
                                  ],
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'flex-start',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'flex-start',
                                    },
                                  ],
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <FlatList
                                data={fetchData?.unique}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'ntIGdzsi'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'Tag - Gray'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'Tag - Gray'
                                          ].style,
                                          { flexDirection: 'row' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData}
                                      </Text>
                                    </View>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                horizontal={true}
                              />
                            </View>
                            {/* list */}
                            <FlatList
                              data={fetchData?.checkin}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(listData, index) =>
                                listData?.id ??
                                listData?.uuid ??
                                index.toString()
                              }
                              keyboardShouldPersistTaps={'never'}
                              listKey={'uKvbhbNR'}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const listData = item;
                                return (
                                  <>
                                    {/* touchable */}
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'CheckinDetailsScreen',
                                            {
                                              page: 'case',
                                              participant:
                                                fetchData?.participant,
                                              checkin: listData,
                                              checkin_provider_id:
                                                listData?.provider_org_id,
                                              user_org_id:
                                                Constants['works_for']?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      {...GlobalStyles.TouchableStyles(theme)[
                                        'case list view'
                                      ].props}
                                      disabled={!fetchData?.allow_action}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TouchableStyles(theme)[
                                          'case list view'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {/* item */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            flexDirection: 'row',
                                            marginBottom: 8,
                                            marginTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* left */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 8 },
                                            dimensions.width
                                          )}
                                        >
                                          {/* verified icon */}
                                          <>
                                            {!listData?.verified ? null : (
                                              <Icon
                                                size={24}
                                                color={theme.colors['Primary']}
                                                name={
                                                  'MaterialCommunityIcons/check-decagram'
                                                }
                                                style={StyleSheet.applyWidth(
                                                  { opacity: 0.5 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          {/* open icon */}
                                          <>
                                            {listData?.verified ? null : (
                                              <Icon
                                                size={24}
                                                color={theme.colors['Dark']}
                                                name={
                                                  'MaterialCommunityIcons/decagram-outline'
                                                }
                                                style={StyleSheet.applyWidth(
                                                  { opacity: 0.5 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                        </View>
                                        {/* center */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { flex: 1 },
                                            dimensions.width
                                          )}
                                        >
                                          {/* action */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].props}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'List Bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.action}
                                          </Text>
                                          {/* verified */}
                                          <>
                                            {!listData?.verified ? null : (
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.date}
                                                {' - '}
                                                {listData?.entity}
                                                {' - '}
                                                {listData?.name_first}{' '}
                                                {listData?.name_last}
                                              </Text>
                                            )}
                                          </>
                                          {/* unverified */}
                                          <>
                                            {listData?.verified ? null : (
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.entity}
                                              </Text>
                                            )}
                                          </>
                                          <>
                                            {!listData?.provider_note ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginTop: 4 },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* note */}
                                                <>
                                                  {!fetchData?.allow_action_note ? null : (
                                                    <View
                                                      {...GlobalStyles.ViewStyles(
                                                        theme
                                                      )['Tag - Gray'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.ViewStyles(
                                                            theme
                                                          )['Tag - Gray'].style,
                                                          {
                                                            alignItems:
                                                              'flex-start',
                                                            backgroundColor:
                                                              theme.colors[
                                                                'Background Input'
                                                              ],
                                                            borderColor:
                                                              theme.colors[
                                                                'Divider'
                                                              ],
                                                            margin: 0,
                                                            opacity: 0.75,
                                                            padding: 4,
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['Tag - Grey - Text']
                                                          .props}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'Tag - Grey - Text'
                                                            ].style,
                                                            {
                                                              color:
                                                                theme.colors[
                                                                  'Strong'
                                                                ],
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          listData?.provider_note
                                                        }
                                                      </Text>
                                                    </View>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </View>
                        )}
                      </>
                      {/* update */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 32 },
                          dimensions.width
                        )}
                      >
                        {/* touchable 2 */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('CaseEditScreen', {
                                case: fetchData?.case,
                                selected_case: fetchData,
                                participant: fetchData?.participant,
                                jurisdiction: fetchData?.jurisdiction,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'case list view'
                          ].props}
                          disabled={!fetchData?.allow_case_details}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'case list view'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {/* item */}
                          <View>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Header - Page'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Header - Page']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'update'}
                            </Text>
                            {/* update */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  { flexGrow: 1 }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.case?.update}
                            </Text>
                          </View>
                        </Touchable>
                      </View>
                      {/* case list */}
                      <>
                        {!(
                          Constants['user_details']?.role !== 'provider'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 32 },
                              dimensions.width
                            )}
                          >
                            {/* touchable 2 */}
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('CaseEditScreen', {
                                    case: fetchData?.case,
                                    selected_case: fetchData,
                                    participant: fetchData?.participant,
                                    jurisdiction: fetchData?.jurisdiction,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.TouchableStyles(theme)[
                                'case list view'
                              ].props}
                              disabled={!fetchData?.allow_case_details}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TouchableStyles(theme)[
                                  'case list view'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {/* item */}
                              <View>
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Header - Page'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Header - Page'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'case list'}
                                </Text>
                                {/* tags 2 */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)['tags']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)['tags']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!fetchData?.case?.city_user_id ? null : (
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'Tag - Grey - View'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'Tag - Grey - View'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'city'}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                  <>
                                    {!fetchData?.case?.state_user_id ? null : (
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'Tag - Grey - View'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'Tag - Grey - View'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'state'}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                  <>
                                    {!fetchData?.case
                                      ?.sign_def_user_id ? null : (
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'Tag - Grey - View'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'Tag - Grey - View'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'defender'}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                </View>
                                {/* update */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      { flexGrow: 1 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.case?.case_list}
                                </Text>
                              </View>
                            </Touchable>
                          </View>
                        )}
                      </>
                      {/* options */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 64, marginTop: 16 },
                          dimensions.width
                        )}
                      >
                        {/* case details */}
                        <>
                          {!fetchData?.allow_case_details ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('CaseEditScreen', {
                                    case: fetchData?.case,
                                    selected_case: fetchData,
                                    participant: fetchData?.participant,
                                    jurisdiction: fetchData?.jurisdiction,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { marginTop: 32 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'case details'}
                                </Text>
                                <Icon
                                  size={24}
                                  {...GlobalStyles.IconStyles(theme)[
                                    'right link icon'
                                  ].props}
                                  color={theme.colors['Primary']}
                                  name={'MaterialCommunityIcons/scale-balance'}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.IconStyles(theme)[
                                      'right link icon'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </Touchable>
                          )}
                        </>
                        {/* option_notes */}
                        <>
                          {!fetchData?.allow_add_note ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('CaseNotesScreen', {
                                    selected_case: Constants['selected_case'],
                                    jurisdiction:
                                      fetchData?.jurisdiction?.entity,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { marginTop: 32 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'notes'}
                                </Text>
                                <Icon
                                  size={24}
                                  {...GlobalStyles.IconStyles(theme)[
                                    'right link icon'
                                  ].props}
                                  color={theme.colors['Primary']}
                                  name={'Ionicons/ios-document-attach-outline'}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.IconStyles(theme)[
                                      'right link icon'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </Touchable>
                          )}
                        </>
                        {/* sign */}
                        <>
                          {!fetchData?.allow_sign_motion ? null : (
                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await XanoApi.caseSignPATCH(Constants, {
                                        case_id:
                                          props.route?.params?.case_id ?? 1,
                                      })
                                    )?.json;
                                    await refetchCaseGetOne();
                                    navigation.navigate('CaseDetailsScreen', {
                                      case_id: fetchData?.case?.id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                { marginTop: 32 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'sign motion'}
                                </Text>
                                <Icon
                                  size={24}
                                  {...GlobalStyles.IconStyles(theme)[
                                    'right link icon'
                                  ].props}
                                  color={theme.colors['Primary']}
                                  name={
                                    'MaterialCommunityIcons/signature-freehand'
                                  }
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.IconStyles(theme)[
                                      'right link icon'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </Touchable>
                          )}
                        </>
                        {/* option_order */}
                        <>
                          {!fetchData?.allow_order ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('CaseOrderScreen', {
                                    case_id: fetchData?.case?.id,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { marginTop: 32 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'order'}
                                </Text>
                                <Icon
                                  size={24}
                                  {...GlobalStyles.IconStyles(theme)[
                                    'right link icon'
                                  ].props}
                                  color={theme.colors['Primary']}
                                  name={'Ionicons/document-text-outline'}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.IconStyles(theme)[
                                      'right link icon'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </Touchable>
                          )}
                        </>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'Tag - Grey - Text'
                              ].style,
                              { marginTop: 32, textAlign: 'right' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'options re participant'}
                        </Text>
                        {/* view profile */}
                        <>
                          {!fetchData?.allow_profile ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('UserProfileScreen', {
                                    user_id: fetchData?.participant?.id,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Right Link Text'
                                      ].style,
                                      { opacity: 0.5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'profile'}
                                </Text>
                              </View>
                            </Touchable>
                          )}
                        </>
                        {/* view profile 2 */}
                        <>
                          {!fetchData?.allow_profile ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('UserCaseScreen', {
                                    user_id: fetchData?.participant?.id,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Right Link Text'
                                      ].style,
                                      { opacity: 0.5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'all cases'}
                                </Text>
                              </View>
                            </Touchable>
                          )}
                        </>
                        {/* add action 2 */}
                        <>
                          {!fetchData?.allow_add_action ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('ActionListScreen', {
                                    participant: fetchData?.participant,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Right Link Text'
                                      ].style,
                                      { opacity: 0.5 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'add action'}
                                </Text>
                              </View>
                            </Touchable>
                          )}
                        </>
                      </View>
                      {/* help */}
                      <>
                        {!Constants['user_details']?.admin ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 32 },
                              dimensions.width
                            )}
                          >
                            {/* admin */}
                            <>
                              {!(fetchData?.case?.program_id === 4) ? null : (
                                <View>
                                  {/* Text 2 */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.waiting_for}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
                {/* Court View */}
                <>
                  {!fetchData?.allow_court_view ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { backgroundColor: theme.colors['Background'] },
                        dimensions.width
                      )}
                    >
                      {/* participant */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'stretch',
                            flexDirection: 'column',
                            justifyContent: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-start',
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'space-between',
                              },
                            ],
                            marginTop: 32,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'participant'}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            { alignSelf: 'stretch' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Assistant_400Regular',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              Constants['selected_case']?.participant
                                ?.name_first
                            }{' '}
                            {
                              Constants['selected_case']?.participant
                                ?.name_middle
                            }{' '}
                            {Constants['selected_case']?.participant?.name_last}
                          </Text>

                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Assistant_400Regular',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              Constants['selected_case']?.participant
                                ?.date_of_birth
                            }
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: theme.colors.strong,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Medium'],
                                    },
                                  ],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Assistant_700Bold',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Assistant_700Bold',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 8 },
                                    { minWidth: Breakpoints.Laptop, value: 8 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {'  dob '}
                            </Text>
                          </Text>

                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Assistant_400Regular',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {Constants['selected_case']?.participant?.state_id}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: theme.colors.strong,
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: theme.colors['Medium'],
                                    },
                                  ],
                                  fontFamily: 'Assistant_700Bold',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 8 },
                                    { minWidth: Breakpoints.Laptop, value: 8 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {' state id'}
                            </Text>
                          </Text>
                        </View>
                      </View>
                      {/* background check */}
                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Small Header']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Small Header']
                                .style,
                              { marginTop: 32 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'add/edit background check below'}
                        </Text>
                        <TextInput
                          changeTextDelay={500}
                          multiline={true}
                          onChangeText={newTextAreaValue => {
                            try {
                              setInputBG(newTextAreaValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                          }
                          textAlignVertical={'top'}
                          webShowOutline={true}
                          {...GlobalStyles.TextInputStyles(theme)['Input']
                            .props}
                          autoFocus={false}
                          numberOfLines={20}
                          placeholder={'add background check here...'}
                          placeholderTextColor={theme.colors['Medium']}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextInputStyles(theme)['Input'].style,
                            dimensions.width
                          )}
                          value={inputBG}
                        />
                        {/* Button Background */}
                        <Button
                          onPress={() => {
                            const handler = async () => {
                              try {
                                (
                                  await XanoApi.caseBackgroundPATCH(Constants, {
                                    background: inputBG,
                                    case_id: fetchData?.case?.id,
                                  })
                                )?.json;
                                navigation.goBack();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              { marginTop: 32 }
                            ),
                            dimensions.width
                          )}
                          title={'update background check'}
                        />
                      </View>
                    </View>
                  )}
                </>
              </KeyboardAwareScrollView>
              <Divider
                {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                color={'rgba(0, 0, 0, 0)'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  dimensions.width
                )}
              />
            </>
          );
        }}
      </XanoApi.FetchCaseGetOneGET>
    </ScreenContainer>
  );
};

export default withTheme(CaseDetailsScreen);
