import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const CheckinListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [category, setCategory] = React.useState('housing');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 5'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={'rgba(0, 0, 0, 0)'}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'requests for verification'}
        </Text>
        <>
          {!(props.route?.params?.participant ?? '') ? null : (
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors['Medium'],
                    fontFamily: 'Assistant_300Light',
                    fontSize: 14,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'for '}
              {(props.route?.params?.participant ?? '')?.name_first}{' '}
              {(props.route?.params?.participant ?? '')?.name_last}
            </Text>
          )}
        </>
      </View>
      {/* list */}
      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        contentContainerStyle={StyleSheet.applyWidth(
          { alignContent: 'stretch', alignSelf: 'stretch', margin: 32 },
          dimensions.width
        )}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <XanoApi.FetchCheckinListGET>
          {({ loading, error, data, refetchCheckinList }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <FlatList
                data={fetchData}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ?? listData?.uuid ?? index.toString()
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'gXPKGEX2'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <>
                      {/* horizontal touch */}
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('CheckinEditScreen', {
                              checkin_id: listData?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 32 },
                          dimensions.width
                        )}
                      >
                        {/* new standard list view */}
                        <View
                          {...GlobalStyles.ViewStyles(theme)['horizontal']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)['horizontal'].style,
                            dimensions.width
                          )}
                        >
                          {/* left */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginRight: 8 },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={theme.colors['Primary']}
                              name={'Feather/user'}
                              size={18}
                              style={StyleSheet.applyWidth(
                                { opacity: 0.5 },
                                dimensions.width
                              )}
                            />
                          </View>
                          {/* center */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['List Bold']
                                  .props}
                                numberOfLines={2}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['List Bold']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {listData?.name_first} {listData?.name_middle}{' '}
                                {listData?.name_last}
                              </Text>
                              {/* address */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Tag - Grey - Text'
                                ].props}
                                numberOfLines={2}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'Tag - Grey - Text'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {listData?.action}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </Touchable>
                    </>
                  );
                }}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flex: 1 },
                  dimensions.width
                )}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
              />
            );
          }}
        </XanoApi.FetchCheckinListGET>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(CheckinListScreen);
