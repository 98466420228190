import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { Text } from 'react-native';

const BlankScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <Text
        accessible={true}
        {...GlobalStyles.TextStyles(theme)['Print Header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.TextStyles(theme)['Print Header'].style,
          dimensions.width
        )}
      >
        {'Double click me to edit 👀'}
      </Text>
    </ScreenContainer>
  );
};

export default withTheme(BlankScreen);
