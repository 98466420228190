import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors['Primary'],
        borderRadius: 4,
        color: theme.colors['Background'],
        fontFamily: 'Assistant_700Bold',
        fontSize: 16,
        marginTop: 16,
        padding: 16,
        textAlign: 'center',
        textDecorationLine: 'none',
      },
      props: {},
    },
    'Button Outline': {
      style: {
        backgroundColor: theme.colors['Background'],
        borderColor: theme.colors['Primary'],
        borderRadius: 4,
        borderWidth: 2,
        color: theme.colors['Primary'],
        fontFamily: 'Assistant_700Bold',
        fontSize: 16,
        padding: 16,
        textAlign: 'center',
      },
      props: {},
    },
  });

export const TouchableStyles = theme =>
  StyleSheet.create({
    'case list view': { style: {}, props: {} },
    'icon touchable': { style: { marginBottom: 8 }, props: {} },
    'list touchable details': { style: { marginBottom: 12 }, props: {} },
    'touchable icon right': { style: { marginTop: 32 }, props: {} },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    'Big Header': {
      style: {
        color: theme.colors['Dark'],
        fontFamily: 'Assistant_800ExtraBold',
        fontSize: 32,
        textAlign: 'left',
      },
      props: {},
    },
    'Big Header - Text': {
      style: {
        color: theme.colors.strong,
        fontFamily: 'Assistant_400Regular',
        fontSize: 18,
        textAlign: 'auto',
      },
      props: {},
    },
    Caption: {
      style: {
        color: theme.colors.strong,
        fontFamily: 'Assistant_400Regular',
        fontSize: 14,
        textAlign: 'center',
      },
      props: {},
    },
    'Header - Page': {
      style: {
        color: theme.colors['Dark'],
        fontFamily: 'Assistant_800ExtraBold',
        fontSize: 18,
        opacity: 1,
        textAlign: 'auto',
      },
      props: {},
    },
    'List Bold': {
      style: {
        alignSelf: 'auto',
        color: theme.colors['Strong'],
        fontFamily: 'Assistant_600SemiBold',
        fontSize: 16,
      },
      props: {},
    },
    'Print Body': {
      style: {
        fontFamily: 'Assistant_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Mobile, value: 12 },
        ],
      },
      props: {},
    },
    'Print Header': {
      style: {
        color: theme.colors.strong,
        fontFamily: 'Assistant_700Bold',
        fontSize: 10,
      },
      props: {},
    },
    'Print Header - Delete Later': {
      style: {
        color: theme.colors.strong,
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'Assistant_700Bold' },
          { minWidth: Breakpoints.Laptop, value: 'Assistant_700Bold' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 10 },
          { minWidth: Breakpoints.Laptop, value: 12 },
        ],
        marginBottom: { minWidth: Breakpoints.Laptop, value: 12 },
        marginTop: { minWidth: Breakpoints.Laptop, value: 24 },
        textAlign: 'auto',
      },
      props: {},
    },
    'Print Label': {
      style: {
        color: theme.colors.strong,
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'Assistant_700Bold' },
          { minWidth: Breakpoints.Laptop, value: 'Assistant_300Light' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 8 },
          { minWidth: Breakpoints.Laptop, value: 10 },
        ],
      },
      props: {},
    },
    'Print Tiny': {
      style: {
        fontFamily: 'Assistant_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Mobile, value: 8 },
        ],
      },
      props: {},
    },
    'Right Link Text': {
      style: {
        color: theme.colors['Primary'],
        fontFamily: 'Assistant_800ExtraBold',
        fontSize: 18,
        opacity: 0.75,
      },
      props: {},
    },
    'Small Header': {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: theme.colors['Strong'] },
          { minWidth: Breakpoints.Laptop, value: theme.colors['Medium'] },
        ],
        fontFamily: 'Assistant_700Bold',
        fontSize: 12,
        opacity: 1,
      },
      props: {},
    },
    'Small Header - Red': {
      style: {
        color: theme.colors['Primary'],
        fontFamily: 'Assistant_600SemiBold',
        fontSize: 12,
        opacity: 0.5,
      },
      props: {},
    },
    'Tag - Grey - Text': {
      style: {
        color: theme.colors['Medium'],
        fontFamily: 'Assistant_400Regular',
        fontSize: 12,
        opacity: 1,
      },
      props: {},
    },
    'Tag - Red': {
      style: {
        color: theme.colors['Primary'],
        fontFamily: 'Assistant_700Bold',
        fontSize: 12,
        opacity: 1,
        textAlign: 'auto',
        textTransform: 'uppercase',
      },
      props: {},
    },
    Text: {
      style: {
        color: theme.colors.strong,
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
      },
      props: {},
    },
    help: {
      style: {
        color: theme.colors['Community_Primary_Alt'],
        fontFamily: 'Assistant_500Medium',
        fontSize: 14,
        marginLeft: 8,
      },
      props: {},
    },
    quote: {
      style: { fontFamily: 'Caveat_600SemiBold', fontSize: 32 },
      props: {},
    },
  });

export const IconButtonStyles = theme =>
  StyleSheet.create({ 'Icon - List Button': { style: {}, props: {} } });

export const ViewStyles = theme =>
  StyleSheet.create({
    'List View': { style: {}, props: {} },
    'Tag - Gray': {
      style: {
        backgroundColor: theme.colors['Divider'],
        borderColor: theme.colors['Light'],
        borderRadius: 4,
        borderWidth: 1,
        marginRight: 4,
        marginTop: 2,
        padding: 2,
      },
      props: {},
    },
    'Tag - Grey - View': {
      style: {
        alignItems: [
          { minWidth: Breakpoints.Laptop, value: 'flex-start' },
          { minWidth: Breakpoints.Mobile, value: 'flex-start' },
        ],
        backgroundColor: theme.colors['Divider'],
        borderColor: theme.colors['Light'],
        borderRadius: 4,
        borderWidth: 1,
        flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
        justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
        marginRight: [
          { minWidth: Breakpoints.Laptop, value: 3 },
          { minWidth: Breakpoints.Mobile, value: 4 },
        ],
        marginTop: 2,
        padding: 2,
      },
      props: {},
    },
    'Tag - Red - View': {
      style: {
        alignItems: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Mobile, value: 'flex-start' },
        ],
        backgroundColor: [
          {
            minWidth: Breakpoints.Laptop,
            value: theme.colors['Primary-Faint'],
          },
          {
            minWidth: Breakpoints.Mobile,
            value: theme.colors['Primary-Faint'],
          },
        ],
        borderColor: [
          {
            minWidth: Breakpoints.Laptop,
            value: theme.colors['Primary-Faint'],
          },
          { minWidth: Breakpoints.Mobile, value: theme.colors['Primary'] },
        ],
        borderRadius: [
          { minWidth: Breakpoints.Laptop, value: 2 },
          { minWidth: Breakpoints.Mobile, value: 4 },
        ],
        borderWidth: [
          { minWidth: Breakpoints.Laptop, value: 1 },
          { minWidth: Breakpoints.Mobile, value: 1 },
        ],
        flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
        justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
        marginRight: [
          { minWidth: Breakpoints.Laptop, value: 3 },
          { minWidth: Breakpoints.Mobile, value: 4 },
        ],
        padding: 2,
      },
      props: {},
    },
    TitleBlock: { style: { alignItems: 'center', marginTop: 64 }, props: {} },
    datepicker: {
      style: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 390,
      },
      props: {},
    },
    edit: { style: { marginTop: 32 }, props: {} },
    header: {
      style: { backgroundColor: theme.colors['Divider'], padding: 32 },
      props: {},
    },
    horizontal: {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors['Background Input'],
        borderColor: theme.colors['Divider'],
        borderRadius: 4,
        borderWidth: 1,
        flexDirection: 'row',
        padding: 16,
      },
      props: {},
    },
    nav: {
      style: { flexDirection: 'row', justifyContent: 'space-between' },
      props: {},
    },
    'nav 2': {
      style: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
      },
      props: {},
    },
    'nav 3': {
      style: { flexDirection: 'row', justifyContent: 'space-between' },
      props: {},
    },
    'nav 5': {
      style: { flexDirection: 'row', justifyContent: 'space-between' },
      props: {},
    },
    'new standard list view 4': {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
      },
      props: {},
    },
    options: {
      style: { alignContent: 'stretch', alignSelf: 'stretch', paddingTop: 16 },
      props: {},
    },
    'options 2': {
      style: { alignContent: 'stretch', alignSelf: 'stretch' },
      props: {},
    },
    'right red button': { style: {}, props: {} },
    tags: {
      style: {
        alignItems: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Mobile, value: 'flex-start' },
        ],
        flexDirection: [
          { minWidth: Breakpoints.Laptop, value: 'row' },
          { minWidth: Breakpoints.Mobile, value: 'row' },
        ],
        justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
      },
      props: {},
    },
  });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({ 'Deck Swiper': { style: { width: '100%' }, props: {} } });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: theme.colors['Background Input'],
        borderColor: theme.colors['Light'],
        borderRadius: 4,
        borderWidth: 1,
        flex: 1,
        justifyContent: 'center',
        maxHeight: 275,
        maxWidth: 275,
        minHeight: 275,
        minWidth: 275,
        opacity: 1,
        padding: 16,
      },
      props: {},
    },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: {
      style: {
        color: theme.colors.primary,
        fontFamily: 'Assistant_600SemiBold',
        fontSize: 12,
        opacity: 0.5,
      },
      props: {},
    },
    'Link - Big - Red': {
      style: {
        color: theme.colors.primary,
        fontFamily: 'Assistant_800ExtraBold',
        fontSize: 18,
        opacity: 0.75,
        textAlign: 'right',
      },
      props: {},
    },
  });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    'Switch Row': {
      style: {
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
        marginBottom: 4,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 4,
      },
      props: {},
    },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const ScreenContainerStyles = theme =>
  StyleSheet.create({
    'Text 2': { style: { flexDirection: 'column' }, props: {} },
  });

export const MarkdownStyles = theme =>
  StyleSheet.create({
    'Text 3': {
      style: {
        color: theme.colors['Strong'],
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
      },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    Input: {
      style: {
        backgroundColor: theme.colors['Background Input'],
        borderBottomWidth: 1,
        borderColor: theme.colors['Light'],
        borderLeftWidth: 1,
        borderRadius: 4,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: theme.colors['Strong'],
        fontFamily: 'Assistant_500Medium',
        fontSize: 16,
        marginBottom: 16,
        marginTop: 0,
        opacity: 1,
        padding: 16,
        textAlign: 'left',
      },
      props: {},
    },
    'Text Area': {
      style: {
        backgroundColor: theme.colors['Background Input'],
        borderColor: theme.colors['Divider'],
        borderRadius: 4,
        borderWidth: 1,
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.divider,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
      },
      props: {},
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { style: { flex: 1 }, props: {} } });

export const YoutubePlayerStyles = theme =>
  StyleSheet.create({ 'Youtube Player': { style: {}, props: {} } });

export const FlatListStyles = theme =>
  StyleSheet.create({ list: { style: { flex: 1 }, props: {} } });

export const PickerStyles = theme =>
  StyleSheet.create({
    picker: {
      style: {
        backgroundColor: theme.colors['Background Input'],
        borderColor: theme.colors['Light'],
        borderRadius: 4,
        color: theme.colors['Strong'],
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
        marginBottom: 16,
      },
      props: {},
    },
  });

export const IconStyles = theme =>
  StyleSheet.create({
    'help 2': { style: { backgroundColor: 'rgba(0, 0, 0, 0)' }, props: {} },
    'right link icon': { style: { marginLeft: 8 }, props: {} },
  });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      style: {
        backgroundColor: theme.colors['Background Input'],
        borderBottomWidth: 1,
        borderColor: theme.colors['Light'],
        borderLeftWidth: 1,
        borderRadius: 4,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: theme.colors['Strong'],
        fontFamily: 'Assistant_400Regular',
        fontSize: 16,
        marginBottom: 16,
        padding: 16,
      },
      props: {},
    },
  });

export const PinInputStyles = theme =>
  StyleSheet.create({
    'Pin Input': {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors['Background Input'],
        borderColor: theme.colors['Light'],
        borderRadius: 4,
        borderWidth: 1,
        color: theme.colors.strong,
        flex: 1,
        fontFamily: 'Assistant_400Regular',
        fontSize: 24,
        justifyContent: 'center',
        marginLeft: 8,
        marginRight: 8,
        maxHeight: 70,
        maxWidth: 70,
        padding: 8,
      },
      props: {},
    },
  });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { style: {}, props: {} } });

export const AccordionGroupStyles = theme =>
  StyleSheet.create({ Accordion: { style: { fontSize: 16 }, props: {} } });

export const SVGStyles = theme =>
  StyleSheet.create({ SVG: { style: { height: 100, width: 100 }, props: {} } });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { style: { height: 32 }, props: {} } });

export const BottomSheetStyles = theme =>
  StyleSheet.create({
    'Bottom Sheet': {
      style: {
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
      },
      props: {},
    },
  });
