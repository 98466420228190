import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import listContains from '../global-functions/listContains';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ProgramListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [category, setCategory] = React.useState('housing');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const loc = await getLocationUtil();
        setGlobalVariableValue({
          key: 'user_location',
          value: loc,
        });
        console.log(loc);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchProgramListGET
        handlers={{
          onData: fetchData => {
            try {
              console.log(fetchData);
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchProgramList }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 5'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'\ncourt programs'}
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { padding: 32 },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                <View>
                  <FlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'o87jJlCZ'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* touchable box */}
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('ProgramDetailScreen', {
                                  program_id: listData?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              { marginTop: 8 },
                              dimensions.width
                            )}
                          >
                            <View>
                              {/* new standard list view */}
                              <View
                                {...GlobalStyles.ViewStyles(theme)['horizontal']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ViewStyles(theme)['horizontal']
                                      .style,
                                    {
                                      flexGrow: 1,
                                      justifyContent: 'space-evenly',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* type of org icon */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 8 },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={theme.colors['Primary']}
                                    name={
                                      'MaterialCommunityIcons/scale-balance'
                                    }
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { opacity: 0.5 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* center */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'stretch',
                                      flex: 1,
                                      flexGrow: 1,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Red'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.name}
                                    </Text>

                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'List Bold'
                                      ].props}
                                      numberOfLines={1}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'List Bold'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.full_name}
                                    </Text>
                                    {/* secondary */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].props}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.description}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          </Touchable>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1 },
                      dimensions.width
                    )}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </KeyboardAwareScrollView>

              <View>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      { height: 64 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
            </>
          );
        }}
      </XanoApi.FetchProgramListGET>
    </ScreenContainer>
  );
};

export default withTheme(ProgramListScreen);
