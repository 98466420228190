import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import makeDate from '../global-functions/makeDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  Link,
  NumberInput,
  Picker,
  ScreenContainer,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const IntakeInfoScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [errorMessage, setErrorMessage] = React.useState('');
  const [inputAdmin, setInputAdmin] = React.useState(false);
  const [inputAdults, setInputAdults] = React.useState('');
  const [inputAlt, setInputAlt] = React.useState('');
  const [inputBar, setInputBar] = React.useState('');
  const [inputBenefits, setInputBenefits] = React.useState('');
  const [inputCause, setInputCause] = React.useState('');
  const [inputChildren, setInputChildren] = React.useState('');
  const [inputCity, setInputCity] = React.useState('');
  const [inputDOB, setInputDOB] = React.useState('');
  const [inputDay, setInputDay] = React.useState('');
  const [inputDisability, setInputDisability] = React.useState('');
  const [inputEDU, setInputEDU] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [inputEntryDate, setInputEntryDate] = React.useState('');
  const [inputEntryDay, setInputEntryDay] = React.useState('');
  const [inputEntryMonth, setInputEntryMonth] = React.useState('');
  const [inputEntryYear, setInputEntryYear] = React.useState('');
  const [inputFirst, setInputFirst] = React.useState('');
  const [inputGender, setInputGender] = React.useState('');
  const [inputHousing, setInputHousing] = React.useState('');
  const [inputIncome, setInputIncome] = React.useState('');
  const [inputLast, setInputLast] = React.useState('');
  const [inputLead, setInputLead] = React.useState(false);
  const [inputMiddle, setInputMiddle] = React.useState('');
  const [inputMonth, setInputMonth] = React.useState('');
  const [inputRace, setInputRace] = React.useState('');
  const [inputRole, setInputRole] = React.useState('');
  const [inputSaved, setInputSaved] = React.useState('');
  const [inputSavings, setInputSavings] = React.useState('');
  const [inputSource, setInputSource] = React.useState('');
  const [inputStateID, setInputStateID] = React.useState('');
  const [inputStatus, setInputStatus] = React.useState('');
  const [inputStreet, setInputStreet] = React.useState('');
  const [inputTEL, setInputTEL] = React.useState('');
  const [inputVet, setInputVet] = React.useState('');
  const [inputVision, setInputVision] = React.useState('');
  const [inputYear, setInputYear] = React.useState('');
  const [inputZIP, setInputZIP] = React.useState('');
  const [nextStage, setNextStage] = React.useState('');
  const [pickerState, setPickerState] = React.useState('');
  const [pickerWorksFor, setPickerWorksFor] = React.useState('');
  const [switchPrivacy, setSwitchPrivacy] = React.useState(false);
  const xanoUserPatchPATCH = XanoApi.useUserPatchPATCH();

  return (
    <ScreenContainer
      scrollable={false}
      {...GlobalStyles.ScreenContainerStyles(theme)['Text 2'].props}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        GlobalStyles.ScreenContainerStyles(theme)['Text 2'].style,
        dimensions.width
      )}
    >
      <XanoApi.FetchUserGetGET user_id={637}>
        {({ loading, error, data, refetchUserGet }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Divider'], padding: 32 },
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                ></View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'\ntell us about yourself'}
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    borderRadius: 4,
                    flexBasis: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingTop: 32,
                  },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* id */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                        { fontSize: 24 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.id}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.id_text}
                  </Text>
                  {/* first */}
                  <View>
                    {/* input */}
                    <TextInput
                      changeTextDelay={500}
                      onChangeText={newInputValue => {
                        try {
                          setInputFirst(newInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      autoComplete={'given-name'}
                      autoFocus={false}
                      multiline={false}
                      numberOfLines={10}
                      placeholder={'first name'}
                      placeholderTextColor={theme.colors['Medium']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Input'].style,
                        dimensions.width
                      )}
                      value={inputFirst}
                    />
                  </View>
                  {/* middle */}
                  <View>
                    {/* input */}
                    <TextInput
                      changeTextDelay={500}
                      onChangeText={newInputValue => {
                        try {
                          setInputMiddle(newInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      autoComplete={'additional-name'}
                      autoFocus={false}
                      multiline={false}
                      numberOfLines={10}
                      placeholder={'middle name, full please _/\\_'}
                      placeholderTextColor={theme.colors['Medium']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Input'].style,
                        dimensions.width
                      )}
                      value={inputMiddle}
                    />
                  </View>
                  {/* last */}
                  <View>
                    {/* input */}
                    <TextInput
                      changeTextDelay={500}
                      onChangeText={newInputValue => {
                        try {
                          setInputLast(newInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      autoComplete={'family-name'}
                      autoFocus={false}
                      multiline={false}
                      numberOfLines={10}
                      placeholder={'last name'}
                      placeholderTextColor={theme.colors['Medium']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Input'].style,
                        dimensions.width
                      )}
                      value={inputLast}
                    />
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Small Header'].style,
                      dimensions.width
                    )}
                  >
                    {'date of birth'}
                  </Text>
                  {/* datepicker */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['datepicker'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['datepicker'].style,
                      dimensions.width
                    )}
                  >
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputMonth(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Medium']}
                      label={'month'}
                      options={Constants['month']}
                      placeholder={'month'}
                      placeholderTextColor={theme.colors['Strong']}
                      rightIconName={'Entypo/chevron-down'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          { marginTop: 0, width: 95 }
                        ),
                        dimensions.width
                      )}
                      value={inputMonth}
                    />
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputDay(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Medium']}
                      label={'day'}
                      options={Constants['day']}
                      placeholder={'day'}
                      placeholderTextColor={theme.colors['Strong']}
                      rightIconName={'Entypo/chevron-down'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          { width: 95 }
                        ),
                        dimensions.width
                      )}
                      value={inputDay}
                    />
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputYear(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Medium']}
                      label={'year'}
                      options={Constants['year']}
                      placeholder={'year'}
                      placeholderTextColor={theme.colors['Strong']}
                      rightIconName={'Entypo/chevron-down'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          { width: 110 }
                        ),
                        dimensions.width
                      )}
                      value={inputYear}
                    />
                  </View>
                  {/* state id */}
                  <View>
                    {/* input */}
                    <TextInput
                      changeTextDelay={500}
                      onChangeText={newInputValue => {
                        try {
                          setInputStateID(newInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      autoFocus={false}
                      multiline={false}
                      numberOfLines={10}
                      placeholder={'state id'}
                      placeholderTextColor={theme.colors['Medium']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)['Input'].style,
                        dimensions.width
                      )}
                      value={inputStateID}
                    />
                  </View>
                </View>
                {/* contact */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 64 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                        { fontSize: 24 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.contact}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.contact_text}
                  </Text>
                  {/* street */}
                  <TextInput
                    changeTextDelay={500}
                    onChangeText={newStreetValue => {
                      try {
                        setInputStreet(newStreetValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                    }
                    textAlignVertical={'top'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                    autoComplete={'street-address'}
                    autoFocus={false}
                    multiline={false}
                    numberOfLines={1}
                    placeholder={'street address, eg 1234 Main Street'}
                    placeholderTextColor={theme.colors['Medium']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      dimensions.width
                    )}
                    value={inputStreet}
                  />
                  {/* city */}
                  <TextInput
                    changeTextDelay={500}
                    onChangeText={newCityValue => {
                      try {
                        setInputCity(newCityValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                    }
                    textAlignVertical={'top'}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                    autoFocus={false}
                    multiline={false}
                    numberOfLines={1}
                    placeholder={'city'}
                    placeholderTextColor={theme.colors['Medium']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      dimensions.width
                    )}
                    value={inputCity}
                  />
                  {/* state */}
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background}
                    dropDownBorderColor={theme.colors.divider}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newStateValue => {
                      try {
                        setPickerState(newStateValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    placeholder={'Select an option'}
                    selectedIconColor={theme.colors.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)['picker'].props}
                    iconColor={theme.colors['Primary']}
                    label={'state'}
                    leftIconName={'Feather/map-pin'}
                    options={Constants['state']}
                    rightIconName={'Ionicons/chevron-down'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)['picker'].style,
                      dimensions.width
                    )}
                    value={pickerState}
                  />
                  {/* zip */}
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newZipValue => {
                      try {
                        setInputZIP(newZipValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                      .props}
                    autoComplete={'postal-code'}
                    maxLength={5}
                    placeholder={'zip code'}
                    placeholderTextColor={theme.colors['Medium']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.NumberInputStyles(theme)['Number Input']
                        .style,
                      dimensions.width
                    )}
                    value={inputZIP}
                  />
                  {/* tel */}
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newTelValue => {
                      try {
                        setInputTEL(newTelValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                      .props}
                    autoComplete={'tel'}
                    placeholder={'telephone number, just the 10 digits'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.NumberInputStyles(theme)['Number Input']
                        .style,
                      dimensions.width
                    )}
                    value={inputTEL}
                  />
                  {/* alt */}
                  <NumberInput
                    changeTextDelay={500}
                    onChangeText={newAltValue => {
                      try {
                        setInputAlt(newAltValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.NumberInputStyles(theme)['Number Input']
                      .props}
                    autoComplete={'tel'}
                    placeholder={'backup number, just in case'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.NumberInputStyles(theme)['Number Input']
                        .style,
                      dimensions.width
                    )}
                    value={inputAlt}
                  />
                </View>
                {/* demo */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 64 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                        { fontSize: 24 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.demo}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.demo_text}
                  </Text>
                  {/* gender */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputGender(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'gender'}
                      leftIconName={'Ionicons/ios-transgender-outline'}
                      options={Constants['gender']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputGender}
                    />
                  </View>
                  {/* race */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputRace(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'race'}
                      leftIconName={'Ionicons/earth-outline'}
                      options={Constants['race']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputRace}
                    />
                  </View>
                  {/* disability */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputDisability(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'disability'}
                      leftIconName={
                        'MaterialCommunityIcons/wheelchair-accessibility'
                      }
                      options={Constants['disability']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputDisability}
                    />
                  </View>
                  {/* vet */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputVet(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'veteran status'}
                      leftIconName={'Feather/flag'}
                      options={Constants['vet']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputVet}
                    />
                  </View>
                  {/* edu */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputEDU(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'highest level of education'}
                      leftIconName={'SimpleLineIcons/graduation'}
                      options={Constants['edu']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputEDU}
                    />
                  </View>
                </View>
                {/* entry */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 64 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Right Link Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Right Link Text'].style,
                        { fontSize: 24 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.entry}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.entry_text}
                  </Text>
                  {/* causes */}
                  <>
                    {!(inputHousing !== 'secure') ? null : (
                      <View>
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={theme.colors.background}
                          dropDownBorderColor={theme.colors.divider}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          mode={'native'}
                          onValueChange={newPickerValue => {
                            try {
                              setInputCause(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Select an option'}
                          selectedIconColor={theme.colors.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.PickerStyles(theme)['picker'].props}
                          iconColor={theme.colors['Primary']}
                          label={'main cause of instability'}
                          leftIconName={'AntDesign/warning'}
                          options={Constants['entry_causes']}
                          rightIconName={'Ionicons/chevron-down'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.PickerStyles(theme)['picker'].style,
                            dimensions.width
                          )}
                          value={inputCause}
                        />
                      </View>
                    )}
                  </>
                  {/* housing */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputHousing(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'where are you sleeping most nights?'}
                      leftIconName={'Feather/home'}
                      options={Constants['entry_housing']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputHousing}
                    />
                  </View>
                  {/* hh_adults */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputAdults(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'live with a partner? change this to "2"'}
                      leftIconName={'Feather/user-plus'}
                      options={Constants['entry_adults']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputAdults}
                    />
                  </View>
                  {/* hh_children */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputChildren(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'how many children are in the household?'}
                      leftIconName={'Feather/users'}
                      options={Constants['entry_children']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputChildren}
                    />
                  </View>
                  {/* benefits */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputBenefits(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'you getting SNAP? Medicaid? Etc...?'}
                      leftIconName={'Feather/shield'}
                      options={Constants['entry_benefits']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputBenefits}
                    />
                  </View>
                  {/* source */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputSource(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'main source of income'}
                      leftIconName={'Feather/trending-up'}
                      options={Constants['entry_source']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputSource}
                    />
                  </View>
                  {/* income */}
                  <View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setInputIncome(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'monthly cash income'}
                      leftIconName={'Feather/dollar-sign'}
                      options={Constants['entry_income']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={inputIncome}
                    />
                  </View>
                </View>

                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Primary'],
                      fontFamily: 'Assistant_700Bold',
                      fontSize: 16,
                      marginTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
                <>
                  {!inputTEL ? null : (
                    <View>
                      <>
                        {!inputFirst ? null : (
                          <View>
                            {/* View 2 */}
                            <>
                              {!inputLast ? null : (
                                <View>
                                  {/* View 3 */}
                                  <>
                                    {!inputDay ? null : (
                                      <View>
                                        {/* View 4 */}
                                        <>
                                          {!inputMonth ? null : (
                                            <View>
                                              {/* View 5 */}
                                              <>
                                                {!inputYear ? null : (
                                                  <View>
                                                    {/* View 6 */}
                                                    <>
                                                      {!inputAdults ? null : (
                                                        <View>
                                                          {/* View 7 */}
                                                          <>
                                                            {!inputChildren ? null : (
                                                              <View>
                                                                {/* View 8 */}
                                                                <View>
                                                                  <>
                                                                    {!inputBenefits ? null : (
                                                                      <View>
                                                                        {/* View 2 */}
                                                                        <>
                                                                          {!inputIncome ? null : (
                                                                            <View>
                                                                              {/* View 3 */}
                                                                              <>
                                                                                {!inputSource ? null : (
                                                                                  <View>
                                                                                    {/* View 4 */}
                                                                                    <View>
                                                                                      {/* View 5 */}
                                                                                      <View>
                                                                                        {/* View 6 */}
                                                                                        <View>
                                                                                          {/* View 7 */}
                                                                                          <View>
                                                                                            {/* Button Note */}
                                                                                            <Button
                                                                                              onPress={() => {
                                                                                                const handler =
                                                                                                  async () => {
                                                                                                    try {
                                                                                                      const functionDate =
                                                                                                        makeDate(
                                                                                                          Variables,
                                                                                                          inputDay,
                                                                                                          inputMonth,
                                                                                                          inputYear
                                                                                                        );
                                                                                                      const postResponse =
                                                                                                        (
                                                                                                          await xanoUserPatchPATCH.mutateAsync(
                                                                                                            {
                                                                                                              admin:
                                                                                                                inputAdmin,
                                                                                                              alt: inputAlt,
                                                                                                              bar: inputBar,
                                                                                                              city: inputCity,
                                                                                                              disability:
                                                                                                                inputDisability,
                                                                                                              dob: functionDate,
                                                                                                              edu: inputEDU,
                                                                                                              email:
                                                                                                                Constants[
                                                                                                                  'user_details'
                                                                                                                ]
                                                                                                                  ?.email,
                                                                                                              entry_adult:
                                                                                                                inputAdults,
                                                                                                              entry_benefits:
                                                                                                                inputBenefits,
                                                                                                              entry_cause:
                                                                                                                inputCause,
                                                                                                              entry_child:
                                                                                                                inputChildren,
                                                                                                              entry_disposable:
                                                                                                                inputSavings,
                                                                                                              entry_housing:
                                                                                                                inputHousing,
                                                                                                              entry_income:
                                                                                                                inputIncome,
                                                                                                              entry_saved:
                                                                                                                inputSaved,
                                                                                                              entry_source:
                                                                                                                inputSource,
                                                                                                              first:
                                                                                                                inputFirst,
                                                                                                              last: inputLast,
                                                                                                              lead: inputLead,
                                                                                                              middle:
                                                                                                                inputMiddle,
                                                                                                              privacy:
                                                                                                                switchPrivacy,
                                                                                                              race: inputRace,
                                                                                                              role: 'participant',
                                                                                                              sex: inputGender,
                                                                                                              state:
                                                                                                                pickerState,
                                                                                                              state_id:
                                                                                                                inputStateID,
                                                                                                              status:
                                                                                                                inputStatus,
                                                                                                              street:
                                                                                                                inputStreet,
                                                                                                              tel: inputTEL,
                                                                                                              user_id:
                                                                                                                Constants[
                                                                                                                  'user_details'
                                                                                                                ]
                                                                                                                  ?.id,
                                                                                                              vet: inputVet,
                                                                                                              vision:
                                                                                                                inputVision,
                                                                                                              works_for:
                                                                                                                pickerWorksFor,
                                                                                                              zip: inputZIP,
                                                                                                            }
                                                                                                          )
                                                                                                        )
                                                                                                          ?.json;
                                                                                                      setErrorMessage(
                                                                                                        postResponse?.message
                                                                                                      );
                                                                                                      if (
                                                                                                        postResponse?.message ===
                                                                                                        'success'
                                                                                                      ) {
                                                                                                        navigation.navigate(
                                                                                                          'IntakeVisionScreen',
                                                                                                          {
                                                                                                            user_id:
                                                                                                              Constants[
                                                                                                                'user_details'
                                                                                                              ]
                                                                                                                ?.id,
                                                                                                          }
                                                                                                        );
                                                                                                      }
                                                                                                    } catch (err) {
                                                                                                      console.error(
                                                                                                        err
                                                                                                      );
                                                                                                    }
                                                                                                  };
                                                                                                handler();
                                                                                              }}
                                                                                              {...GlobalStyles.ButtonStyles(
                                                                                                theme
                                                                                              )[
                                                                                                'Button'
                                                                                              ]
                                                                                                .props}
                                                                                              style={StyleSheet.applyWidth(
                                                                                                StyleSheet.compose(
                                                                                                  GlobalStyles.ButtonStyles(
                                                                                                    theme
                                                                                                  )[
                                                                                                    'Button'
                                                                                                  ]
                                                                                                    .style,
                                                                                                  {
                                                                                                    marginBottom: 32,
                                                                                                    marginTop: 32,
                                                                                                  }
                                                                                                ),
                                                                                                dimensions.width
                                                                                              )}
                                                                                              title={
                                                                                                "that was easy... what's next?"
                                                                                              }
                                                                                            />
                                                                                          </View>
                                                                                        </View>
                                                                                      </View>
                                                                                    </View>
                                                                                  </View>
                                                                                )}
                                                                              </>
                                                                            </View>
                                                                          )}
                                                                        </>
                                                                      </View>
                                                                    )}
                                                                  </>
                                                                </View>
                                                              </View>
                                                            )}
                                                          </>
                                                        </View>
                                                      )}
                                                    </>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchUserGetGET>
    </ScreenContainer>
  );
};

export default withTheme(IntakeInfoScreen);
