import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const FAQListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [category, setCategory] = React.useState('all');
  const [fetch_list, setFetch_list] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      undefined;
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchFAQListGET
        handlers={{
          onData: fetchData => {
            try {
              setFetch_list(fetchData?.resources);
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchFAQList }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 5'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {"\nfaq's"}
                </Text>
                <>
                  {!(props.route?.params?.participant ?? '') ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors['Medium'],
                            fontFamily: 'Assistant_300Light',
                            fontSize: 14,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'request verification for:\n'}
                      {
                        (props.route?.params?.participant ?? '')?.name_first
                      }{' '}
                      {(props.route?.params?.participant ?? '')?.name_last}
                      {'\n'}
                      {(props.route?.params?.checkin ?? '')?.action}
                      {' - '}
                      {(props.route?.params?.checkin ?? '')?.id}
                      {'\n'}
                    </Text>
                  )}
                </>
                {/* options */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'stretch',
                      alignItems: 'flex-start',
                      alignSelf: 'stretch',
                      flexDirection: 'column',
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'stretch',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                      },
                      dimensions.width
                    )}
                  >
                    <FlatList
                      data={fetchData?.faq_category?.[0]}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'Jyk8Kz6t'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignSelf: 'center' },
                              dimensions.width
                            )}
                          >
                            {/* options */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  borderColor: theme.colors['Light'],
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginRight: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* red */}
                              <>
                                {!(listData === category) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setCategory(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Red'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Tag - Red'
                                          ].style,
                                          {
                                            fontFamily:
                                              'Assistant_800ExtraBold',
                                            fontSize: 16,
                                            textTransform: 'none',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                              {/* black */}
                              <>
                                {!(listData !== category) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setCategory(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Light'],
                                          fontFamily: 'Assistant_700Bold',
                                          fontSize: 16,
                                          textAlign: 'auto',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                            </View>
                          </View>
                        );
                      }}
                      {...GlobalStyles.FlatListStyles(theme)['list'].props}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.FlatListStyles(theme)['list'].style,
                        dimensions.width,
                        ['width', 'height', 'flexGrow']
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.FlatListStyles(theme)['list'].style,
                          {
                            alignContent: 'stretch',
                            alignSelf: 'stretch',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexBasis: 1, padding: 32 },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* horizontal list */}
                <View>
                  <FlatList
                    data={fetchData?.faq}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'YWM0CnJB'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.role === category) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('FAQDetailScreen', {
                                      faq_id: listData?.id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        alignContent: 'stretch',
                                        alignItems: 'stretch',
                                        alignSelf: 'auto',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginBottom: 8,
                                        marginRight: 8,
                                        maxHeight: 80,
                                        minHeight: 80,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        alignSelf: 'auto',
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          justifyContent: 'center',
                                          marginRight: 8,
                                          marginTop: 2,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={theme.colors['Primary']}
                                        name={'AntDesign/questioncircleo'}
                                        size={18}
                                        style={StyleSheet.applyWidth(
                                          { opacity: 0.5 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'stretch',
                                          flex: 1,
                                          flexGrow: 1,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { justifyContent: 'center' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].props}
                                          numberOfLines={3}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.question}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'column', flexWrap: 'wrap' },
                      dimensions.width
                    )}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchFAQListGET>
    </ScreenContainer>
  );
};

export default withTheme(FAQListScreen);
