import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const OrgDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [verified, setVerified] = React.useState('verified');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchOrganizationDetailGET
        organization_id={props.route?.params?.OrganizationID ?? 12}
      >
        {({ loading, error, data, refetchOrganizationDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                <Divider
                  color={theme.colors.divider}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                      { textTransform: 'none' }
                    ),
                    dimensions.width
                  )}
                >
                  {fetchData?.organization?.category}
                </Text>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.organization?.entity}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'flex-start' },
                    dimensions.width
                  )}
                >
                  {/* address */}
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            `https://maps.google.com/?q=${fetchData?.organization?.address_street} ${fetchData?.organization?.address_city} ${fetchData?.organization?.address_st} ${fetchData?.organization?.address_zip}`
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.TouchableStyles(theme)['icon touchable']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['icon touchable']
                        .style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/map-outline'}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?.organization?.address_street}
                        {'\n'}
                        {fetchData?.organization?.address_city}
                        {', '}
                        {fetchData?.organization?.address_st}
                        {'  '}
                        {fetchData?.organization?.address_zip}
                      </Text>
                    </View>
                  </Touchable>
                  {/* tel */}
                  <Touchable
                    onPress={() => {
                      try {
                        Linking.openURL(
                          `tel:${fetchData?.organization?.telephone}`
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.TouchableStyles(theme)['icon touchable']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['icon touchable']
                        .style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialIcons/local-phone'}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {(fetchData?.organization?.telephone).slice(0, 3)}
                        {'-'}
                        {(fetchData?.organization?.telephone).slice(3, 6)}
                        {'-'}
                        {(fetchData?.organization?.telephone).slice(6, 10)}
                      </Text>
                    </View>
                  </Touchable>
                  {/* web */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FileViewScreen', {
                          file_url: fetchData?.organization?.website,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.TouchableStyles(theme)['icon touchable']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)['icon touchable']
                        .style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/web'}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData?.organization?.website}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexBasis: 1, marginBottom: 32 },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: 32, paddingRight: 32, paddingTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'overview'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.organization?.description}
                  </Text>
                </View>
                {/* actions */}
                <>
                  {!(fetchData?.actions?.length >= 1) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 32, paddingRight: 32, paddingTop: 32 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {'they specialize in'}
                      </Text>
                      <FlatList
                        data={fetchData?.actions}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'PIVMd2dT'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* horizontal touch */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('ActionDetailsScreen', {
                                      action_id: listData?.action_id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                disabled={true}
                              >
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        alignItems: 'stretch',
                                        marginRight: 8,
                                        marginTop: 8,
                                        maxWidth: 275,
                                        minWidth: 275,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* left */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Primary']}
                                      name={
                                        'MaterialCommunityIcons/alert-decagram-outline'
                                      }
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.action}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1, flexDirection: 'row' },
                          dimensions.width
                        )}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  )}
                </>
                {/* programs */}
                <>
                  {!(fetchData?.programs?.length >= 1) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 32, paddingRight: 32, paddingTop: 32 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {'court programs'}
                      </Text>
                      <FlatList
                        data={fetchData?.programs}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'DbMrbnxI'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* horizontal touch */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('ProgramDetailScreen', {
                                      program_id: listData?.id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        marginRight: 8,
                                        marginTop: 8,
                                        maxWidth: 275,
                                        minWidth: 275,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* left */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Primary']}
                                      name={
                                        'MaterialCommunityIcons/scale-balance'
                                      }
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.full_name}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1, flexDirection: 'row' },
                          dimensions.width
                        )}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  )}
                </>
                {/* staff */}
                <>
                  {!(fetchData?.organization?.type !== 'justice') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 32, paddingRight: 32, paddingTop: 32 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Header - Page']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Header - Page']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'staff'}
                        </Text>
                      </View>
                      <FlatList
                        data={fetchData?.staff}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'pU7xzzYK'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* horizontal touch */}
                              <Touchable>
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        marginRight: 8,
                                        marginTop: 8,
                                        maxWidth: 275,
                                        minWidth: 275,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* left */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={theme.colors['Primary']}
                                      name={'Feather/user'}
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'stretch',
                                        flex: 1,
                                        flexGrow: 1,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name_first}{' '}
                                        {listData?.name_last}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* right */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* tel */}
                                    <IconButton
                                      onLongPress={() => {
                                        try {
                                          Linking.openURL(
                                            `sms:${listData?.tel}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      onPress={() => {
                                        try {
                                          Linking.openURL(
                                            `tel:${listData?.tel}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      icon={'MaterialIcons/phone'}
                                      size={24}
                                    />
                                    {/* email */}
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          Linking.openURL(
                                            `mailto:${listData?.email}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      icon={
                                        'MaterialCommunityIcons/email-plus-outline'
                                      }
                                      size={24}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 8 },
                                        dimensions.width
                                      )}
                                    />
                                    <>
                                      {!listData?.tel_alt ? null : (
                                        <View>
                                          {/* tel 2 */}
                                          <>
                                            {!(
                                              Constants['user_details']
                                                ?.role === 'defender'
                                            ) ? null : (
                                              <IconButton
                                                onLongPress={() => {
                                                  try {
                                                    Linking.openURL(
                                                      `sms:${listData?.tel_alt}`
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                onPress={() => {
                                                  try {
                                                    Linking.openURL(
                                                      `tel:${listData?.tel_alt}`
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                icon={
                                                  'MaterialCommunityIcons/phone-message-outline'
                                                }
                                                size={24}
                                                style={StyleSheet.applyWidth(
                                                  { marginLeft: 8 },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1, flexDirection: 'row' },
                          dimensions.width
                        )}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  )}
                </>
                {/* button */}
                <>
                  {!(props.route?.params?.checkin ?? '') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 32,
                          paddingLeft: 32,
                          paddingRight: 32,
                          paddingTop: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!(props.route?.params?.checkin ?? '') ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  (
                                    await XanoApi.checkinRequestPATCH(
                                      Constants,
                                      {
                                        checkin_id:
                                          props.route?.params?.checkin ?? '',
                                        organization_id:
                                          props.route?.params?.OrganizationID ??
                                          12,
                                      }
                                    )
                                  )?.json;
                                  navigation.navigate('UserProfileScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              dimensions.width
                            )}
                            title={`request verification for:
${(props.route?.params?.checkin ?? '')?.win}
`}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={theme.colors['Background']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      { height: 64 }
                    ),
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchOrganizationDetailGET>
    </ScreenContainer>
  );
};

export default withTheme(OrgDetailsScreen);
