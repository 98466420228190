import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ActionDetailsPublicScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const location = await getLocationUtil();
        setGlobalVariableValue({
          key: 'user_location',
          value: location,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
    >
      <XanoApi.FetchPublicActionSinglePOST
        action_id={props.route?.params?.action_id ?? 6}
        location={Constants['user_location']}
      >
        {({ loading, error, data, refetchPublicActionSingle }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* Title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'\n'}
                  {fetchData?.action?.action}
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { marginBottom: 8 }
                    ),
                    dimensions.width
                  )}
                >
                  {fetchData?.action?.advice}
                </Text>
                <>
                  {props.route?.params?.checkin ?? '' ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await XanoApi.checkinAddPOST(Constants, {
                                action_id: props.route?.params?.action_id ?? 6,
                                user_id: (
                                  props.route?.params?.participant ?? ''
                                )?.id,
                              })
                            )?.json;
                            navigation.navigate('UserProfileScreen', {
                              user_id: (props.route?.params?.participant ?? '')
                                ?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        dimensions.width
                      )}
                      title={'add to action plan'}
                    />
                  )}
                </>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexBasis: 1, padding: 32 },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* partners */}
                <>
                  {!(fetchData?.organizations?.length > 0) ? null : (
                    <View>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {fetchData && fetchData[1]}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Tag - Grey - Text'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'Tag - Grey - Text'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchData && fetchData['1_help']}
                          </Text>
                        </View>
                      </View>
                      <FlatList
                        data={fetchData?.organizations}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'EwoTZCE6'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* horizontal touch */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('OrgDetailsScreen', {
                                      OrganizationID: listData?.organization_id,
                                      participant:
                                        props.route?.params?.participant ?? '',
                                      checkin:
                                        props.route?.params?.checkin ?? '',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        marginRight: 8,
                                        marginTop: 8,
                                        maxWidth: 275,
                                        minWidth: 275,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* icon 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!(
                                        listData?.category === 'legal'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/scale-balance'
                                          }
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!(
                                        listData?.category === 'housing'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/shield-home-outline'
                                          }
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!(
                                        listData?.category === 'money'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Ionicons/ios-wallet-outline'}
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!(
                                        listData?.category === 'health'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/plus-outline'
                                          }
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!(
                                        listData?.category === 'connect'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/shield-link-variant-outline'
                                          }
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'stretch',
                                        flex: 1,
                                        flexGrow: 1,
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'List Bold'
                                        ].props}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.entity}
                                      </Text>
                                      {/* secondary */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].props}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.address_street}
                                        {', '}
                                        {listData?.address_city}
                                        {', '}
                                        {listData?.address_st}{' '}
                                        {listData?.address_zip}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1, flexDirection: 'row' },
                          dimensions.width
                        )}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  )}
                </>
                {/* resources */}
                <>
                  {!(fetchData?.resources?.length > 0) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {fetchData && fetchData[2]}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Tag - Grey - Text'
                            ].props}
                            numberOfLines={2}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'Tag - Grey - Text'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchData && fetchData['2_help']}
                          </Text>
                        </View>
                      </View>
                      <FlatList
                        data={fetchData?.resources}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'WuxVpp5R'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* horizontal touch */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate(
                                      'ResourceDetailsScreen',
                                      { resource_id: listData?.id }
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'horizontal'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].style,
                                      {
                                        marginRight: 8,
                                        marginTop: 8,
                                        maxWidth: 275,
                                        minWidth: 275,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* icon 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Right Link Text'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Right Link Text'
                                          ].style,
                                          { opacity: 0.5 }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.state}
                                    </Text>
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'stretch',
                                        flex: 1,
                                        flexGrow: 1,
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          justifyContent: 'center',
                                          minHeight: 40,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'List Bold'
                                        ].props}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.title}
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1, flexDirection: 'row' },
                          dimensions.width
                        )}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                      />
                    </View>
                  )}
                </>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchPublicActionSinglePOST>
    </ScreenContainer>
  );
};

export default withTheme(ActionDetailsPublicScreen);
