import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ActionListPublicScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [category, setCategory] = React.useState('housing');
  const [option, setOption] = React.useState(1);
  const [userID, setUserID] = React.useState(0);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchPublicActionListGET>
        {({ loading, error, data, refetchPublicActionList }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 5'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* tag */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'\n\naction plan'}
                </Text>
                {/* title */}
                <>
                  {props.route?.params?.participant ?? '' ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {'action plan options'}
                    </Text>
                  )}
                </>
                {/* title 2 */}
                <>
                  {!(props.route?.params?.participant ?? '') ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {(props.route?.params?.participant ?? '')?.name_first}{' '}
                      {(props.route?.params?.participant ?? '')?.name_last}
                    </Text>
                  )}
                </>
                {/* options */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'stretch',
                      alignSelf: 'stretch',
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'stretch',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                      },
                      dimensions.width
                    )}
                  >
                    <FlatList
                      data={Constants['action_category']}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'lQlsOqgi'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignSelf: 'center' },
                              dimensions.width
                            )}
                          >
                            {/* options */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  borderColor: theme.colors['Light'],
                                  borderRadius: 4,
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  marginRight: 4,
                                  padding: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {/* red */}
                              <>
                                {!(listData === category) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setCategory(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Primary'],
                                          fontFamily: 'Assistant_800ExtraBold',
                                          fontSize: 16,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                              {/* black */}
                              <>
                                {!(listData !== category) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setCategory(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Light'],
                                          fontFamily: 'Assistant_600SemiBold',
                                          fontSize: 16,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                            </View>
                          </View>
                        );
                      }}
                      {...GlobalStyles.FlatListStyles(theme)['list'].props}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.FlatListStyles(theme)['list'].style,
                        dimensions.width,
                        ['width', 'height', 'flexGrow']
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.FlatListStyles(theme)['list'].style,
                          { alignContent: 'stretch', alignSelf: 'stretch' }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Background'],
                    flexBasis: 1,
                    marginBottom: 32,
                    padding: 32,
                  },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                {/* 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData[1]}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                            .style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData['1_help']}
                      </Text>
                    </View>
                  </View>
                  <FlatList
                    data={fetchData?.action_list}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'z0b2ASbS'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.category === category) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    /* hidden 'API Request' action */
                                    /* hidden 'Navigate Back' action */
                                    navigation.navigate(
                                      'ActionDetailsPublicScreen',
                                      { action_id: listData?.id }
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <>
                                  {!(listData?.step === 1) ? null : (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'horizontal'
                                          ].style,
                                          {
                                            marginRight: 8,
                                            marginTop: 8,
                                            maxWidth: 275,
                                            minWidth: 275,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* icon 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.category === 'legal'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/scale-balance'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'housing'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-home-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'money'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'Ionicons/ios-wallet-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'health'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/plus-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'connect'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-link-variant-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                      </View>
                                      {/* center */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'stretch',
                                            flex: 1,
                                            flexGrow: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View>
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].props}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'List Bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.action}
                                          </Text>
                                          {/* secondary */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].props}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Tag - Grey - Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.why}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
                {/* 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData[2]}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                            .style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData['2_help']}
                      </Text>
                    </View>
                  </View>
                  <FlatList
                    data={fetchData?.action_list}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'o7BODKmf'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.category === category) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    /* hidden 'API Request' action */
                                    /* hidden 'Navigate Back' action */
                                    navigation.navigate(
                                      'ActionDetailsPublicScreen',
                                      { action_id: listData?.id }
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <>
                                  {!(listData?.step === 2) ? null : (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'horizontal'
                                          ].style,
                                          {
                                            marginRight: 8,
                                            marginTop: 8,
                                            maxWidth: 275,
                                            minWidth: 275,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* icon 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.category === 'legal'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/scale-balance'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'housing'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-home-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'money'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'Ionicons/ios-wallet-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'health'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/plus-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'connect'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-link-variant-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                      </View>
                                      {/* center */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'stretch',
                                            flex: 1,
                                            flexGrow: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View>
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].props}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'List Bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.action}
                                          </Text>
                                          {/* secondary */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].props}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Tag - Grey - Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.why}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
                {/* 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData[3]}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                            .style,
                          dimensions.width
                        )}
                      >
                        {fetchData && fetchData['3_help']}
                      </Text>
                    </View>
                  </View>
                  <FlatList
                    data={fetchData?.action_list}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'GUpXg4By'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.category === category) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    /* hidden 'API Request' action */
                                    /* hidden 'Navigate Back' action */
                                    if (
                                      !(
                                        (props.route?.params?.participant ?? '')
                                          ?.id >= 1
                                      )
                                    ) {
                                      navigation.navigate(
                                        'ActionDetailsPublicScreen',
                                        { action_id: listData?.id }
                                      );
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <>
                                  {!(listData?.step === 3) ? null : (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'horizontal'
                                          ].style,
                                          {
                                            marginRight: 8,
                                            marginTop: 8,
                                            maxWidth: 275,
                                            minWidth: 275,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* icon 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.category === 'legal'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/scale-balance'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'housing'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-home-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'money'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'Ionicons/ios-wallet-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'health'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/plus-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'connect'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-link-variant-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                      </View>
                                      {/* center */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'stretch',
                                            flex: 1,
                                            flexGrow: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View>
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].props}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'List Bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.action}
                                          </Text>
                                          {/* secondary */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].props}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Tag - Grey - Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.why}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchPublicActionListGET>
    </ScreenContainer>
  );
};

export default withTheme(ActionListPublicScreen);
