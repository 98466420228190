import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import makeDate from '../global-functions/makeDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  NumberInput,
  Picker,
  ScreenContainer,
  Switch,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const UserDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [edit, setEdit] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [inputAdmin, setInputAdmin] = React.useState(false);
  const [inputAdults, setInputAdults] = React.useState('');
  const [inputAlt, setInputAlt] = React.useState('');
  const [inputBar, setInputBar] = React.useState('');
  const [inputBenefits, setInputBenefits] = React.useState('');
  const [inputCause, setInputCause] = React.useState('');
  const [inputChildren, setInputChildren] = React.useState('');
  const [inputCity, setInputCity] = React.useState('');
  const [inputDOB, setInputDOB] = React.useState('');
  const [inputDay, setInputDay] = React.useState('');
  const [inputDisability, setInputDisability] = React.useState('');
  const [inputEDU, setInputEDU] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [inputEntryDate, setInputEntryDate] = React.useState('');
  const [inputEntryDay, setInputEntryDay] = React.useState('');
  const [inputEntryMonth, setInputEntryMonth] = React.useState('');
  const [inputEntryYear, setInputEntryYear] = React.useState('');
  const [inputFirst, setInputFirst] = React.useState('');
  const [inputGender, setInputGender] = React.useState('');
  const [inputHousing, setInputHousing] = React.useState('');
  const [inputIncome, setInputIncome] = React.useState('');
  const [inputLast, setInputLast] = React.useState('');
  const [inputLead, setInputLead] = React.useState(false);
  const [inputMiddle, setInputMiddle] = React.useState('');
  const [inputMonth, setInputMonth] = React.useState('');
  const [inputRace, setInputRace] = React.useState('');
  const [inputRole, setInputRole] = React.useState('');
  const [inputSaved, setInputSaved] = React.useState('');
  const [inputSavings, setInputSavings] = React.useState('');
  const [inputSource, setInputSource] = React.useState('');
  const [inputStateID, setInputStateID] = React.useState('');
  const [inputStatus, setInputStatus] = React.useState('');
  const [inputStreet, setInputStreet] = React.useState('');
  const [inputTEL, setInputTEL] = React.useState('');
  const [inputVet, setInputVet] = React.useState('');
  const [inputVision, setInputVision] = React.useState('');
  const [inputYear, setInputYear] = React.useState('');
  const [inputZIP, setInputZIP] = React.useState('');
  const [pickerState, setPickerState] = React.useState('');
  const [pickerWorksFor, setPickerWorksFor] = React.useState('');
  const [switchPrivacy, setSwitchPrivacy] = React.useState(false);
  const xanoUserPatchPATCH = XanoApi.useUserPatchPATCH();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <View
        collapsable={true}
        style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
      >
        <XanoApi.FetchUserGetGET
          handlers={{
            onData: fetchData => {
              console.log('Fetch ON_DATA Start');
              let error = null;
              try {
                console.log('Start ON_DATA:0 SET_VARIABLE');
                setInputFirst(fetchData?.user?.name_first);
                console.log('Complete ON_DATA:0 SET_VARIABLE');
                console.log('Start ON_DATA:1 SET_VARIABLE');
                setInputMiddle(fetchData?.user?.name_middle);
                console.log('Complete ON_DATA:1 SET_VARIABLE');
                console.log('Start ON_DATA:2 SET_VARIABLE');
                setInputLast(fetchData?.user?.name_last);
                console.log('Complete ON_DATA:2 SET_VARIABLE');
                console.log('Start ON_DATA:3 SET_VARIABLE');
                setInputDOB(fetchData?.user?.date_of_birth);
                console.log('Complete ON_DATA:3 SET_VARIABLE');
                console.log('Start ON_DATA:4 SET_VARIABLE');
                setInputStateID(fetchData?.user?.state_id);
                console.log('Complete ON_DATA:4 SET_VARIABLE');
                console.log('Start ON_DATA:5 SET_VARIABLE');
                setInputStreet(fetchData?.user?.address_street);
                console.log('Complete ON_DATA:5 SET_VARIABLE');
                console.log('Start ON_DATA:6 SET_VARIABLE');
                setInputCity(fetchData?.user?.address_city);
                console.log('Complete ON_DATA:6 SET_VARIABLE');
                console.log('Start ON_DATA:7 SET_VARIABLE');
                setPickerState(fetchData?.user?.address_state);
                console.log('Complete ON_DATA:7 SET_VARIABLE');
                console.log('Start ON_DATA:8 SET_VARIABLE');
                setInputZIP(fetchData?.user?.address_zip);
                console.log('Complete ON_DATA:8 SET_VARIABLE');
                console.log('Start ON_DATA:9 SET_VARIABLE');
                setInputTEL(fetchData?.user?.tel);
                console.log('Complete ON_DATA:9 SET_VARIABLE');
                console.log('Start ON_DATA:10 SET_VARIABLE');
                setInputAlt(fetchData?.user?.tel_alt);
                console.log('Complete ON_DATA:10 SET_VARIABLE');
                console.log('Start ON_DATA:11 SET_VARIABLE');
                setInputEntryDate(fetchData?.user?.entry_date);
                console.log('Complete ON_DATA:11 SET_VARIABLE');
                console.log('Start ON_DATA:12 SET_VARIABLE');
                setInputBenefits(fetchData?.user?.entry_benefits);
                console.log('Complete ON_DATA:12 SET_VARIABLE');
                console.log('Start ON_DATA:13 SET_VARIABLE');
                setInputIncome(fetchData?.user?.entry_income);
                console.log('Complete ON_DATA:13 SET_VARIABLE');
                console.log('Start ON_DATA:14 SET_VARIABLE');
                setInputSavings(fetchData?.user?.entry_disposable);
                console.log('Complete ON_DATA:14 SET_VARIABLE');
                console.log('Start ON_DATA:15 SET_VARIABLE');
                setInputSaved(fetchData?.user?.entry_saved);
                console.log('Complete ON_DATA:15 SET_VARIABLE');
                console.log('Start ON_DATA:16 SET_VARIABLE');
                setInputVision(fetchData?.user?.vision_statement);
                console.log('Complete ON_DATA:16 SET_VARIABLE');
                console.log('Start ON_DATA:17 SET_VARIABLE');
                setInputGender(fetchData?.user?.sex);
                console.log('Complete ON_DATA:17 SET_VARIABLE');
                console.log('Start ON_DATA:18 SET_VARIABLE');
                setInputRace(fetchData?.user?.race);
                console.log('Complete ON_DATA:18 SET_VARIABLE');
                console.log('Start ON_DATA:19 SET_VARIABLE');
                setInputDisability(fetchData?.user?.disability);
                console.log('Complete ON_DATA:19 SET_VARIABLE');
                console.log('Start ON_DATA:20 SET_VARIABLE');
                setInputVet(fetchData?.user?.vet);
                console.log('Complete ON_DATA:20 SET_VARIABLE');
                console.log('Start ON_DATA:21 SET_VARIABLE');
                setInputEDU(fetchData?.user?.edu);
                console.log('Complete ON_DATA:21 SET_VARIABLE');
                console.log('Start ON_DATA:22 SET_VARIABLE');
                setSwitchPrivacy(fetchData?.user?.privacy);
                console.log('Complete ON_DATA:22 SET_VARIABLE');
                console.log('Start ON_DATA:24 SET_VARIABLE');
                setInputRole(fetchData?.user?.role);
                console.log('Complete ON_DATA:24 SET_VARIABLE');
                console.log('Start ON_DATA:25 SET_VARIABLE');
                setInputStatus(fetchData?.user?.status);
                console.log('Complete ON_DATA:25 SET_VARIABLE');
                console.log('Start ON_DATA:26 SET_VARIABLE');
                setInputEmail(fetchData?.user?.email);
                console.log('Complete ON_DATA:26 SET_VARIABLE');
                console.log('Start ON_DATA:27 SET_VARIABLE');
                setPickerWorksFor(fetchData?.user?.works_for);
                console.log('Complete ON_DATA:27 SET_VARIABLE');
                console.log('Start ON_DATA:28 SET_VARIABLE');
                setInputBar(fetchData?.user?.bar);
                console.log('Complete ON_DATA:28 SET_VARIABLE');
                console.log('Start ON_DATA:29 SET_VARIABLE');
                setInputLead(fetchData?.user?.lead);
                console.log('Complete ON_DATA:29 SET_VARIABLE');
                console.log('Start ON_DATA:30 SET_VARIABLE');
                setInputAdmin(fetchData?.user?.admin);
                console.log('Complete ON_DATA:30 SET_VARIABLE');
                console.log('Start ON_DATA:31 SET_VARIABLE');
                setInputDay(fetchData?.user?.dob_day);
                console.log('Complete ON_DATA:31 SET_VARIABLE');
                console.log('Start ON_DATA:32 SET_VARIABLE');
                setInputMonth(fetchData?.user?.dob_month);
                console.log('Complete ON_DATA:32 SET_VARIABLE');
                console.log('Start ON_DATA:33 SET_VARIABLE');
                setInputYear(fetchData?.user?.dob_year);
                console.log('Complete ON_DATA:33 SET_VARIABLE');
                console.log('Start ON_DATA:34 SET_VARIABLE');
                setInputEntryDay(fetchData?.user?.entry_day);
                console.log('Complete ON_DATA:34 SET_VARIABLE');
                console.log('Start ON_DATA:35 SET_VARIABLE');
                setInputEntryMonth(fetchData?.user?.entry_month);
                console.log('Complete ON_DATA:35 SET_VARIABLE');
                console.log('Start ON_DATA:36 SET_VARIABLE');
                setInputEntryYear(fetchData?.user?.entry_year);
                console.log('Complete ON_DATA:36 SET_VARIABLE');
                console.log('Start ON_DATA:37 SET_VARIABLE');
                setInputCause(fetchData?.user?.entry_cause);
                console.log('Complete ON_DATA:37 SET_VARIABLE');
              } catch (err) {
                console.error(err);
                error = err.message ?? err;
              }
              console.log(
                'Fetch ON_DATA Complete',
                error ? { error } : 'no error'
              );
            },
          }}
          user_id={props.route?.params?.user_id ?? 637}
        >
          {({ loading, error, data, refetchUserGet }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* header */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['header'].style,
                    dimensions.width
                  )}
                >
                  {/* nav */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['nav 2'].style,
                      dimensions.width
                    )}
                  >
                    {/* back */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* view */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'auto',
                            flexDirection: 'column',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Light']}
                          name={'Ionicons/chevron-back'}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 4,
                              marginLeft: 4,
                              marginRight: 4,
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: theme.colors['Light'], fontSize: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'back'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* home */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('FSScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* view */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Light']}
                          name={'Feather/home'}
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 4,
                              marginLeft: 4,
                              marginRight: 4,
                              marginTop: 4,
                            },
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: theme.colors['Light'], fontSize: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'home'}
                        </Text>
                      </View>
                    </Touchable>
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                      dimensions.width
                    )}
                  >
                    {'\n\nprofile'}
                  </Text>
                  {/* title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Big Header'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.user?.name_first}
                    {/* title 2 */}
                    <>
                      {!fetchData?.user?.name_middle ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Big Header']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Big Header'].style,
                            dimensions.width
                          )}
                        >
                          {' '}
                          {fetchData?.user?.name_middle}
                        </Text>
                      )}
                    </>
                    {/* title 3 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {' '}
                      {fetchData?.user?.name_last}
                    </Text>
                  </Text>
                </View>

                <KeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableOnAndroid={false}
                  enableResetScrollToCoords={false}
                  viewIsInsideTabBar={false}
                  contentContainerStyle={StyleSheet.applyWidth(
                    {
                      flexBasis: 1,
                      paddingBottom: 32,
                      paddingLeft: 32,
                      paddingRight: 32,
                      paddingTop: 32,
                    },
                    dimensions.width
                  )}
                  keyboardShouldPersistTaps={'always'}
                  showsVerticalScrollIndicator={false}
                >
                  {/* details */}
                  <>
                    {edit ? null : (
                      <View>
                        {/* id */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 32 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'id'}
                          </Text>
                          {/* View 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* center */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            >
                              {/* dob */}
                              <>
                                {!fetchData?.user?.date_of_birth ? null : (
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    numberOfLines={1}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchData?.user?.date_of_birth}
                                  </Text>
                                )}
                              </>
                              {/* org */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.user?.state_id}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* demo */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 32 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'demographics'}
                          </Text>
                          {/* race */}
                          <>
                            {!fetchData?.user?.date_of_birth ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.user?.race}
                              </Text>
                            )}
                          </>
                          {/* disability */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.disability}
                          </Text>
                          {/* vet */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.vet}
                          </Text>
                          {/* sex */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.sex}
                          </Text>
                          {/* edu */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.edu}
                          </Text>
                        </View>
                        {/* situation */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 32 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Header - Page']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Header - Page']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'situation at latest intake'}
                          </Text>
                          {/* housing */}
                          <>
                            {!fetchData?.user?.date_of_birth ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.user?.entry_housing}
                              </Text>
                            )}
                          </>
                          {/* causes */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.entry_cause}
                          </Text>
                          {/* HH */}
                          <>
                            {!fetchData?.user?.entry_hh_adult ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'HH: '}
                                {fetchData?.user?.entry_hh_adult}
                                {', '}
                                {fetchData?.user?.entry_hh_child}
                              </Text>
                            )}
                          </>
                          {/* benefits */}
                          <>
                            {!fetchData?.user?.entry_benefits ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'receives needs based public benefits'}
                              </Text>
                            )}
                          </>
                          {/* benefits 2 */}
                          <>
                            {fetchData?.user?.entry_benefits ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'does not get needs-based public benefits'}
                              </Text>
                            )}
                          </>
                          {/* income */}
                          <>
                            {!fetchData?.user?.entry_income ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {fetchData?.user?.entry_income}
                              </Text>
                            )}
                          </>
                          {/* source */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              dimensions.width
                            )}
                          >
                            {fetchData?.user?.entry_source}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                  {/* edit */}
                  <>
                    {!fetchData?.edit ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('UserEditScreen', {
                              user_id: fetchData?.user?.id,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.TouchableStyles(theme)[
                          'touchable icon right'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].style,
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'flex-start',
                                flex: 1,
                                flexWrap: 'nowrap',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Right Link Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].style,
                                  { alignSelf: 'flex-end' }
                                ),
                                dimensions.width
                              )}
                            >
                              {'edit'}
                            </Text>
                          </View>
                          <Icon
                            size={24}
                            color={theme.colors['Primary']}
                            name={'Feather/edit-3'}
                            style={StyleSheet.applyWidth(
                              { marginLeft: 8 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </Touchable>
                    )}
                  </>
                </KeyboardAwareScrollView>
              </>
            );
          }}
        </XanoApi.FetchUserGetGET>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UserDetailScreen);
