import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const StatsYearScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [actionArray, setActionArray] = React.useState([]);
  const [actionCategory, setActionCategory] = React.useState('');
  const [actionID, setActionID] = React.useState(0);
  const [category, setCategory] = React.useState('');
  const [my_case, setMy_case] = React.useState(false);
  const [programID, setProgramID] = React.useState(1000);
  const [showActions, setShowActions] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Constants['user_details']?.role === 'participant') {
        setMy_case(true);
      }
      console.log(Constants['works_for_programs']);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 5'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  { color: theme.colors['Light'], fontSize: 10 },
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'\nreports'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
            dimensions.width
          )}
        >
          {'Select a year'}
        </Text>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          { padding: 32 },
          dimensions.width
        )}
        showsVerticalScrollIndicator={false}
      >
        <View>
          {/* list */}
          <FlatList
            data={Constants['year'].slice(0, 7)}
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ?? listData?.uuid ?? index.toString()
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'5iK2ZvW3'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <Button
                  onPress={() => {
                    console.log('Button ON_PRESS Start');
                    let error = null;
                    try {
                      console.log('Start ON_PRESS:0 NAVIGATE');
                      if (Constants['user_details']?.role !== 'provider') {
                        navigation.navigate('StatsReportScreen', {
                          program_id: props.route?.params?.program_id ?? '',
                          program_name: props.route?.params?.program_name ?? '',
                          year: listData?.value,
                        });
                        console.log('Complete ON_PRESS:0 NAVIGATE');
                      } else {
                        console.log(
                          'Skipped ON_PRESS:0 NAVIGATE: condition not met'
                        );
                      }
                      console.log('Start ON_PRESS:1 NAVIGATE');
                      if (Constants['user_details']?.role === 'provider') {
                        navigation.navigate('StatsReportProviderScreen', {
                          program_id: props.route?.params?.program_id ?? '',
                          program_name: props.route?.params?.program_name ?? '',
                          year: listData?.value,
                        });
                        console.log('Complete ON_PRESS:1 NAVIGATE');
                      } else {
                        console.log(
                          'Skipped ON_PRESS:1 NAVIGATE: condition not met'
                        );
                      }
                    } catch (err) {
                      console.error(err);
                      error = err.message ?? err;
                    }
                    console.log(
                      'Button ON_PRESS Complete',
                      error ? { error } : 'no error'
                    );
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button Outline'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button Outline'].style,
                      {
                        fontFamily: 'Assistant_800ExtraBold',
                        fontSize: 18,
                        marginBottom: 8,
                        marginTop: 8,
                        opacity: 0.75,
                      }
                    ),
                    dimensions.width
                  )}
                  title={`${listData?.label}`}
                />
              );
            }}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            contentContainerStyle={StyleSheet.applyWidth(
              { alignContent: 'stretch' },
              dimensions.width
            )}
          />
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(StatsYearScreen);
