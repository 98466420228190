import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  Link,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const FSScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <XanoApi.FetchAuthMeGET
        handlers={{
          on401: fetchData => {
            try {
              navigation.navigate('FSLoginScreen');
            } catch (err) {
              console.error(err);
            }
          },
          on403: fetchData => {
            try {
              navigation.navigate('FSLoginScreen');
            } catch (err) {
              console.error(err);
            }
          },
          on404: fetchData => {
            try {
              navigation.navigate('FSLoginScreen');
            } catch (err) {
              console.error(err);
            }
          },
          on4xx: fetchData => {
            try {
              navigation.navigate('FSLoginScreen');
            } catch (err) {
              console.error(err);
            }
          },
          on5xx: fetchData => {
            try {
              navigation.navigate('FSLoginScreen');
            } catch (err) {
              console.error(err);
            }
          },
          onData: fetchData => {
            try {
              const userDets = setGlobalVariableValue({
                key: 'user_details',
                value: fetchData?.user,
              });
              setGlobalVariableValue({
                key: 'works_for',
                value: fetchData?.works_for,
              });
              setGlobalVariableValue({
                key: 'works_for_programs',
                value: fetchData?.works_for_programs,
              });
              setGlobalVariableValue({
                key: 'organization_list',
                value: fetchData?.organization_list,
              });
              setGlobalVariableValue({
                key: 'person_list',
                value: fetchData?.person_list,
              });
              setGlobalVariableValue({
                key: 'case_status_list',
                value: fetchData?.case_status_list,
              });
              setGlobalVariableValue({
                key: 'case_type_list',
                value: fetchData?.case_type_list,
              });
              setGlobalVariableValue({
                key: 'entry_causes',
                value: fetchData?.entry_cause,
              });
              setGlobalVariableValue({
                key: 'entry_housing',
                value: fetchData?.entry_housing,
              });
              setGlobalVariableValue({
                key: 'entry_source',
                value: fetchData?.entry_source,
              });
              setGlobalVariableValue({
                key: 'edu',
                value: fetchData?.edu,
              });
              setGlobalVariableValue({
                key: 'race',
                value: fetchData?.race,
              });
              const quote = setGlobalVariableValue({
                key: 'quote',
                value: fetchData?.quote,
              });
              undefined;
              undefined;
              if (fetchData?.onboarding) {
                navigation.navigate('IntakeInfoScreen', {
                  user_id: fetchData?.user?.id,
                  email: fetchData?.user?.email,
                });
              }
              /* 'Set Variable' action requires configuration: choose a variable */
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchAuthMe }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              <>
                {!Constants['initial_load'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors['Divider'],
                        height: '100%',
                        justifyContent: 'center',
                        padding: 32,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* quote */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 32 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['quote'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['quote'].style,
                          dimensions.width
                        )}
                      >
                        {'"'}
                        {fetchData?.quote?.quote}
                        {'"'}
                      </Text>

                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Small Header']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Small Header']
                              .style,
                            {
                              fontFamily: 'Assistant_500Medium',
                              fontSize: 16,
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'\n- with love from '}
                        {fetchData?.quote?.who}
                        {",\nsomeone who's been there"}
                      </Text>
                    </View>
                    {/* Locked */}
                    <>
                      {!fetchData?.user?.locked ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 32 },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: theme.colors['Primary-Faint'],
                                borderColor: theme.colors['Primary'],
                                borderRadius: 4,
                                borderWidth: 1,
                                flexDirection: 'row',
                                padding: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={theme.colors['Primary']}
                              name={'Feather/alert-triangle'}
                              size={48}
                              style={StyleSheet.applyWidth(
                                { marginRight: 8 },
                                dimensions.width
                              )}
                            />
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Tag - Red']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Tag - Red']
                                    .style,
                                  { fontSize: 16, textTransform: 'none' }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.locked}
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    <>
                      {fetchData?.user?.locked ? null : (
                        <Link
                          accessible={true}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'initial_load',
                                value: false,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.LinkStyles(theme)['Link - Big - Red']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinkStyles(theme)['Link - Big - Red']
                                .style,
                              {
                                fontSize: 24,
                                marginTop: 32,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                          title={"Let's Go!"}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
              {/* header_home */}
              <>
                {Constants['initial_load'] ? null : (
                  <View
                    {...GlobalStyles.ViewStyles(theme)['header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['header'].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'stretch',
                          alignSelf: 'stretch',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Big Header - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Big Header - Text']
                              .style,
                            {
                              color: theme.colors['Dark'],
                              fontFamily: 'Assistant_700Bold',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'\nWelcome back,'}
                      </Text>

                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Big Header'].style,
                            { fontSize: 60 }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['user_details']?.name_first}
                        {'!'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            { alignSelf: 'flex-end' }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['user_details']?.role}
                        {/* Text 3 */}
                        <>
                          {!fetchData?.works_for?.entity ? null : (
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Tag - Grey - Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'Tag - Grey - Text'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {' @ '}
                              {fetchData?.works_for?.entity}
                            </Text>
                          )}
                        </>
                      </Text>
                    </View>
                  </View>
                )}
              </>
              <>
                {Constants['initial_load'] ? null : (
                  <KeyboardAwareScrollView
                    enableAutomaticScroll={false}
                    enableOnAndroid={false}
                    enableResetScrollToCoords={false}
                    viewIsInsideTabBar={false}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flexBasis: 1, padding: 32 },
                      dimensions.width
                    )}
                    keyboardShouldPersistTaps={'always'}
                    showsVerticalScrollIndicator={false}
                  >
                    {/* user info */}
                    <>
                      {!fetchData?.allow_profile ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('UserDetailScreen', {
                                user_id: fetchData?.user?.id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.profile}
                              </Text>

                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Caption']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Caption']
                                      .style,
                                    {
                                      alignSelf: 'auto',
                                      fontFamily: 'Assistant_300Light',
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.profile_help}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={'Feather/user'}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* user checkins */}
                    <>
                      {!fetchData?.allow_profile ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('UserActionPlanScreen', {
                                user_id: fetchData?.user?.id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'action plan'}
                              </Text>

                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Caption']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Caption']
                                      .style,
                                    {
                                      alignSelf: 'auto',
                                      fontFamily: 'Assistant_300Light',
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.profile_help}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={'MaterialCommunityIcons/format-list-checks'}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* search */}
                    <>
                      {!fetchData?.allow_search ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('UserSearchScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.search}
                              </Text>

                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Caption']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Caption']
                                      .style,
                                    {
                                      alignSelf: 'auto',
                                      fontFamily: 'Assistant_300Light',
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.search_help}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={
                                'MaterialCommunityIcons/account-search-outline'
                              }
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* checkin */}
                    <>
                      {!fetchData?.allow_checkins ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('CheckinListScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.requests}
                              </Text>

                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Caption']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Caption']
                                      .style,
                                    {
                                      alignSelf: 'auto',
                                      fontFamily: 'Assistant_300Light',
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.requests_help}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={
                                'MaterialCommunityIcons/alert-decagram-outline'
                              }
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* caselist */}
                    <>
                      {!fetchData?.allow_caselist ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('CaseListProgramScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-start',
                                  flex: 1,
                                  flexWrap: 'nowrap',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'Right Link Text'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.caselist}
                              </Text>

                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Caption']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Caption']
                                      .style,
                                    {
                                      alignSelf: 'auto',
                                      fontFamily: 'Assistant_300Light',
                                      textAlign: 'right',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchData?.screen?.caselist_help}
                              </Text>
                            </View>
                            <Icon
                              size={24}
                              color={theme.colors['Primary']}
                              name={'MaterialCommunityIcons/scale-balance'}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* cleanup */}
                    <>
                      {!fetchData?.allow_cleanup ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('CaseListCleanupScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Right Link Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'cleanup'}
                            </Text>
                            <Icon
                              size={24}
                              {...GlobalStyles.IconStyles(theme)[
                                'right link icon'
                              ].props}
                              color={theme.colors['Primary']}
                              name={
                                'MaterialCommunityIcons/folder-alert-outline'
                              }
                              style={StyleSheet.applyWidth(
                                GlobalStyles.IconStyles(theme)[
                                  'right link icon'
                                ].style,
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* archive */}
                    <>
                      {!fetchData?.allow_cleanup ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('CaseListArchiveScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TouchableStyles(theme)[
                              'touchable icon right'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Right Link Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'Right Link Text'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'archive'}
                            </Text>
                            <Icon
                              size={24}
                              {...GlobalStyles.IconStyles(theme)[
                                'right link icon'
                              ].props}
                              color={theme.colors['Primary']}
                              name={'MaterialCommunityIcons/folder-zip-outline'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.IconStyles(theme)[
                                  'right link icon'
                                ].style,
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32, position: 'relative' },
                        dimensions.width
                      )}
                    >
                      {/* bottom links */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-end',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            marginTop: 32,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Tag - Grey - Text'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'other options'}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-end',
                              flexDirection: 'column',
                              justifyContent: 'space-evenly',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!(
                              Constants['user_details']?.role !== 'participant'
                            ) ? null : (
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('ActionListScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)[
                                      'Link - Big - Red'
                                    ].style,
                                    { opacity: 0.5 }
                                  ),
                                  dimensions.width
                                )}
                                title={'action list'}
                              />
                            )}
                          </>
                          {/* Link 3 */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                navigation.navigate('OrgList2Screen', {
                                  type: 'provider',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)[
                              'Link - Big - Red'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].style,
                                { opacity: 0.5 }
                              ),
                              dimensions.width
                            )}
                            title={'partner list'}
                          />
                          {/* Link 2 */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                navigation.navigate('ProgramListScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)[
                              'Link - Big - Red'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].style,
                                { opacity: 0.5 }
                              ),
                              dimensions.width
                            )}
                            title={'program list'}
                          />
                          {/* Link 6 */}
                          <>
                            {!(
                              fetchData?.user?.role !== 'participant'
                            ) ? null : (
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('StatsProgramScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)[
                                      'Link - Big - Red'
                                    ].style,
                                    { opacity: 0.5 }
                                  ),
                                  dimensions.width
                                )}
                                title={'reports'}
                              />
                            )}
                          </>
                          {/* Link 4 */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                navigation.navigate('FAQListScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)[
                              'Link - Big - Red'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].style,
                                { opacity: 0.5 }
                              ),
                              dimensions.width
                            )}
                            title={'FAQs'}
                          />
                          {/* Link 5 */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                undefined;
                                undefined;
                                navigation.navigate('FSLoginScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)[
                              'Link - Big - Red'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'Link - Big - Red'
                                ].style,
                                { marginTop: 32, opacity: 0.5 }
                              ),
                              dimensions.width
                            )}
                            title={'log out'}
                          />
                        </View>
                        <Divider
                          {...GlobalStyles.DividerStyles(theme)['Divider']
                            .props}
                          color={'rgba(0, 0, 0, 0)'}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.DividerStyles(theme)['Divider']
                                .style,
                              { height: 64 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  </KeyboardAwareScrollView>
                )}
              </>
            </>
          );
        }}
      </XanoApi.FetchAuthMeGET>
    </ScreenContainer>
  );
};

export default withTheme(FSScreen);
