import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgb(255, 255, 255)',
    'Background Input': 'rgb(250, 250, 250)',
    Community_Primary: 'rgb(23, 179, 87)',
    Community_Primary_Alt: 'rgb(24, 103, 255)',
    Dark: 'rgb(75, 82, 103)',
    Darken: 'rgba(0, 0, 0, 0.1)',
    'Darken Super': 'rgba(0, 0, 0, 0.85)',
    Divider: 'rgba(234, 237, 242, 1)',
    Green: 'rgb(188, 189, 139)',
    Input_Background_Primary: 'rgb(101, 13, 27)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Blue': 'rgb(198, 202, 237)',
    Medium: 'rgba(70, 78, 88, 1)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    Primary: 'rgb(254, 95, 85)',
    'Primary-Faint': 'rgba(254, 95, 85, 0.2)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Tag 1': 'rgb(176, 253, 247)',
    'Tag 2 - Faint': 'rgba(155, 159, 156, 0.2)',
    'Tag 3': 'rgb(255, 230, 110)',
    background: 'rgb(255, 255, 255)',
    backgroundInput: 'rgb(250, 250, 250)',
    communityPrimary: 'rgb(23, 179, 87)',
    communityPrimaryAlt: 'rgb(24, 103, 255)',
    dark: 'rgb(75, 82, 103)',
    darken: 'rgba(0, 0, 0, 0.1)',
    darkenSuper: 'rgba(0, 0, 0, 0.85)',
    divider: 'rgba(234, 237, 242, 1)',
    green: 'rgb(188, 189, 139)',
    inputBackgroundPrimary: 'rgb(101, 13, 27)',
    light: 'rgba(165, 173, 183, 1)',
    lightBlue: 'rgb(198, 202, 237)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    primary: 'rgb(254, 95, 85)',
    primaryFaint: 'rgba(254, 95, 85, 0.2)',
    strong: 'rgba(18, 20, 44, 1)',
    tag1: 'rgb(176, 253, 247)',
    tag2Faint: 'rgba(155, 159, 156, 0.2)',
    tag3: 'rgb(255, 230, 110)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    custom14: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 20,
    },
    custom15: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom16: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom17: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom18: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom19: {
      ...systemWeights.thin,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom20: {
      ...systemWeights.thin,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom21: {
      ...systemWeights.extralight,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom22: {
      ...systemWeights.black,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom23: {
      ...systemWeights.black,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom24: {
      ...systemWeights.black,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom25: {
      ...systemWeights.black,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom26: {
      ...systemWeights.thin,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom27: {
      ...systemWeights.extralight,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom28: {
      ...systemWeights.extralight,
      fontSize: 14,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom29: {
      ...systemWeights.extralight,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom30: {
      ...systemWeights.extralight,
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom31: {
      ...systemWeights.thin,
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom32: {
      ...systemWeights.extralight,
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: 16,
    },
  },
};
