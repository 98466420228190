import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEqualTo from '../global-functions/isEqualTo';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CaseListCleanupScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [actionArray, setActionArray] = React.useState([]);
  const [actionCategory, setActionCategory] = React.useState('');
  const [actionID, setActionID] = React.useState(0);
  const [category, setCategory] = React.useState('');
  const [my_case, setMy_case] = React.useState(false);
  const [programID, setProgramID] = React.useState(1000);
  const [showActions, setShowActions] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Constants['user_details']?.role === 'participant') {
        setMy_case(true);
      }
      console.log(Constants['works_for_programs']);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchCaseListCleanupGET>
        {({ loading, error, data, refetchCaseListCleanup }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 5'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 5'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Light'], fontSize: 10 },
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'\n'}
                  {fetchData?.title}
                </Text>
                {/* options 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'stretch',
                      alignSelf: 'stretch',
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'stretch',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        flexWrap: 'wrap',
                      },
                      dimensions.width
                    )}
                  >
                    <FlatList
                      data={Constants['works_for_programs']}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'rG9FcEYG'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignSelf: 'center' },
                              dimensions.width
                            )}
                          >
                            {/* options */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  borderColor: theme.colors['Light'],
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  padding: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {/* red */}
                              <>
                                {!(listData?.id === programID) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setProgramID(listData?.id);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Primary'],
                                          fontFamily: 'Assistant_800ExtraBold',
                                          fontSize: 16,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.label}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                              {/* black */}
                              <>
                                {isEqualTo(listData?.id, programID) ? null : (
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setProgramID(listData?.id);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Light'],
                                          fontFamily: 'Assistant_700Bold',
                                          fontSize: 16,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.label}
                                    </Text>
                                  </Touchable>
                                )}
                              </>
                            </View>
                          </View>
                        );
                      }}
                      {...GlobalStyles.FlatListStyles(theme)['list'].props}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.FlatListStyles(theme)['list'].style,
                        dimensions.width,
                        ['width', 'height', 'flexGrow']
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.FlatListStyles(theme)['list'].style,
                          {
                            alignContent: 'stretch',
                            alignSelf: 'stretch',
                            flexWrap: 'wrap',
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flexBasis: 1, padding: 32 },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                <View>
                  {/* list */}
                  <FlatList
                    data={fetchData?.cases}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'Pfh96ONC'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* touchable */}
                          <>
                            {!(listData?.program_id === programID) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('CaseDetailsScreen', {
                                      case_id: listData?.id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'case list view'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TouchableStyles(theme)[
                                      'case list view'
                                    ].style,
                                    { marginBottom: 16 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* item */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        theme.colors['Background Input'],
                                      borderColor: theme.colors['Divider'],
                                      borderRadius: 4,
                                      borderWidth: 1,
                                      justifyContent: 'center',
                                      padding: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* left */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        size={24}
                                        color={theme.colors['Primary']}
                                        name={
                                          'MaterialCommunityIcons/scale-balance'
                                        }
                                        style={StyleSheet.applyWidth(
                                          { opacity: 0.5 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* date */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Tag - Red'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Tag - Red'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.next_date}
                                        {/* status */}
                                        <>
                                          {!listData?.status ? null : (
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Red'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Tag - Red'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {' - '}
                                              {listData?.status}
                                            </Text>
                                          )}
                                        </>
                                      </Text>
                                      {/* name */}
                                      <>
                                        {!(
                                          Constants['user_details']?.role !==
                                          'participant'
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'List Bold'
                                            ].props}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'List Bold'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.participant?.name_first}{' '}
                                            {listData?.participant?.name_last}
                                          </Text>
                                        )}
                                      </>
                                      {/* case type */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.case_type}
                                      </Text>

                                      <Text
                                        accessible={true}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Strong'],
                                            fontFamily: 'Assistant_400Regular',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 12,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 16,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.jurisdiction?.entity}
                                      </Text>
                                    </View>
                                    {/* right */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'defender'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {!listData?.flag_participant ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'participant'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          <>
                                            {!listData?.flag_provider ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'provider'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          <>
                                            {!listData?.flag_defender ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'defender'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          <>
                                            {!listData?.flag_prosecutor ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'prosecutor'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          <>
                                            {!listData?.flag_court ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'court'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                          <>
                                            {!listData?.flag_special ? null : (
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['Tag - Grey - View'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['Tag - Grey - View'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Tag - Grey - Text']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'special'}
                                                </Text>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { alignContent: 'stretch' },
                      dimensions.width
                    )}
                  />
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchCaseListCleanupGET>
    </ScreenContainer>
  );
};

export default withTheme(CaseListCleanupScreen);
