import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const CheckinEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setTextAreaValue(null?.provider_note);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column', paddingBottom: 32 },
        dimensions.width
      )}
    >
      <XanoApi.FetchCheckinGetOneGET
        checkin_id={props.route?.params?.checkin_id ?? 1}
      >
        {({ loading, error, data, refetchCheckinGetOne }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <>
                  {null?.verified ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {'\nedit action'}
                    </Text>
                  )}
                </>
                {/* title 2 */}
                <>
                  {!null?.verified ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {'\nverified action'}
                    </Text>
                  )}
                </>
              </View>
              {/* Keyboard Aware Scroll View 2 */}
              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Background'],
                    flexBasis: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                  },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                {/* edit */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['edit'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['edit'].style,
                    dimensions.width
                  )}
                >
                  {/* participant 2 */}
                  <View>
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Header - Page'].style,
                        dimensions.width
                      )}
                    >
                      {'participant'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { textAlign: 'auto' }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.checkin?.participant?.name_first}{' '}
                      {fetchData?.checkin?.participant?.name_middle}{' '}
                      {fetchData?.checkin?.participant?.name_last}
                      {'\n'}
                      {null?.date_of_birth}
                    </Text>
                  </View>
                  {/* action 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Header - Page'].style,
                        dimensions.width
                      )}
                    >
                      {'action'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { textAlign: 'auto' }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.checkin?.action?.action}
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Header - Page'].style,
                        dimensions.width
                      )}
                    >
                      {'verification details'}
                    </Text>

                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Tag - Grey - Text']
                          .style,
                        dimensions.width
                      )}
                    >
                      {fetchData?.verify_instructions}
                    </Text>
                    {/* input */}
                    <TextInput
                      changeTextDelay={500}
                      multiline={true}
                      onChangeText={newInputValue => {
                        try {
                          setTextAreaValue(newInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                      }
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                      autoFocus={true}
                      numberOfLines={10}
                      placeholder={
                        'application confirmation email dated 11/5 from Detroit Housing'
                      }
                      placeholderTextColor={theme.colors['Medium']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Input'].style,
                          { marginTop: 8 }
                        ),
                        dimensions.width
                      )}
                      value={textAreaValue}
                    />
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { marginTop: 32, textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.verify_text}
                  </Text>
                  {/* Button Note */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await XanoApi.checkinVerifyPATCH(Constants, {
                              checkin_id: fetchData?.checkin?.id,
                              note: textAreaValue,
                              verified: true,
                            })
                          )?.json;
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      dimensions.width
                    )}
                    title={'verify'}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Caption'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Caption'].style,
                        { marginTop: 64 }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.verify_decline}
                  </Text>
                  {/* Button Note 2 */}
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await XanoApi.checkinDenyPATCH(Constants, {
                              checkin_id: fetchData?.checkin?.id,
                            })
                          )?.json;
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button Outline']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button Outline']
                          .style,
                        { marginTop: 16 }
                      ),
                      dimensions.width
                    )}
                    title={'decline'}
                  />
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchCheckinGetOneGET>
    </ScreenContainer>
  );
};

export default withTheme(CheckinEditScreen);
