import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const ProgramDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [verified, setVerified] = React.useState('verified');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column', paddingBottom: 32 },
        dimensions.width
      )}
    >
      <XanoApi.FetchProgramGetOneGET
        program_id={props.route?.params?.program_id ?? 4}
      >
        {({ loading, error, data, refetchProgramGetOne }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                {...GlobalStyles.ViewStyles(theme)['header'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header'].style,
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Big Header'].style,
                      { textAlign: 'auto' }
                    ),
                    dimensions.width
                  )}
                >
                  {'\n'}
                  {fetchData?.program?.name}
                </Text>
                {/* Action */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
                    dimensions.width
                  )}
                >
                  {fetchData?.program?.full_name}
                </Text>
              </View>

              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  { padding: 32 },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={'always'}
                showsVerticalScrollIndicator={false}
              >
                {/* Body 2 */}
                <View>
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'overview'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.program?.description}
                  </Text>
                </View>
                {/* Body 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'how to participate'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.program?.get_started}
                  </Text>
                </View>
                {/* horizontal list 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Header - Page'].style,
                        dimensions.width
                      )}
                    >
                      {'community partners'}
                    </Text>
                  </View>
                  <FlatList
                    data={fetchData?.organizations}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'HlNBja63'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.role === 'provider') ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('OrgDetailsScreen', {
                                      OrganizationID: listData?.organization_id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <>
                                  {!(
                                    listData?.category !== 'provider'
                                  ) ? null : (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'horizontal'
                                          ].style,
                                          {
                                            marginRight: 8,
                                            marginTop: 8,
                                            maxWidth: 275,
                                            minWidth: 275,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* type of org icon */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!(
                                            listData?.category === 'legal'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/scale-balance'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'housing'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-home-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'money'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={'Entypo/wallet'}
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'health'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/medical-bag'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {!(
                                            listData?.category === 'connect'
                                          ) ? null : (
                                            <Icon
                                              color={theme.colors['Primary']}
                                              name={
                                                'MaterialCommunityIcons/shield-link-variant-outline'
                                              }
                                              size={18}
                                              style={StyleSheet.applyWidth(
                                                { opacity: 0.5 },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                      </View>
                                      {/* center */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            alignSelf: 'stretch',
                                            flex: 1,
                                            flexGrow: 1,
                                            justifyContent: 'center',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Red'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Tag - Red'
                                              ].style,
                                              { textTransform: 'lowercase' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.category}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={2}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st} {null}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
                {/* horizontal list */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Header - Page'].style,
                        dimensions.width
                      )}
                    >
                      {'legal partners'}
                    </Text>
                  </View>
                  <FlatList
                    data={fetchData?.organizations}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'lwaTjcAo'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* horizontal touch */}
                          <>
                            {!(listData?.role !== 'provider') ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('OrgDetailsScreen', {
                                      OrganizationID: listData?.organization_id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* new standard list view */}
                                <>
                                  {!(
                                    listData?.category !== 'provider'
                                  ) ? null : (
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'horizontal'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'horizontal'
                                          ].style,
                                          {
                                            marginRight: 8,
                                            marginTop: 8,
                                            maxWidth: 275,
                                            minWidth: 275,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* type of org icon */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/scale-balance'
                                          }
                                          size={18}
                                          style={StyleSheet.applyWidth(
                                            { opacity: 0.5 },
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                      {/* center */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            alignSelf: 'stretch',
                                            flex: 1,
                                            flexGrow: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Red'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Tag - Red'
                                              ].style,
                                              { textTransform: 'lowercase' }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.role}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entity}
                                        </Text>
                                        {/* secondary */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Tag - Grey - Text'
                                          ].props}
                                          numberOfLines={2}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Tag - Grey - Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.address_street}
                                          {', '}
                                          {listData?.address_city}
                                          {', '}
                                          {listData?.address_st} {null}
                                        </Text>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </Touchable>
                            )}
                          </>
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row' },
                      dimensions.width
                    )}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchProgramGetOneGET>
    </ScreenContainer>
  );
};

export default withTheme(ProgramDetailScreen);
