import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEqualTo from '../global-functions/isEqualTo';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  Markdown,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const CaseOrderScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [inputBG, setInputBG] = React.useState('');
  const [response, setResponse] = React.useState('');
  const [toggle, setToggle] = React.useState(false);
  const [toggleAction, setToggleAction] = React.useState(false);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchCaseGetOneGET
        case_id={props.route?.params?.case_id ?? 2116}
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'selected_case',
                value: fetchData,
              });
              if (isEqualTo(parseBoolean(inputBG), false)) {
                setInputBG(fetchData?.case?.bg_report);
              }
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchCaseGetOne }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <ScrollView
              bounces={true}
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              contentContainerStyle={StyleSheet.applyWidth(
                { padding: 32 },
                dimensions.width
              )}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
            >
              {/* caption */}
              <View
                {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['nav 2'].style,
                  dimensions.width
                )}
              >
                {/* back */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* view */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'auto',
                        flexDirection: 'column',
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      color={theme.colors['Darken']}
                      name={'Ionicons/chevron-back'}
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 4,
                          marginLeft: 4,
                          marginRight: 4,
                          marginTop: 4,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Assistant_700Bold', textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {fetchData?.program?.order_header}
                </Text>
                {/* back 2 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* view */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'auto',
                        flexDirection: 'column',
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      color={theme.colors['Darken']}
                      name={'Feather/chevron-right'}
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 4,
                          marginLeft: 4,
                          marginRight: 4,
                          marginTop: 4,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </Touchable>
              </View>
              {/* parties */}
              <View
                {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    { alignContent: 'center', alignSelf: 'auto' }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      { fontFamily: 'Assistant_400Regular', fontSize: 8 },
                      dimensions.width
                    )}
                  >
                    {'complainant'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { fontFamily: 'Assistant_700Bold' }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.program?.order_complainant}
                  </Text>
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: '10%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { fontFamily: 'Assistant_700Bold', textAlign: 'center' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'\nv'}
                  </Text>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Assistant_400Regular',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Assistant_400Regular',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 8 },
                          { minWidth: Breakpoints.Mobile, value: 8 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'defendant'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { fontFamily: 'Assistant_700Bold' }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.participant?.name_first}
                    {/* Text 3 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Print Body'].style,
                          { fontFamily: 'Assistant_700Bold' }
                        ),
                        dimensions.width
                      )}
                    >
                      {' '}
                      {fetchData?.participant?.name_middle}
                      {/* Text 4 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Print Body'].style,
                            { fontFamily: 'Assistant_700Bold' }
                          ),
                          dimensions.width
                        )}
                      >
                        {' '}
                        {fetchData?.participant?.name_last}
                      </Text>
                    </Text>
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.participant?.address_street}
                    {', '}
                    {fetchData?.participant?.address_city}
                    {', '}
                    {fetchData?.participant?.address_state}{' '}
                    {fetchData?.participant?.address_zip}
                  </Text>
                </View>
              </View>
              {/* caption 2 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    { justifyContent: 'center', marginTop: 32 }
                  ),
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      { fontFamily: 'Assistant_700Bold', textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'MOTION FOR RELIEF\n'}
                  {fetchData?.program?.full_name?.toUpperCase()}
                </Text>
              </View>
              {/* 1 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column', marginTop: 8 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 28 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Print Body'].style,
                          { textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'1.'}
                    </Text>
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { alignSelf: 'auto' }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.program?.order_authority}
                  </Text>
                </View>
              </View>
              {/* 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column', marginTop: 8 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 28 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Print Body'].style,
                          { textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'2.'}
                    </Text>
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { alignSelf: 'auto' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Participant '}
                    {fetchData?.participant?.name_first}{' '}
                    {fetchData?.participant?.name_last}
                    {' worked with '}
                    {fetchData?.provider?.entity}
                    {
                      ' to develop an Action Plan that addressed the root causes of their poverty/law-offending acts. Pursuant to that Action Plan, Participant has taken the following independently-verified actions:'
                    }
                  </Text>
                </View>
              </View>
              {/* action plan list */}
              <>
                {!(fetchData?.checkin?.length >= 1) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginLeft: 32 },
                      dimensions.width
                    )}
                  >
                    {/* list */}
                    <FlatList
                      data={fetchData?.checkin}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ?? listData?.uuid ?? index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'uP9wfBIb'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* item */}
                            <>
                              {!listData?.verified ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* left */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 8 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Print Body'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Print Body'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'-'}
                                    </Text>
                                  </View>
                                  {/* center */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1, flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* action */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Print Body'
                                      ].props}
                                      numberOfLines={1}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Print Body'
                                          ].style,
                                          {
                                            fontFamily: 'Assistant_600SemiBold',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.action}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </View>
                )}
              </>
              {/* 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column', marginTop: 8 },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 28 },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Print Body'].style,
                          { textAlign: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'3.'}
                    </Text>
                  </View>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        { alignSelf: 'auto' }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      "Participant, having met the program's eligibility and gradiation criteria on the basis of their efforts to address said root causes, respectfully requests that the Court grant Participant's Motion for Relief in Street Outreach Court Detroit in the following cases:\n"
                    }
                    {fetchData?.case?.case_list}
                  </Text>
                </View>
              </View>
              {/* signers */}
              <View
                {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      marginLeft: '55%',
                    }
                  ),
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      { fontFamily: 'Assistant_700Bold', textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Respectfully submitted,'}
                </Text>
                {/* Text 2 */}
                <>
                  {!fetchData?.case?.city_user_id ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        dimensions.width
                      )}
                    >
                      {'/s/ '}
                      {fetchData?.city_user?.name_first}{' '}
                      {fetchData?.city_user?.name_last}
                      {', '}
                      {fetchData?.city_user?.entity}
                      {'  '}
                      {null}
                      {' \n '}
                      {fetchData?.case?.city_sign_timestamp}
                    </Text>
                  )}
                </>
                {/* Text 3 */}
                <>
                  {!fetchData?.case?.state_user_id ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        dimensions.width
                      )}
                    >
                      {'/s/ '}
                      {fetchData?.state_user?.name_first}{' '}
                      {fetchData?.state_user?.name_last}
                      {', '}
                      {fetchData?.state_user?.entity}
                      {'  '}
                      {fetchData?.state_user?.bar}
                      {'  '}
                      {fetchData?.case?.state_sign_timestamp}
                    </Text>
                  )}
                </>
                {/* Text 4 */}
                <>
                  {!fetchData?.case?.city_user_id ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Print Body'].style,
                        dimensions.width
                      )}
                    >
                      {'/s/ '}
                      {fetchData?.def_user?.name_first}{' '}
                      {fetchData?.def_user?.name_last}
                      {', '}
                      {fetchData?.def_user?.entity}
                      {'  '}
                      {fetchData?.def_user?.bar}
                      {'  '}
                      {fetchData?.case?.sign_def_timestamp}
                    </Text>
                  )}
                </>
              </View>
              {/* caption 3 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    { justifyContent: 'center', marginTop: 32 }
                  ),
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      { fontFamily: 'Assistant_700Bold', textAlign: 'center' }
                    ),
                    dimensions.width
                  )}
                >
                  {'ORDER'}
                </Text>
              </View>
              {/* 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column' },
                  dimensions.width
                )}
              >
                <Markdown
                  style={StyleSheet.applyWidth(
                    { fontFamily: 'Assistant_400Regular', fontSize: 12 },
                    dimensions.width
                  )}
                >
                  {fetchData?.program?.relief}
                </Markdown>
              </View>
              {/* 5 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column', marginTop: 16 },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      { alignSelf: 'auto', textAlign: 'right' }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Dated: ________________          __________________________________________________'
                  }
                </Text>
                {/* Text 3 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      { alignSelf: 'auto', textAlign: 'right' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Honorable '}
                  {fetchData?.judge_user?.name_first}{' '}
                  {fetchData?.judge_user?.name_middle}{' '}
                  {fetchData?.judge_user?.name_last}
                </Text>
              </View>
            </ScrollView>
          );
        }}
      </XanoApi.FetchCaseGetOneGET>
    </ScreenContainer>
  );
};

export default withTheme(CaseOrderScreen);
