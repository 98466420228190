import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { FlashList } from '@shopify/flash-list';
import { FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CaseNewScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const xanoCasePostPOST = XanoApi.useCasePostPOST();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column', paddingBottom: 32 },
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
          {/* home */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('FSScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Feather/home'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'home'}
              </Text>
            </View>
          </Touchable>
        </View>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'\nadd/refer a case'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
            dimensions.width
          )}
        >
          {(props.route?.params?.participant ?? '')?.name_first}{' '}
          {(props.route?.params?.participant ?? '')?.name_middle}{' '}
          {(props.route?.params?.participant ?? '')?.name_last}
          {'\n'}
          {(props.route?.params?.participant ?? '')?.date_of_birth}
        </Text>
      </View>
      {/* Keyboard Aware Scroll View 2 */}
      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Background'],
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
          },
          dimensions.width
        )}
        showsVerticalScrollIndicator={false}
      >
        {/* new */}
        <View
          {...GlobalStyles.ViewStyles(theme)['edit'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['edit'].style,
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth({ marginTop: 32 }, dimensions.width)}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Header - Page'].style,
                dimensions.width
              )}
            >
              {'provider note'}
            </Text>

            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {'Anything you want to tell us about this referral?'}
            </Text>
            {/* input */}
            <TextInput
              changeTextDelay={500}
              multiline={true}
              onChangeText={newInputValue => {
                try {
                  setTextAreaValue(newInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
              autoFocus={true}
              numberOfLines={10}
              placeholder={props.route?.params?.provider_note_help ?? ''}
              placeholderTextColor={theme.colors['Medium']}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                dimensions.width
              )}
              value={textAreaValue}
            />
          </View>
          {/* program options */}
          <FlatList
            data={Constants['works_for_programs']}
            horizontal={false}
            inverted={false}
            keyExtractor={(programOptionsData, index) =>
              programOptionsData?.id ??
              programOptionsData?.uuid ??
              index.toString()
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'kKv9aNb6'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const programOptionsData = item;
              return (
                <>
                  {!(
                    programOptionsData?.count <=
                    (props.route?.params?.count ?? '')
                  ) ? null : (
                    <View>
                      {/* option_order */}
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const response = (
                                await xanoCasePostPOST.mutateAsync({
                                  program_id: programOptionsData?.id,
                                  provider_note: textAreaValue,
                                  user_id: (
                                    props.route?.params?.participant ?? ''
                                  )?.id,
                                })
                              )?.json;
                              navigation.navigate('CaseDetailsScreen', {
                                case_id: response?.id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          { marginTop: 32 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'Right Link Text'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['Right Link Text']
                                .style,
                              dimensions.width
                            )}
                          >
                            {programOptionsData?.name}
                          </Text>
                          <Icon
                            size={24}
                            {...GlobalStyles.IconStyles(theme)[
                              'right link icon'
                            ].props}
                            color={theme.colors['Primary']}
                            name={'Feather/upload'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.IconStyles(theme)['right link icon']
                                .style,
                              dimensions.width
                            )}
                          />
                        </View>
                      </Touchable>
                    </View>
                  )}
                </>
              );
            }}
            contentContainerStyle={StyleSheet.applyWidth(
              { marginTop: 64 },
              dimensions.width
            )}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          />
        </View>

        <View
          {...GlobalStyles.ViewStyles(theme)['Tag - Red - View'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['Tag - Red - View'].style,
              { marginTop: 32 }
            ),
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                { textAlign: 'center', textTransform: 'none' }
              ),
              dimensions.width
            )}
          >
            {
              'If there is no referral option listed, it means that the participant does not meet the referral criteria for any program connected to your organization.\n\nEmail jayesh@streetdemocracy.org if this is in error.'
            }
          </Text>
        </View>
      </KeyboardAwareScrollView>

      <View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={'rgba(0, 0, 0, 0)'}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(CaseNewScreen);
