import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const OpportunityReportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [verified, setVerified] = React.useState('verified');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'participant_report',
        value: null,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchUserReportGET
        user_id={props.route?.params?.user_id ?? 1356}
      >
        {({ loading, error, data, refetchUserReport }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Divider'], padding: 32 },
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          opacity: 0.1,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Medium']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                </View>
                {/* title */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Big Header'].style,
                    dimensions.width
                  )}
                >
                  {'opportunity report'}
                </Text>
                {/* Action */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Print Body'].style,
                    dimensions.width
                  )}
                >
                  {
                    'Functional Sentencing offers people the opportunity to address the root causes of their behavior and circumstances resulting in better criminal justice outcomes than traditional punishments--like fines, supervision, and jail.'
                  }
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth({ padding: 32 }, dimensions.width)}
              >
                {/* date */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Header'].style,
                      dimensions.width
                    )}
                  >
                    {'date of report'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.date}
                  </Text>
                </View>
                {/* participant */}
                <View>
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Header'].style,
                      dimensions.width
                    )}
                  >
                    {'participant'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.participant?.name_first}{' '}
                    {fetchData?.participant?.name_middle}{' '}
                    {fetchData?.participant?.name_last}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.participant?.date_of_birth}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Print Label'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Print Label'].style,
                        dimensions.width
                      )}
                    >
                      {'  dob'}
                    </Text>
                  </Text>
                </View>
                {/* vision */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Header'].style,
                      dimensions.width
                    )}
                  >
                    {'vision statement'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Body'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Body'].style,
                      dimensions.width
                    )}
                  >
                    {fetchData?.participant?.vision_statement}
                  </Text>
                </View>
                {/* actions */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Print Header'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Print Header'].style,
                      dimensions.width
                    )}
                  >
                    {'verified checkins'}
                  </Text>
                  <FlatList
                    data={fetchData?.checkin}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'tiL0sYUC'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {!listData?.verified ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  marginBottom: 8,
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  name={'MaterialIcons/verified'}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 8 },
                                    dimensions.width
                                  )}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1, justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Print Body'
                                  ].props}
                                  numberOfLines={2}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Print Body'
                                      ].style,
                                      { fontFamily: 'Assistant_700Bold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.win}
                                </Text>

                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Print Body'
                                  ].props}
                                  numberOfLines={2}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'Print Body'
                                      ].style,
                                      { fontSize: 10 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.entity}
                                  {' -- '}
                                  {listData?.name_first} {listData?.name_last}
                                  {' -- '}
                                  {listData?.date}
                                </Text>
                              </View>
                            </View>
                          )}
                        </>
                      );
                    }}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flex: 1 },
                      dimensions.width
                    )}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </View>
            </>
          );
        }}
      </XanoApi.FetchUserReportGET>
    </ScreenContainer>
  );
};

export default withTheme(OpportunityReportScreen);
