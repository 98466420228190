import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Divider,
  Icon,
  Picker,
  ScreenContainer,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CaseEditScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [caseBackground, setCaseBackground] = React.useState(
    Constants['selected_case']?.case?.bg_report
  );
  const [caseListValue, setCaseListValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.case_list
  );
  const [caseNumberValue, setCaseNumberValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.number_of
  );
  const [caseScreen, setCaseScreen] = React.useState('');
  const [caseStatusValue, setCaseStatusValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.case_status
  );
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [dueEndValue, setDueEndValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.due_at_end
  );
  const [dueStartValue, setDueStartValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.due_at_start
  );
  const [fileUpload, setFileUpload] = React.useState('');
  const [filename, setFilename] = React.useState('');
  const [inputNextDate, setInputNextDate] = React.useState(
    (props.route?.params?.case ?? 2116)?.next_date
  );
  const [inputProviderNote, setInputProviderNote] = React.useState(
    (props.route?.params?.case ?? 2116)?.provider_note
  );
  const [inputUpdate, setInputUpdate] = React.useState(
    (props.route?.params?.case ?? 2116)?.update
  );
  const [pickerDefender, setPickerDefender] = React.useState(
    (props.route?.params?.selected_case ?? '')?.defender_user_id
  );
  const [pickerJudge, setPickerJudge] = React.useState(
    (props.route?.params?.case ?? 2116)?.judge_user_id
  );
  const [pickerJurisdiction, setPickerJurisdiction] = React.useState(
    (props.route?.params?.case ?? 2116)?.jurisdiction_id
  );
  const [pickerProgram, setPickerProgram] = React.useState(
    (props.route?.params?.case ?? 2116)?.program_id
  );
  const [pickerProvider, setPickerProvider] = React.useState(
    (props.route?.params?.case ?? 2116)?.provider_org_id
  );
  const [pickerProviderUser, setPickerProviderUser] = React.useState(
    (props.route?.params?.case ?? 2116)?.provider_user_id
  );
  const [pickerReferringJudge, setPickerReferringJudge] = React.useState(
    (props.route?.params?.case ?? 2116)?.referring_judge_user_id
  );
  const [pickerStatus, setPickerStatus] = React.useState(
    (props.route?.params?.case ?? 2116)?.status
  );
  const [pickerType, setPickerType] = React.useState(
    (props.route?.params?.case ?? 2116)?.case_type
  );
  const [pickerValue, setPickerValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.case_type_id
  );
  const [switchRow2Value, setSwitchRow2Value] = React.useState(
    (props.route?.params?.case ?? 2116)?.flag_provider
  );
  const [switchRow3Value, setSwitchRow3Value] = React.useState(
    (props.route?.params?.case ?? 2116)?.flag_defender
  );
  const [switchRow4Value, setSwitchRow4Value] = React.useState(
    (props.route?.params?.case ?? 2116)?.flag_prosecutor
  );
  const [switchRow5Value, setSwitchRow5Value] = React.useState(
    (props.route?.params?.case ?? 2116)?.flag_court
  );
  const [switchRow6Value, setSwitchRow6Value] = React.useState(
    (props.route?.params?.case ?? 2116)?.flag_special
  );
  const [switchRowValue, setSwitchRowValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.win
  );
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [winValue, setWinValue] = React.useState(
    (props.route?.params?.case ?? 2116)?.win
  );
  const [switchValue, setSwitchValue] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setCaseScreen(props.route?.params?.case ?? 2116);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      {...GlobalStyles.ScreenContainerStyles(theme)['Text 2'].props}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        GlobalStyles.ScreenContainerStyles(theme)['Text 2'].style,
        dimensions.width
      )}
    >
      {/* header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header'].style,
          dimensions.width
        )}
      >
        {/* nav */}
        <View
          {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['nav 2'].style,
            dimensions.width
          )}
        >
          {/* back */}
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* view */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'auto',
                  flexDirection: 'column',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors['Light']}
                name={'Ionicons/chevron-back'}
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 4,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { color: theme.colors['Light'], fontSize: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'back'}
              </Text>
            </View>
          </Touchable>
        </View>
        {/* title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header'].style,
            dimensions.width
          )}
        >
          {'\n'}
          {(props.route?.params?.selected_case ?? '')?.program?.name}
          {' #'}
          {(props.route?.params?.case ?? 2116)?.id}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Big Header - Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Big Header - Text'].style,
            dimensions.width
          )}
        >
          {(props.route?.params?.participant ?? '')?.name_first}{' '}
          {(props.route?.params?.participant ?? '')?.name_middle}{' '}
          {(props.route?.params?.participant ?? '')?.name_last}
          {'\n'}
          {(props.route?.params?.participant ?? '')?.date_of_birth}
        </Text>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flexBasis: 1,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 32,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={false}
      >
        {/* bg */}
        <>
          {!(pickerType === 'street outreach') ? null : (
            <View>
              {/* Text 7 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    {
                      flexGrow: 1,
                      marginTop: { minWidth: Breakpoints.Laptop, value: 32 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'background check'}
                {/* Text 5 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {' - '}
                  {(props.route?.params?.case ?? 2116)?.bg_date}
                  {' - #'}
                  {(props.route?.params?.case ?? 2116)?.bg_user}{' '}
                </Text>
              </Text>
              <>
                {Constants['user_details']?.role === 'court' ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'rgb(250, 249, 249)',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'rgb(250, 250, 250)',
                          },
                        ],
                        borderColor: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors['Divider'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['Divider'],
                          },
                        ],
                        borderRadius: 8,
                        borderWidth: [
                          { minWidth: Breakpoints.Laptop, value: 1 },
                          { minWidth: Breakpoints.Mobile, value: 1 },
                        ],
                        opacity: { minWidth: Breakpoints.Laptop, value: 1 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Text 6 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {(props.route?.params?.case ?? 2116)?.bg_report}
                    </Text>
                  </View>
                )}
              </>
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: [
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                      { minWidth: Breakpoints.Mobile, value: 'row' },
                    ],
                    justifyContent: [
                      { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                      { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Assistant_300Light',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Assistant_300Light',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 10 },
                          { minWidth: Breakpoints.Mobile, value: 12 },
                        ],
                        marginRight: [
                          { minWidth: Breakpoints.Laptop, value: 16 },
                          { minWidth: Breakpoints.Mobile, value: 16 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'no. of cases: '}
                  {(props.route?.params?.case ?? 2116)?.number_of}
                </Text>
                {/* Text 3 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Assistant_300Light',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Assistant_300Light',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 10 },
                          { minWidth: Breakpoints.Mobile, value: 12 },
                        ],
                        marginRight: [
                          { minWidth: Breakpoints.Laptop, value: 16 },
                          { minWidth: Breakpoints.Mobile, value: 16 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'due at start: $'}
                  {(props.route?.params?.case ?? 2116)?.due_at_start}
                </Text>
                {/* Text 4 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Assistant_300Light',
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Assistant_300Light',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 10 },
                          { minWidth: Breakpoints.Mobile, value: 12 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'due at end: $'}
                  {(props.route?.params?.case ?? 2116)?.due_at_end}
                </Text>
              </View>
              <Divider
                {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                color={'rgba(0, 0, 0, 0)'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  dimensions.width
                )}
              />
            </View>
          )}
        </>
        {/* defender only */}
        <>
          {!(Constants['user_details']?.role === 'defender') ? null : (
            <View
              style={StyleSheet.applyWidth({ marginTop: 32 }, dimensions.width)}
            >
              {/* case info */}
              <View>
                {/* case info */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'mandatory case data'}
                </Text>
                {/* jurisdiction */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newJurisdictionValue => {
                    try {
                      setPickerJurisdiction(newJurisdictionValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'jurisdiction'}
                  leftIconName={'MaterialIcons/gavel'}
                  options={Constants['jurisdiction_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerJurisdiction}
                />
                {/* referring judge */}
                <>
                  {!(pickerType === 'street outreach') ? null : (
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newReferringJudgeValue => {
                        try {
                          setPickerReferringJudge(newReferringJudgeValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)['picker'].props}
                      iconColor={theme.colors['Primary']}
                      label={'referring judge'}
                      leftIconName={'MaterialIcons/gavel'}
                      options={Constants['person_list']}
                      placeholderTextColor={theme.colors['Medium']}
                      rightIconName={'Ionicons/chevron-down'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)['picker'].style,
                        dimensions.width
                      )}
                      value={pickerReferringJudge}
                    />
                  )}
                </>
                {/* judge */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newJudgeValue => {
                    try {
                      setPickerJudge(newJudgeValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'judge'}
                  options={Constants['person_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerJudge}
                />
                {/* case type */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newCaseTypeValue => {
                    try {
                      setPickerType(newCaseTypeValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'case type'}
                  options={Constants['case_type_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerType}
                />
                {/* case list label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'case list'}
                </Text>
                {/* case list input */}
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newCaseListInputValue => {
                    try {
                      setCaseListValue(newCaseListInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  numberOfLines={10}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    dimensions.width
                  )}
                  value={caseListValue}
                />
                {/* number of label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'number of cases'}
                </Text>
                {/* number of input */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newNumberOfInputValue => {
                    try {
                      setCaseNumberValue(newNumberOfInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  multiline={false}
                  numberOfLines={10}
                  placeholder={'no. of matters, eg. 3'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      { fontFamily: 'Assistant_400Regular' }
                    ),
                    dimensions.width
                  )}
                  value={caseNumberValue}
                />
                {/* due at start */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'due at start'}
                </Text>
                {/* due at start input */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newDueAtStartInputValue => {
                    try {
                      setDueStartValue(newDueAtStartInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  multiline={false}
                  numberOfLines={10}
                  placeholder={'amount of $ due at start'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    dimensions.width
                  )}
                  value={dueStartValue}
                />
                {/* due at end */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'due at end'}
                </Text>
                {/* due at end input */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newDueAtEndInputValue => {
                    try {
                      setDueEndValue(newDueAtEndInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  multiline={false}
                  numberOfLines={10}
                  placeholder={'amount of $ at end'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    dimensions.width
                  )}
                  value={dueEndValue}
                />
              </View>
              {/* program info */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'program info'}
                </Text>
                {/* program */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newProgramValue => {
                    try {
                      setPickerProgram(newProgramValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'program'}
                  options={Constants['works_for_programs']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerProgram}
                />
                {/* provider */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newProviderValue => {
                    try {
                      setPickerProvider(newProviderValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'provider'}
                  options={Constants['organization_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerProvider}
                />
                {/* manager */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newManagerValue => {
                    try {
                      setPickerProviderUser(newManagerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'manager'}
                  options={Constants['person_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerProviderUser}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'provider note'}
                </Text>
                {/* input */}
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newInputValue => {
                    try {
                      setInputProviderNote(newInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  disabled={true}
                  numberOfLines={10}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      { fontFamily: 'Assistant_400Regular' }
                    ),
                    dimensions.width
                  )}
                  value={inputProviderNote}
                />
              </View>
              {/* status */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Header - Page'].style,
                    dimensions.width
                  )}
                >
                  {'status - keep up to date'}
                </Text>
                {/* defender */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newDefenderValue => {
                    try {
                      setPickerDefender(newDefenderValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'defender'}
                  options={Constants['person_list']}
                  rightIconName={'Ionicons/chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerDefender}
                />
                {/* status */}
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background}
                  dropDownBorderColor={theme.colors.divider}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newStatusValue => {
                    try {
                      setPickerStatus(newStatusValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Select an option'}
                  selectedIconColor={theme.colors.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)['picker'].props}
                  iconColor={theme.colors['Primary']}
                  label={'next event/status'}
                  options={Constants['case_status_list']}
                  rightIconName={'Ionicons/ios-chevron-down'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)['picker'].style,
                    dimensions.width
                  )}
                  value={pickerStatus}
                />
                {/* next date */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'next date'}
                </Text>
                {/* next date input */}
                <TextInput
                  changeTextDelay={500}
                  onChangeText={newNextDateInputValue => {
                    try {
                      setInputNextDate(newNextDateInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  multiline={false}
                  numberOfLines={10}
                  placeholder={'YYYY-MM-DD'}
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Input'].style,
                      { fontFamily: 'Assistant_400Regular' }
                    ),
                    dimensions.width
                  )}
                  value={inputNextDate}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 8 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'case update or narrative'}
                </Text>
                {/* input */}
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newInputValue => {
                    try {
                      setInputUpdate(newInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Input'].props}
                  autoFocus={false}
                  numberOfLines={10}
                  placeholder={
                    'This is to summarize any argument from defender to court. Anyone who can see this case will be able to see this note.'
                  }
                  placeholderTextColor={theme.colors['Medium']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Input'].style,
                    dimensions.width
                  )}
                  value={inputUpdate}
                />
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Small Header'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Small Header'].style,
                    dimensions.width
                  )}
                >
                  {'flags - use if there are issues'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                      justifyContent: 'flex-start',
                      maxWidth: 120,
                    },
                    dimensions.width
                  )}
                >
                  {/* participant */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newParticipantValue => {
                      const switchValue = newParticipantValue;
                      try {
                        setSwitchRowValue(newParticipantValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    direction={'row'}
                    label={'participant'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                        { textAlign: 'auto' }
                      ),
                      dimensions.width
                    )}
                    value={switchRowValue}
                  />
                  {/* provider */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newProviderValue => {
                      const switchValue = newProviderValue;
                      try {
                        setSwitchRow2Value(newProviderValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'provider'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                        { textAlign: 'auto' }
                      ),
                      dimensions.width
                    )}
                    value={switchRow2Value}
                  />
                  {/* defender */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newDefenderValue => {
                      const switchValue = newDefenderValue;
                      try {
                        setSwitchRow3Value(newDefenderValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'defender'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      dimensions.width
                    )}
                    value={switchRow3Value}
                  />
                  {/* prosecutor */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newProsecutorValue => {
                      const switchValue = newProsecutorValue;
                      try {
                        setSwitchRow4Value(newProsecutorValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'prosecutor'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      dimensions.width
                    )}
                    value={switchRow4Value}
                  />
                  {/* court */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newCourtValue => {
                      const switchValue = newCourtValue;
                      try {
                        setSwitchRow5Value(newCourtValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'court'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      dimensions.width
                    )}
                    value={switchRow5Value}
                  />
                  {/* special */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newSpecialValue => {
                      const switchValue = newSpecialValue;
                      try {
                        setSwitchRow6Value(newSpecialValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'special'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      dimensions.width
                    )}
                    value={switchRow6Value}
                  />
                  {/* win */}
                  <SwitchRow
                    label={'First Option'}
                    onValueChange={newWinValue => {
                      try {
                        setWinValue(newWinValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                    label={'win'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      dimensions.width
                    )}
                    value={winValue}
                  />
                </View>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 8 },
                  dimensions.width
                )}
              >
                {/* Button Note */}
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        (
                          await XanoApi.caseUpdatePATCH(Constants, {
                            case_id: (props.route?.params?.case ?? 2116)?.id,
                            case_list: caseListValue,
                            case_type: pickerType,
                            defender_user_id: pickerDefender,
                            due_at_end: dueEndValue,
                            due_at_state: dueStartValue,
                            flag_court: switchRow5Value,
                            flag_defender: switchRow3Value,
                            flag_participant: switchRowValue,
                            flag_prosecutor: switchRow4Value,
                            flag_provider: switchRow2Value,
                            flag_special: switchRow6Value,
                            judge_user_id: pickerJudge,
                            jurisdiction_id: pickerJurisdiction,
                            next_date: inputNextDate,
                            number_of: caseNumberValue,
                            program_id: pickerProgram,
                            provider_note: inputProviderNote,
                            provider_org_id: pickerProvider,
                            provider_user_org: pickerProviderUser,
                            referring_judge_user_id: pickerReferringJudge,
                            status: pickerStatus,
                            update: inputUpdate,
                            win: winValue,
                          })
                        )?.json;
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { marginTop: 32 }
                    ),
                    dimensions.width
                  )}
                  title={'update case'}
                />
              </View>
            </View>
          )}
        </>
      </KeyboardAwareScrollView>

      <View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={'rgba(0, 0, 0, 0)'}
          style={StyleSheet.applyWidth(
            GlobalStyles.DividerStyles(theme)['Divider'].style,
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(CaseEditScreen);
