import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const UserProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [response, setResponse] = React.useState('');
  const [showActionPlanNote, setShowActionPlanNote] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [viewSelect, setViewSelect] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { flexDirection: 'column' },
        dimensions.width
      )}
    >
      <XanoApi.FetchUserReportGET
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'selected_case',
                value: fetchData,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
        user_id={props.route?.params?.user_id ?? 636}
      >
        {({ loading, error, data, refetchUserReport }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header */}
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Divider'], padding: 32 },
                  dimensions.width
                )}
              >
                {/* nav */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['nav 2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['nav 2'].style,
                    dimensions.width
                  )}
                >
                  {/* back */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Ionicons/chevron-back'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'back'}
                      </Text>
                    </View>
                  </Touchable>
                  {/* home */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('FSScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* view */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={theme.colors['Light']}
                        name={'Feather/home'}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 4,
                            marginLeft: 4,
                            marginRight: 4,
                            marginTop: 4,
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Light'], fontSize: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'home'}
                      </Text>
                    </View>
                  </Touchable>
                </View>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Tag - Red'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Tag - Red'].style,
                    dimensions.width
                  )}
                >
                  {'\n\nprofile'}
                </Text>
                {/* touchable 2 */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('UserEditScreen', {
                        user_id: fetchData?.participant?.id,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.TouchableStyles(theme)['case list view']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TouchableStyles(theme)['case list view'].style,
                    dimensions.width
                  )}
                >
                  {/* participant */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', flexWrap: 'wrap' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {fetchData?.participant?.name_first}{' '}
                    </Text>
                    {/* Text 2 */}
                    <>
                      {!fetchData?.participant?.name_middle ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Big Header']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Big Header'].style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.participant?.name_middle}{' '}
                        </Text>
                      )}
                    </>
                    {/* Text 3 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Big Header'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Big Header'].style,
                        dimensions.width
                      )}
                    >
                      {fetchData?.participant?.name_last}
                    </Text>
                  </View>
                  {/* item */}
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* center */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        {/* dob */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Big Header - Text'
                          ].props}
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Big Header - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.participant?.date_of_birth}
                        </Text>
                        {/* name */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'Big Header - Text'
                          ].props}
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Big Header - Text']
                              .style,
                            dimensions.width
                          )}
                        >
                          {fetchData?.participant?.address_city}
                          {', '}
                          {fetchData?.participant?.address_state}
                        </Text>
                      </View>
                      {/* right 2 */}
                      <>
                        {Constants['works_for']?.type === 'justice' ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* View 2 */}
                            <>
                              {Constants['user_details']?.role ===
                              'participant' ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* tel */}
                                  <IconButton
                                    onLongPress={() => {
                                      try {
                                        /* 'Open App Link' action requires configuration: select URL */
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    onPress={() => {
                                      try {
                                        Linking.openURL(
                                          `tel:${fetchData?.participant?.tel}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    icon={'MaterialIcons/phone'}
                                    size={24}
                                  />
                                  {/* email */}
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        Linking.openURL(
                                          `mailto:${fetchData?.participant?.email}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    icon={
                                      'MaterialCommunityIcons/email-plus-outline'
                                    }
                                    size={24}
                                    style={StyleSheet.applyWidth(
                                      { marginLeft: 8 },
                                      dimensions.width
                                    )}
                                  />
                                  {/* tel 2 */}
                                  <>
                                    {!(
                                      Constants['user_details']?.role ===
                                      'defender'
                                    ) ? null : (
                                      <IconButton
                                        onLongPress={() => {
                                          try {
                                            /* 'Open App Link' action requires configuration: select URL */
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        onPress={() => {
                                          try {
                                            Linking.openURL(
                                              `tel:${fetchData?.participant?.tel_alt}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        icon={
                                          'MaterialCommunityIcons/phone-message-outline'
                                        }
                                        size={24}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 8 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                </Touchable>
              </View>
              {/* Keyboard Aware Scroll View 2 */}
              <KeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                viewIsInsideTabBar={false}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Background'],
                    flexBasis: 1,
                    paddingBottom: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                  },
                  dimensions.width
                )}
                showsVerticalScrollIndicator={false}
              >
                {/* vision */}
                <>
                  {!fetchData?.participant?.vision_statement ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {'vision statement'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: theme.colors['Strong'], opacity: 0.75 }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.participant?.vision_statement}
                      </Text>
                    </View>
                  )}
                </>
                {/* action plan list */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Header - Page'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Header - Page'].style,
                      dimensions.width
                    )}
                  >
                    {'action plan'}
                  </Text>
                  <>
                    {!fetchData?.unique ? null : (
                      <FlatList
                        data={fetchData?.unique}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'Byh5Awfk'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'Tag - Grey - View'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ViewStyles(theme)[
                                    'Tag - Grey - View'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'Tag - Grey - Text'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'Tag - Grey - Text'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {listData}
                                </Text>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* list */}
                  <FlatList
                    data={fetchData?.checkin}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'gGrcwQ9l'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* touchable */}
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('CheckinDetailsScreen', {
                                  page: 'profile',
                                  participant: fetchData?.participant,
                                  checkin: listData,
                                  checkin_provider_id:
                                    listData?.provider_org_id,
                                  user_org_id: Constants['works_for']?.id,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.TouchableStyles(theme)[
                              'case list view'
                            ].props}
                            disabled={
                              Constants['works_for']?.type === 'justice'
                            }
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TouchableStyles(theme)[
                                'case list view'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {/* item */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  marginBottom: 8,
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* left */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* verified icon */}
                                <>
                                  {!listData?.verified ? null : (
                                    <Icon
                                      size={24}
                                      color={theme.colors['Primary']}
                                      name={
                                        'MaterialCommunityIcons/check-decagram'
                                      }
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                                {/* open icon */}
                                <>
                                  {listData?.verified ? null : (
                                    <Icon
                                      size={24}
                                      color={theme.colors['Dark']}
                                      name={
                                        'MaterialCommunityIcons/decagram-outline'
                                      }
                                      style={StyleSheet.applyWidth(
                                        { opacity: 0.5 },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                              {/* center */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                {/* action */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'List Bold'
                                  ].props}
                                  numberOfLines={1}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['List Bold']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?.action}
                                </Text>
                                {/* verified */}
                                <>
                                  {!listData?.verified ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].props}
                                      numberOfLines={1}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.date}
                                      {' - '}
                                      {listData?.entity}
                                      {' - '}
                                      {listData?.name_first}{' '}
                                      {listData?.name_last}
                                    </Text>
                                  )}
                                </>
                                {/* unverified */}
                                <>
                                  {listData?.verified ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'Tag - Grey - Text'
                                      ].props}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'Tag - Grey - Text'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.entity}
                                    </Text>
                                  )}
                                </>
                                <>
                                  {!listData?.provider_note ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginTop: 4 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* note */}
                                      <>
                                        {!(
                                          Constants['user_details']?.role ===
                                          'defender'
                                        ) ? null : (
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'Tag - Grey - View'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                {
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Background Input'
                                                    ],
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  margin: 0,
                                                  opacity: 0.75,
                                                  padding: 4,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Grey - Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  {
                                                    color:
                                                      theme.colors['Strong'],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.provider_note}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* note 2 */}
                                      <>
                                        {!(
                                          Constants['user_details']?.role ===
                                          'participant'
                                        ) ? null : (
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'Tag - Grey - View'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                {
                                                  backgroundColor:
                                                    theme.colors[
                                                      'Background Input'
                                                    ],
                                                  borderColor:
                                                    theme.colors['Divider'],
                                                  margin: 0,
                                                  opacity: 0.75,
                                                  padding: 4,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Grey - Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  {
                                                    color:
                                                      theme.colors['Strong'],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.provider_note}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          </Touchable>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </View>
                {/* cases */}
                <>
                  {!fetchData?.cases?.length ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 32 },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Header - Page']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Header - Page'].style,
                          dimensions.width
                        )}
                      >
                        {'cases'}
                      </Text>
                      {/* list */}
                      <FlatList
                        data={fetchData?.cases}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ?? listData?.uuid ?? index.toString()
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'zvfBDQoh'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* touchable */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('CaseDetailsScreen', {
                                      case_id: listData?.id,
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'case list view'
                                ].props}
                                disabled={
                                  Constants['user_details']?.role !== 'defender'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TouchableStyles(theme)[
                                    'case list view'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {/* item */}
                                <View>
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginBottom: 24,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* left */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        size={24}
                                        color={theme.colors['Primary']}
                                        name={
                                          'MaterialCommunityIcons/scale-balance'
                                        }
                                        style={StyleSheet.applyWidth(
                                          { opacity: 0.5 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* center */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flex: 1 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* date */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Tag - Red'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'Tag - Red'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.next_date}
                                        {/* status */}
                                        <>
                                          {!listData?.status ? null : (
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Tag - Red'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Tag - Red'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {' - '}
                                              {listData?.status}
                                            </Text>
                                          )}
                                        </>
                                      </Text>
                                      {/* case type */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'List Bold'
                                        ].props}
                                        numberOfLines={1}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'List Bold'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.case_type}
                                      </Text>
                                      <>
                                        {!(
                                          Constants['user_details']?.role ===
                                          'defender'
                                        ) ? null : (
                                          <Text
                                            accessible={true}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors['Strong'],
                                                fontFamily:
                                                  'Assistant_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 12,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 16,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.jurisdiction?.entity}
                                          </Text>
                                        )}
                                      </>
                                      {/* tags 2 */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'tags'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)['tags']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        <>
                                          {!listData?.flag_participant ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'participant'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!listData?.flag_provider ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'provider'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!listData?.flag_defender ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'defender'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!listData?.flag_prosecutor ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'prosecutor'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!listData?.flag_court ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'court'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                        <>
                                          {!listData?.flag_special ? null : (
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['Tag - Grey - View'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'Tag - Grey - View'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Tag - Grey - Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Tag - Grey - Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'special'}
                                              </Text>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                    {/* right -  defender */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'defender'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          {/* provider */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.provider?.entity}
                                          </Text>
                                          {/* manager */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.manager?.name_first}{' '}
                                            {listData?.manager?.name_last}
                                          </Text>
                                          {/* case */}
                                          <>
                                            {!listData?.win ? null : (
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    {
                                                      color:
                                                        theme.colors['Light'],
                                                      fontSize: 12,
                                                      textAlign: 'right',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.number_of}
                                                {' cases: $'}
                                                {listData?.savings}
                                                {' saved'}
                                              </Text>
                                            )}
                                          </>
                                          <>
                                            {!(
                                              listData?.case_type ===
                                              'street outreach'
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* bg */}
                                                <>
                                                  {!listData?.bg_old ? null : (
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Primary'
                                                              ],
                                                            fontFamily:
                                                              'Assistant_700Bold',
                                                            fontSize: 12,
                                                            textAlign: 'right',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'need bgc'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* right -  provider */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'provider'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          {/* provider */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.provider?.entity}
                                          </Text>
                                          {/* manager */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.manager?.name_first}{' '}
                                            {listData?.manager?.name_last}
                                          </Text>
                                          {/* case */}
                                          <>
                                            {!listData?.win ? null : (
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    {
                                                      color:
                                                        theme.colors['Light'],
                                                      fontSize: 12,
                                                      textAlign: 'right',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.number_of}
                                                {' cases: $'}
                                                {listData?.savings}
                                                {' saved'}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* right -  participant */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'participant'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          {/* provider */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.provider?.entity}
                                          </Text>
                                          {/* manager */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.manager?.name_first}{' '}
                                            {listData?.manager?.name_last}
                                          </Text>
                                          {/* case */}
                                          <>
                                            {!listData?.win ? null : (
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    {
                                                      color:
                                                        theme.colors['Light'],
                                                      fontSize: 12,
                                                      textAlign: 'right',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.number_of}
                                                {' cases: $'}
                                                {listData?.savings}
                                                {' saved'}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* right - judge */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'judge'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          {/* provider */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.provider?.entity}
                                          </Text>
                                          {/* manager */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.manager?.name_first}{' '}
                                            {listData?.manager?.name_last}
                                          </Text>
                                          <>
                                            {!(
                                              listData?.case_type ===
                                              'street outreach'
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* bg */}
                                                <>
                                                  {!listData?.bg_old ? null : (
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Primary'
                                                              ],
                                                            fontFamily:
                                                              'Assistant_700Bold',
                                                            fontSize: 12,
                                                            textAlign: 'right',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'need bgc'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* right - court */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'court'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          <>
                                            {!(
                                              listData?.case_type ===
                                              'street outreach'
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* bg */}
                                                <>
                                                  {!listData?.bg_old ? null : (
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Primary'
                                                              ],
                                                            fontFamily:
                                                              'Assistant_700Bold',
                                                            fontSize: 12,
                                                            textAlign: 'right',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'need bgc'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* right - prosecutor */}
                                    <>
                                      {!(
                                        Constants['user_details']?.role ===
                                        'prosecutor'
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'flex-end',
                                              alignItems: 'flex-end',
                                              alignSelf: 'auto',
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* program */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.program?.name}
                                          </Text>
                                          {/* provider */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.provider?.entity}
                                          </Text>
                                          {/* manager */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color: theme.colors['Light'],
                                                  fontSize: 12,
                                                  textAlign: 'right',
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.manager?.name_first}{' '}
                                            {listData?.manager?.name_last}
                                          </Text>
                                          <>
                                            {!(
                                              listData?.case_type ===
                                              'street outreach'
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* bg */}
                                                <>
                                                  {!listData?.bg_old ? null : (
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              theme.colors[
                                                                'Primary'
                                                              ],
                                                            fontFamily:
                                                              'Assistant_700Bold',
                                                            fontSize: 12,
                                                            textAlign: 'right',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'need bgc'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                </View>
                              </Touchable>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </View>
                  )}
                </>
                {/* options */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 32 },
                    dimensions.width
                  )}
                >
                  {/* edit user details */}
                  <>
                    {!(
                      Constants['user_details']?.role === 'participant'
                    ) ? null : (
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('UserEditScreen', {
                              user_id: props.route?.params?.user_id ?? 636,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.TouchableStyles(theme)[
                          'touchable icon right'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TouchableStyles(theme)[
                            'touchable icon right'
                          ].style,
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'flex-start',
                                flex: 1,
                                flexWrap: 'nowrap',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'Right Link Text'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'Right Link Text'
                                  ].style,
                                  { alignSelf: 'flex-end' }
                                ),
                                dimensions.width
                              )}
                            >
                              {'edit my information'}
                            </Text>
                          </View>
                          <Icon
                            size={24}
                            color={theme.colors['Primary']}
                            name={'MaterialCommunityIcons/account-edit-outline'}
                            style={StyleSheet.applyWidth(
                              { marginLeft: 8 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </Touchable>
                    )}
                  </>
                  {/* action */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ActionListScreen', {
                          participant: fetchData?.participant,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { paddingRight: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'add action'}
                      </Text>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/alert-decagram-outline'}
                      />
                    </View>
                  </Touchable>
                  {/* refer to program */}
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('CaseNewScreen', {
                          participant: fetchData?.participant,
                          count: fetchData?.count,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.TouchableStyles(theme)[
                      'touchable icon right'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TouchableStyles(theme)[
                        'touchable icon right'
                      ].style,
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { paddingRight: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'refer to a program'}
                      </Text>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'MaterialCommunityIcons/briefcase-plus-outline'}
                      />
                    </View>
                  </Touchable>
                  {/* print */}
                  <Touchable
                    onPress={() => {
                      try {
                        /* hidden 'Navigate' action */
                        navigation.navigate('OpportunityReportScreen', {
                          user_id: fetchData?.participant?.id,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginTop: 32 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Right Link Text']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Right Link Text']
                              .style,
                            { paddingRight: 8 }
                          ),
                          dimensions.width
                        )}
                      >
                        {'view print-safe version'}
                      </Text>
                      <Icon
                        size={24}
                        color={theme.colors['Primary']}
                        name={'AntDesign/printer'}
                      />
                    </View>
                  </Touchable>
                  {/* options */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 16 },
                      dimensions.width
                    )}
                  />
                  {/* refer a case */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['right red button']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['right red button'].style,
                      dimensions.width
                    )}
                  />
                  {/* court programs */}
                  <>
                    {!(
                      Constants['user_details']?.role === 'participant'
                    ) ? null : (
                      <View
                        {...GlobalStyles.ViewStyles(theme)['right red button']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['right red button']
                            .style,
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </View>
                <Divider
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  color={'rgba(0, 0, 0, 0)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['Divider'].style,
                    dimensions.width
                  )}
                />
              </KeyboardAwareScrollView>
            </>
          );
        }}
      </XanoApi.FetchUserReportGET>
    </ScreenContainer>
  );
};

export default withTheme(UserProfileScreen);
