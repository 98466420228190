import React from 'react';

// filter list by search box
const listContains = async (list, phrase) => {
  //let phrase1 = phrase.toLowerCase()
  let filteredList = list.filter(item => item.name_first.includes(phrase1));
  return filteredList;
  console.log(filteredList);
};

export default listContains;
